import React from 'react';
import { useForm } from "react-hook-form";
import Axios from "../../../Mics/axiosInstance";
import StateOptions from "../../../SelectOptions/StateOptions";
import { NotificationManager } from 'react-notifications';
import CreditScores from './CreditScores';

export const PersonalGuarantor = ({ guarantor, applicationID }) => {
    const { register, handleSubmit } = useForm({ mode: 'onBlur' });

    const save = (data) => {
        Axios.put('/applications/' + applicationID + '/personal_guarantors/' + guarantor.id, data).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }
    console.log(guarantor)
    return (
        <React.Fragment>
            <form onBlur={handleSubmit(save)}>
                <div className="row mb-4">
                    <div className="col">
                        First Name:
                    <input ref={register} className="form-control" name="first_name" defaultValue={guarantor.first_name} />
                    </div>
                    <div className="col">
                        Middle Name:
                    <input ref={register} className="form-control" name="middle_name" defaultValue={guarantor.middle_name} />
                    </div>
                    <div className="col">
                        Last Name:
                    <input ref={register} className="form-control" name="last_name" defaultValue={guarantor.last_name} />
                    </div>
                </div>

                <hr />
                <div className="row mb-4">
                    <div className="col">
                        Address:
                    <input ref={register} className="form-control" name="address" defaultValue={guarantor.address} />
                    </div>
                    <div className="col">
                        City:
                    <input ref={register} className="form-control" name='city' defaultValue={guarantor.city} />
                    </div>
                    <div className="col">
                        State
                    <select ref={register} className="form-control" name='state' defaultValue={guarantor.state}>
                            <StateOptions />
                        </select>
                    </div>
                    <div className="col">
                        Zip:
                    <input ref={register} className="form-control" name='zip' defaultValue={guarantor.zip} />
                    </div>
                </div>

                <hr />

                <div className="row mb-4">
                    <div className="col">
                        SSN:
                    <input ref={register} className="form-control" name="ssn" defaultValue={guarantor.ssn} />
                    </div>
                    <div className="col">
                        Ownership
                    <div className={`input-group`}>
                            <input ref={register} className="form-control" name="ownership" defaultValue={guarantor.ownership} />
                            <div className="input-group-append">
                                <span className="input-group-text">%</span>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        Title:
                    <input ref={register} className="form-control" name="title" defaultValue={guarantor.title} />
                    </div>
                </div>

                <hr />


                <hr />
            </form>

            <CreditScores guarantorID={guarantor.id} applicationID={applicationID} />
        </React.Fragment>
    )
}

export default PersonalGuarantor
