import React from 'react';
import { Link } from 'react-router-dom'
import logo from '../images/logo-white.png';
import Roles from "../../Components/Config/Roles";

const LeftSideBar = () => {
    const usersRoles = JSON.parse(localStorage.getItem('peak-roles'))

    const hasRoles = (requiredRoles) => {
        if(requiredRoles.length === 0){
            return true
        }
        if(usersRoles?.includes(Roles.ADMIN)){
            return true
        }
        
        return requiredRoles.some(role=> usersRoles?.includes(role))
    }

    const menuItems = [
        {
            title: 'Dashboard',
            link: '/',
            roles: [],
            icon: 'fas fa-fw fa-tachometer-alt'
        },
        {
            title: 'Applications',
            link: '/applications',
            roles: [Roles.SALES,Roles.DOCUMENT, Roles.CREDIT, Roles.ADMIN,],
            icon: 'fas fa-file-invoice-dollar'
        },
        {
            title: 'Doc Request',
            link: '/doc-request/search',
            roles: [Roles.SALES, Roles.DOCUMENT, Roles.ADMIN],
            icon: 'far fa-building'
        },
        {
            title: 'Status',
            link: '/credit-status',
            roles: [Roles.SALES, Roles.DOCUMENT, Roles.ADMIN, Roles.COLLECTION],
            icon: 'fas fa-users'
        },
        {
            title: 'Boarding Sheet',
            link: '/boarding-sheets',
            roles: [Roles.DOCUMENT, Roles.ADMIN],
            icon: 'fas fa-users'
        },
        {
            title: 'Disbursements',
            link: '/disbursements',
            roles: [Roles.DOCUMENT, Roles.ADMIN],
            icon: 'fas fa-users'
        },
        {
            title: 'Credit Applications',
            link: '/credit-applications',
            roles: [Roles.CREDIT, Roles.ADMIN],
            icon: 'fas fa-file-invoice-dollar'
        },
        {
            title: 'Sources',
            link: '/sources',
            roles: [Roles.SALES, Roles.CREDIT, Roles.DOCUMENT, Roles.ADMIN],
            icon: 'fas fa-users'
        },
        {
            title: 'Update',
            link: '/updates',
            roles: [Roles.SALES, Roles.ADMIN],
            icon: 'fas fa-users'
        },
        {
            title: 'Applicants',
            link: '/applicants',
            roles: [Roles.SALES,Roles.CREDIT, Roles.DOCUMENT, Roles.ADMIN],
            icon: 'fas fa-building'
        },
        {
            title: 'Vendors',
            link: '/vendors',
            roles: [Roles.SALES, Roles.CREDIT, Roles.DOCUMENT, Roles.ADMIN],
            icon: 'fas fa-building'
        },
        {
            title: 'Reports',
            link: '/reports',
            roles: [Roles.ADMIN, Roles.REPORTS],
            icon: 'fas fa-chart-bar'
        },
        {
            title: 'Users',
            link: '/admin/users',
            roles: [Roles.ADMIN],
            icon: 'fas fa-users'
        }
    ]

    return (
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark">

            <Link to="/" className="sidebar-brand d-flex align-items-center justify-content-center">
                <div className="sidebar-brand-icon rotate-n-15">
                    <img src={logo} alt="Logo" height={50} />
                </div>
                <div className="sidebar-brand-text mx-3">Peak <sup>2.0</sup></div>
            </Link>

            <hr className="sidebar-divider my-0" />

            {menuItems.map((item) => {
                if(hasRoles(item.roles)){
                    return (
                        <li className="nav-item">
                            <Link to={item.link} className="nav-link">
                                <i className={item.icon}/>
                                <span>{item.title}</span>
                            </Link>
                        </li>
                    )
                }
            })}

        </ul>
    );
}

export default LeftSideBar


