import React from 'react';
import Delete from './Delete'
import Date from '../../Helpers/Date'
import Money from '../../Helpers/Money'

export const BankInfo = ({bank, applicationId}) => {

    return (
        <tr>
            <td>
                {bank.bank_name}
            </td>
            <td>
                <Date date={bank.statement_date}/>
            </td>
            <td>
                <Money amount={bank.deposits}/>
            </td>
            <td>
                <Money amount={bank.withdrawals}/>
            </td>
            <td>
                <Money amount={bank.balance}/>
            </td>
            <td>
                <Delete bank={bank} applicationId={applicationId}/>
            </td>
        </tr>
    )

}
export default BankInfo