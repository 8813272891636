import React, { useState } from 'react';
import { useQuery } from "react-query";
import Axios from "../Mics/axiosInstance";
import { Link, useParams } from "react-router-dom";
import { NotificationManager } from 'react-notifications';

function useApplication(id, cb) {
    return useQuery(["application", id], async () => {
        const { data } = await Axios.get("/applications/" + id);
        cb(data)
        return data;
    });
}

export const Additional = () => {
    const { id } = useParams()
    const [legal_land_description, set_legal_land_description] = useState(false)
    const [title_mso, set_title_mso] = useState(false)
    const [ucc_search, set_ucc_search] = useState(false)
    const [equipment_approval, set_equipment_approval] = useState(false)
    const [vendor_approval, set_vendor_approval] = useState(false)
    const [ucc_fixture_filing, set_ucc_fixture_filing] = useState(false)
    const [lease_or_loan_agreement, set_lease_or_loan_agreement] = useState(false)
    const [vendor_invoice, set_vendor_invoice] = useState(false)
    const [assignment, set_assignment] = useState(false)
    const [exhibit_a, set_exhibit_a] = useState(false)
    const [notice_of_acceptance, set_notice_of_acceptance] = useState(false)
    const [copy_checks_to_vendor, set_copy_checks_to_vendor] = useState(false)
    const [corporate_resolution, set_corporate_resolution] = useState(false)

    const afterLoadSetData = (data) => {
        set_legal_land_description(data.credit.legal_land_description)
        set_title_mso(data.credit.title_mso)
        set_ucc_search(data.credit.ucc_search)
        set_equipment_approval(data.credit.equipment_approval)
        set_vendor_approval(data.credit.vendor_approval)
        set_ucc_fixture_filing(data.credit.ucc_fixture_filing)
        set_lease_or_loan_agreement(data.credit.lease_or_loan_agreement)
        set_vendor_invoice(data.credit.vendor_invoice)
        set_assignment(data.credit.assignment)
        set_exhibit_a(data.credit.exhibit_a)
        set_notice_of_acceptance(data.credit.notice_of_acceptance)
        set_copy_checks_to_vendor(data.credit.copy_checks_to_vendor)
        set_corporate_resolution(data.credit.corporate_resolution)
    }
    const { data, isLoading } = useApplication(id, afterLoadSetData);

    const handleCheckboxSave = (event) => {
        Axios.put('/credits/' + data.credit.id, { [event.target.name]: event.target.checked }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }
    const handleTextSave = (event) => {
        Axios.put('/credits/' + data.credit.id, { [event.target.name]: event.target.value }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }

    if (isLoading) {
        return <div>Loading Credit Application</div>
    }
    if (!data.source) {
        return <div>No Source</div>
    }

    return (
        <div className="row">
            <div className="col">
                <div className="card mb-4">

                    <div className="card-header">
                        Credit - Additional Information - {data.source?.source_type?.name}
                    </div>
                    <div className="card-body">
                        <div className="row mb-3">
                            <div className="col">
                                <div className="form-check">
                                    <input className="form-check-input" type='checkbox' id='legal_land_description' name='legal_land_description' defaultChecked={legal_land_description} onChange={(handleCheckboxSave)} />
                                    <label className="form-check-label" htmlFor="legal_land_description">Legal Land Description</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <div className="form-check">
                                    <input className="form-check-input" type='checkbox' id='title_mso' name='title_mso' defaultChecked={title_mso} onChange={(handleCheckboxSave)} />
                                    <label className="form-check-label" htmlFor="title_mso">Title/MSO</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <div className="form-check">
                                    <input className="form-check-input" type='checkbox' id='ucc_search' name='ucc_search' defaultChecked={ucc_search} onChange={(handleCheckboxSave)} />
                                    <label className="form-check-label" htmlFor="ucc_search">UCC Search</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <div className="form-check">
                                    <input className="form-check-input" type='checkbox' id='equipment_approval' name='equipment_approval' defaultChecked={equipment_approval} onChange={(handleCheckboxSave)} />
                                    <label className="form-check-label" htmlFor="equipment_approval">Equipment to be approved prior to Documentation Preparation</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <div className="form-check">
                                    <input className="form-check-input" type='checkbox' id='vendor_approval' name='vendor_approval' defaultChecked={vendor_approval} onChange={(handleCheckboxSave)} />
                                    <label className="form-check-label" htmlFor="vendor_approval">Vendor to be Approved</label>
                                </div>
                            </div>
                        </div>

                        {data.source?.source_type?.name === 'Discounter' ?
                            <div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <div className="form-check">
                                            <input className="form-check-input" type='checkbox' id='ucc_fixture_filing' name='ucc_fixture_filing' defaultChecked={ucc_fixture_filing} onChange={(handleCheckboxSave)} />
                                            <label className="form-check-label" htmlFor="ucc_fixture_filing">UCC Fixture Filing</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <div className="form-check">
                                            <input className="form-check-input" type='checkbox' id='lease_or_loan_agreement' name='lease_or_loan_agreement' defaultChecked={lease_or_loan_agreement} onChange={(handleCheckboxSave)} />
                                            <label className="form-check-label" htmlFor="lease_or_loan_agreement">Lease/Loan Agreement</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <div className="form-check">
                                            <input className="form-check-input" type='checkbox' id='vendor_invoice' name='vendor_invoice' defaultChecked={vendor_invoice} onChange={(handleCheckboxSave)} />
                                            <label className="form-check-label" htmlFor="vendor_invoice">Vendor Invoice</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <div className="form-check">
                                            <input className="form-check-input" type='checkbox' id='assignment' name='assignment' defaultChecked={assignment} onChange={(handleCheckboxSave)} />
                                            <label className="form-check-label" htmlFor="assignment">Assignment</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col">
                                        <div className="form-check">
                                            <input className="form-check-input" type='checkbox' id='exhibit_a' name='exhibit_a' defaultChecked={exhibit_a} onChange={(handleCheckboxSave)} />
                                            <label className="form-check-label" htmlFor="exhibit_a">Exhibit A</label>
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col">
                                        <i>Proof of Tax Exemption if applicable</i>
                                    </div>
                                </div>
                                <hr />
                                <div className="row mb-3">
                                    <div className="col">
                                        <div className="form-check">
                                            <input className="form-check-input" type='checkbox' id='notice_of_acceptance' name='notice_of_acceptance' defaultChecked={notice_of_acceptance} onChange={(handleCheckboxSave)} />
                                            <label className="form-check-label" htmlFor="notice_of_acceptance">Notice of Acceptance</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <div className="form-check">
                                            <input className="form-check-input" type='checkbox' id='copy_checks_to_vendor' name='copy_checks_to_vendor' defaultChecked={copy_checks_to_vendor} onChange={(handleCheckboxSave)} />
                                            <label className="form-check-label" htmlFor="copy_checks_to_vendor">Copy(s) checks to vendor</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <div className="form-check">
                                            <input className="form-check-input" type='checkbox' id='corporate_resolution' name='corporate_resolution' defaultChecked={corporate_resolution} onChange={(handleCheckboxSave)} />
                                            <label className="form-check-label" htmlFor="corporate_resolution">Corporate Resolution/Incumbency</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ''
                        }

                        <div className="row mb-5">
                            <div className="col">
                                <label htmlFor="">Credit Requirements</label>
                                <textarea className="form-control" name="credit_requirement" defaultValue={data.credit.credit_requirement} onBlur={(handleTextSave)} />
                            </div>
                        </div>


                        <div className="row">
                            <div className="col">
                                <Link className="btn btn-primary float-right" to={`/credit-applications/${id}/download-materials`}>
                                    Go to Download Materials <i className="fas fa-chevron-right" />
                                </Link>
                                <Link className="btn btn-primary" to={`/credit-applications/${id}/scorecard`}>
                                    <i className="fas fa-chevron-left" /> Go back to Scorecard
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Additional
