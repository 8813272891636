import React, { useState } from 'react';
import Edit from "./Edit";
import Delete from './Delete'
export const Item = ({ vendor, applicationId }) => {
    const [modalState, setModalState] = useState(false)

    const toggleModal = () => {
        setModalState(!modalState)
    }
    return (
        <>
            <tr>
                <td>
                    {vendor.company_name}
                </td>
                <td>
                    {vendor.dba_name}
                </td>
                <td>
                    {vendor.address}
                </td>
                <td>
                    {vendor.city}
                </td>
                <td>
                    {vendor.state}
                </td>
                <td>
                    {vendor.zip}
                </td>
                <td>
                    <i className="fas fa-edit mr-2 pointer" title="Edit this item" onClick={toggleModal} />
                </td>
                <td>
                    <Edit modalState={modalState} toggleModal={toggleModal} vendor={vendor} />
                </td>
                <td>
                    <Delete vendor={vendor} applicationId={applicationId} />
                </td>
            </tr>

        </>
    )

}
export default Item