import React, { useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import Axios from "../../../Components/Mics/axiosInstance";
import { useQuery } from "react-query";
import { NotificationManager } from 'react-notifications';

function useRoles() {
    return useQuery(["roles"], async () => {
        const { data } = await Axios.get("/roles");
        return data;
    });
}

export const Add = ({ modalState, toggleModal, triggerReload }) => {
    const { data, isLoading } = useRoles();
    const { register, handleSubmit, errors } = useForm();
    const [isOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
        if (modalState) {
            setIsOpen(true)
        }
    }, [modalState, setIsOpen]);

    const hideModal = () => {
        toggleModal()
        setIsOpen(false);
    };

    const save = (formData) => {
        Axios.post('/users', formData).then(res => {
            hideModal()
            triggerReload()
        }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    };

    if (isLoading) {
        return <div>Loading Staff Types</div>
    }

    return (
        <Modal show={isOpen} onHide={hideModal} size="lg">
            <form onSubmit={handleSubmit(save)}>
                <Modal.Header>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="username" className="form-label">Username <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.username ? "is-invalid" : ""}`} id="username" name="username" />
                        </div>
                        <div className="col">
                            <label htmlFor="name" className="form-label">Name <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.name ? "is-invalid" : ""}`} id="name" name="name" />
                        </div>
                        <div className="col">
                            <label htmlFor="email" className="form-label">Email <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.email ? "is-invalid" : ""}`} id="email" name="email" />
                        </div>
                        <div className="col">
                            <label htmlFor="role_ids" className="form-label">Roles <span className="required">*</span></label>
                            {isLoading ?
                                <div>Loading Roles</div>
                                :
                                <select ref={register({ required: true })} className={`form-control ${errors.role_ids ? "is-invalid" : ""}`} id="role_ids" name="role_ids" multiple>
                                    {data.map(role => {
                                        return (
                                            <option value={role.id}>
                                                {role.name}
                                            </option>
                                        )
                                    })}
                                </select>
                            }
                        </div>
                    </div>

                    <hr />

                    <div className="row mb-3">
                        <div className="col-3">
                            <label htmlFor="password" className="form-label">Password <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.password ? "is-invalid" : ""}`} id="password" name="password" />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={(e) => { e.preventDefault(); hideModal() }}>Cancel</button>
                    <button className="btn btn-primary" type="submit">Save</button>
                </Modal.Footer>
            </form>
        </Modal>
    )

}
export default Add