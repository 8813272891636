import React from 'react';
import InputMask from "react-input-mask";

export const SSNInput = ({register, name, id, value}) => {
    if(register === undefined || register === ''){
        console.log("register param must be set when using <SSNInput register={register}..., You must pass this in from useForm")
    }
    if(name === undefined || name === ''){
        console.log("name param must be set when using <SSNInput name=''...")
    }

    return (
        <InputMask ref={register} name={name} id={id} defaultValue={value} className="form-control" type="text" mask="999-99-9999" />
    )
}

export default SSNInput