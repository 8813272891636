import React from 'react';
import InputMask from "react-input-mask";

export const PhoneInput = ({register, name, id, value, className}) => {
    if(register === undefined || register === ''){
        console.log("register param must be set when using <PhoneInput register={register}..., You must pass this in from useForm")
    }
    if(name === undefined || name === ''){
        console.log("name param must be set when using <PhoneInput name=''...")
    }

    if(className === undefined){
        className = 'form-control'
    }

    if(id === undefined){
        id = name
    }

    return (
        <InputMask ref={register} name={name} id={id} defaultValue={value} className={className} type="tel" mask="(999) 999-9999" />
    )
}

export default PhoneInput