import React from 'react';
import { useForm } from "react-hook-form";
import Axios from "../../Mics/axiosInstance";
import CurrencyInput from "react-currency-input-field";
import { NotificationManager } from 'react-notifications';
import Resaler from './Resaler/Resaler';

export const Decision = ({ application }) => {
    const { register, handleSubmit } = useForm({ mode: 'onBlur' });

    const [approved_amount, set_approved_amount] = React.useState(application.credit.approved_amount !== null ? application.credit.approved_amount : 0.00);
    const [down_payment, set_down_payment] = React.useState(application.credit.down_payment !== null ? application.credit.down_payment : 0.00);
    const [security_deposit, set_security_deposit] = React.useState(application.credit.security_deposit !== null ? application.credit.security_deposit : 0.00);
    const [doc_fee, set_doc_fee] = React.useState(application.credit.doc_fee !== null ? application.credit.doc_fee : 0.00);
    const [inspection_fee, set_inspection_fee] = React.useState(200.00);
    const [ucc_fee, set_ucc_fee] = React.useState(application.credit.ucc_fee !== null ? application.credit.ucc_fee : 0.00);
    const [resaler_id, set_resaler_id] = React.useState(application.credit.resaler_id)
    const save = (data) => {
        data.approved_amount = approved_amount
        data.down_payment = down_payment
        data.security_deposit = security_deposit
        data.doc_fee = doc_fee
        data.inspection_fee = inspection_fee
        data.ucc_fee = ucc_fee
        data.resaler_id = resaler_id
        console.log(data)
        Axios.put('/credits/' + application.credit.id, data).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }
    const handleResalerChange = (value) => {
        console.log(value)
        set_resaler_id(value)
    }
    const handleCurrencyChange = (value, name) => {
        if (name === 'approved_amount') {
            set_approved_amount(value)
        }
        if (name === 'down_payment') {
            set_down_payment(value)
        }
        if (name === 'security_deposit') {
            set_security_deposit(value)
        }
        if (name === 'doc_fee') {
            set_doc_fee(value)
        }
        if (name === 'inspection_fee') {
            set_inspection_fee(value)
        }
        if (name === 'ucc_fee') {
            set_ucc_fee(value)
        }

    }

    return (
        <div>
            <form onBlur={handleSubmit(save)}>

                <div className="row mb-3">
                    <div className="col">
                        Decision:
                        <select ref={register} className="form-control" name='decision' defaultValue={application.credit.decision}>
                            <option value="" disabled selected>Select a decision</option>
                            <option value='Approved'>Approved</option>
                            <option value='Dead'>Dead</option>
                            <option value='Denied'>Denied</option>
                            <option value='Field Reject'>Field Reject</option>
                            <option value='Lost Sale'>Lost Sale</option>
                            <option value='Pending'>Pending</option>
                            <option value="Withdrawn">Withdrawn</option>
                        </select>
                    </div>
                    <div className="col">
                        Decision Date:
                        <input ref={register} className="form-control" type='date' name='decision_date' defaultValue={application.credit.decision_date} />
                    </div>
                    <div className="col">
                        Approved Amount:
                        <div className={`input-group`}>
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <CurrencyInput
                                className="form-control"
                                name="approved_amount"
                                placeholder="0.00"
                                defaultValue={approved_amount}
                                precision={2}
                                onChange={handleCurrencyChange}
                            />
                        </div>
                    </div>
                    <div className="col">
                        Credit Expires on:
                        <input ref={register} className="form-control" type='date' name='credit_expire' defaultValue={application.credit.credit_expire} />
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-3">
                        Down Payment:
                        <div className={`input-group`}>
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <CurrencyInput
                                className="form-control"
                                name="down_payment"
                                placeholder="0.00"
                                defaultValue={down_payment}
                                precision={2}
                                onChange={handleCurrencyChange}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        Security Deposit:
                        <div className={`input-group`}>
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <CurrencyInput
                                className="form-control"
                                name="security_deposit"
                                placeholder="0.00"
                                defaultValue={security_deposit}
                                precision={2}
                                onChange={handleCurrencyChange}
                            />
                        </div>

                    </div>
                    <div className="col-3">
                        EOT Options:
                        <select ref={register} className="form-control" name="eot_options" defaultValue={application.credit.eot_options}>
                            <option value="No Residual">No Residual</option>
                            <option value="$1.00">$1.00</option>
                            <option value="20%">20% Put</option>
                            <option value="15%">15% Put</option>
                            <option value="10%">10% Put</option>
                        </select>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-3">
                        Advance Payment:
                        <select ref={register} className="form-control" name='payment' defaultValue={application.credit.payment} >
                            <option value='First and Last Pmt'>First and Last Payment</option>
                            <option value="First Payment">First Payment</option>
                            <option value='In Arreas'>In Arrears</option>
                            <option value='90 Days Deferred'>90 Days Deferred</option>
                            <option value='180 Days Deferred'>180 Days Deferred</option>
                            <option value='45 Days Deferred'>45 Days Deferred</option>
                            <option value='Other'>Other</option>
                        </select>
                    </div>
                    <div className="col-3">
                        Frequency:
                        <select ref={register} className="form-control" name='frequency' defaultValue={application.credit.frequency} >
                            <option value="Monthly">Monthly</option>
                            <option value="Quarterly">Quarterly</option>
                            <option value="Semi-Annual">Semi-Annual</option>
                            <option value="Annual">Annual</option>
                            <option value="Irregular">Irregular</option>
                            <option value="Bi-Monthly">Bi-Monthly</option>
                            <option value="Bi-Weekly">Bi-Weekly</option>
                        </select>
                    </div>
                    <div className="col-3">
                        Term:
                        <input ref={register} className="form-control" type='number' name='terms' defaultValue={application.credit.terms} />
                    </div>

                </div>

                <div className="row mb-3">
                    <div className="col-3">
                        Purpose:
                        <select ref={register} className="form-control" name='purpose' defaultValue={application.credit.purpose} >
                            <option name='Purpose' value='Bank Qualified'>A-Bank Qualified</option>
                            <option name='Purpose' value='Structured'>B-Structured</option>
                            <option name='Purpose' value='CALCAP'>C-CALCAP</option>
                            <option name='Purpose' value='Title Loan'>D-Title Loan</option>
                        </select>
                    </div>
                    <div className="col-3">
                        Grid:
                        <select ref={register} className="form-control" name='grid' defaultValue={application.credit.grid} >
                            <option name='Grid' value='A'>A</option>
                            <option name='Grid' value='B'>B</option>
                            <option name='Grid' value='C'>C</option>
                            <option name='Grid' value='D'>D</option>
                        </select>
                    </div>
                    <div className="col-3">
                        Fee to Source:
                        <div className={`input-group`}>
                            <input ref={register} className="form-control" name="fee_to_source" defaultValue={application.credit.fee_to_source} maxLength={25} />
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col">
                        Documentation Fee:
                        <div className={`input-group`}>
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <CurrencyInput
                                className="form-control"
                                name="doc_fee"
                                placeholder="0.00"
                                defaultValue={doc_fee}
                                precision={2}
                                onChange={handleCurrencyChange}
                            />
                        </div>

                    </div>
                    <div className="col">
                        Inspection Fee:
                        <div className={`input-group`}>
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <CurrencyInput
                                className="form-control"
                                name="inspection_fee"
                                placeholder="0.00"
                                defaultValue={inspection_fee}
                                precision={2}
                                onChange={handleCurrencyChange}
                            />
                        </div>

                    </div>
                    <div className="col">
                        UCC Fee:
                        <div className={`input-group`}>
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <CurrencyInput
                                className="form-control"
                                name="ucc_fee"
                                placeholder="0.00"
                                defaultValue={ucc_fee}
                                precision={2}
                                onChange={handleCurrencyChange}
                            />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-check">
                            <input ref={register} id="ach_waived" className="form-check-input" type='checkbox' name='ach_waived' defaultChecked={application.credit.ach_waived} />
                            <label className="form-check-label" htmlFor="ach_waived">ACH Waived</label>
                        </div>

                        <div className="form-check">
                            <input ref={register} id="inspection" className="form-check-input" type='checkbox' name='inspection' defaultChecked={application.credit.inspection} />
                            <label className="form-check-label" htmlFor="inspection">Inspection</label>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col">
                        Resaler
                    <Resaler resaler={application.resaler} register={register}
                            // handleChange={(value) => {
                            //     handleResalerChange(value)
                            // }}
                            resaler_id={application.credit.resaler_id} setValue={(value)=>handleResalerChange(value)}/>
                    </div>
                </div>
                {/*<div className="row mb-3">*/}
                {/*    <div className="col">*/}
                {/*        Internal Comment:*/}
                {/*        <textarea ref={register} className="form-control" name="comment" value={application.credit.comment} rows="3"/>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </form>
        </div>

    )
}

export default Decision
