import { useQuery } from "react-query";
import Axios from "./Components/Mics/axiosInstance";

const buildUrl = (urlParts) => {
    return "/"+urlParts.join('/')
};

const getQuery = async (urlParts) => {
    const { data } = await Axios.get(buildUrl(urlParts));
    return data;
};
const updateQuery = async (urlParts, data) => {
    return Axios.put(buildUrl(urlParts), data)
};
const createQuery = async (urlParts, data) => {
    return Axios.post(buildUrl(urlParts), data)
};



//
// Application
//
const applicationsBase = "applications"
const getApplicationById = async (id) => {
    return getQuery([applicationsBase, id]);
}
const useApplication = (id) => {
    return useQuery([applicationsBase, id], () => getApplicationById(id));
}

const getApplications = async () => {
    return getQuery([applicationsBase]);
}
const useApplications = () => {
    return useQuery([applicationsBase], () => getApplications());
}



//
// ScoreCard
//
const scoreCardBase = "score_cards"
const getScoreCardByApplicationId = async (applicationId) => {
    return getQuery([applicationsBase, applicationId, scoreCardBase]);
}
const useScoreCard = (applicationId) => {
    return useQuery([scoreCardBase, applicationId], () => getScoreCardByApplicationId(applicationId));
}



//
// Sources
//
const sourceBase = "sources"
const getSourceById = async (id) => {
    return getQuery([sourceBase, id]);
}
const useSource = (id) => {
    return useQuery([sourceBase, id], () => getSourceById(id));
}

const getSources = async () => {
    return getQuery([sourceBase]);
};
const useSources = () => {
    return useQuery([sourceBase], () => getSources());
}

const createSource = async (data) => {
    return createQuery([sourceBase], data)
}
const updateSource = async (id, data) => {
    return updateQuery([sourceBase, id], data)
}


//
// Source Types
//
const sourceTypesBase = "source_types"
const getSourceTypes = async () => {
    return getQuery([sourceTypesBase]);
}
const useSourceTypes = () => {
    return useQuery([sourceTypesBase], () => getSourceTypes());
}




export default {
    useApplication, useApplications,
    useSource, useSources, updateSource, createSource,
    useSourceTypes,
    useScoreCard
};