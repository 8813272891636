import React, {useState} from 'react';
import {Link} from "react-router-dom";
import List from "../Vendor/List";
import AddOrSelect from "../Vendor/AddOrSelect";

const Vendor = ({application, applicationId}) => {
    const [modalState, setModalState] = useState(false)

    const openAddModal = () => {
        setModalState(true)
    }

    const closeAddModal = () => {
        setModalState(false)
    }

    const Footer = () => {
        return (
            <div className="row">
                <div className="col">
                    <hr/>
                    <Link className="btn btn-outline-secondary float-right" to={`/applications/${applicationId}/overview`}>
                        Go to Overview <i className="fas fa-chevron-right"/>
                    </Link>
                    <Link className="btn btn-outline-secondary" to={`/applications/${applicationId}/banking-info`}>
                        <i className="fas fa-chevron-left"/> Go to Banking Info
                    </Link>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="row mb-3">
                <div className="col">
                    <button className='btn btn-success float-right' onClick={openAddModal}><i className="fas fa-plus"/> Add New Vendor</button>
                </div>
            </div>
            <List applicationId={applicationId}/>
            <AddOrSelect open={modalState} toggle={closeAddModal} applicationId={applicationId}/>
            <Footer/>
        </div>
    );
}

export default Vendor