import React, {useEffect, useState} from 'react';
import { useQuery } from "react-query";
import {Link, useParams} from "react-router-dom";
import Overview from "./Tabs/Overview";
import Applicant from "./Tabs/Applicant";
import Source from "./Tabs/Source";
import Details from "./Tabs/Details";
import CorporateGuarantors from "./Tabs/CorporateGuarantors";
import PersonalGuarantors from "./Tabs/PersonalGuarantors";
import BankingInfo from "./Tabs/BankingInfo";
import Vendor from "./Tabs/Vendor";
import Axios from "../Mics/axiosInstance";

function useApplication(id) {
    return useQuery('get-application', async () => {
        const { data } = await Axios.get("/applications/"+id);
        return data;
    });
}

const tabs = [
    {
        name: 'Overview',
        stage: 'overview',
        component: Overview
    },
    {
        name: 'Applicant',
        stage: 'applicant',
        component: Applicant
    },
    {
        name: 'Source',
        stage: 'source',
        component: Source
    },
    {
        name: 'Details',
        stage: 'details',
        component: Details
    },
    {
        name: 'Personal Guarantors',
        stage: 'personal-guarantors',
        component: PersonalGuarantors
    },
    {
        name: 'Corporate Guarantors',
        stage: 'corporate-guarantors',
        component: CorporateGuarantors
    },
    {
        name: 'Banking Info',
        stage: 'banking-info',
        component: BankingInfo
    },
    {
        name: 'Vendor',
        stage: 'vendor',
        component: Vendor
    }
]

const Wrapper = () => {
    let { applicationId, stage } = useParams();
    const { data, isLoading } = useApplication(applicationId);

    if(isLoading){
        return <div>Loading</div>
    }

    if(!isLoading && Object.keys(data).length === 0){
        return <div className="alert alert-danger">Application does not exist</div>
    }

    if(data.id) {
        return (
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-header">
                            Application: #{applicationId}
                        </div>
                        <div className="card-body m-0 p-0 mt-2">
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    {tabs.map(tab => {
                                        return (
                                            <Link className={`nav-item nav-link ${tab.stage === stage ? "active" : ""}`} to={`/applications/${applicationId}/${tab.stage}`}>{tab.name}</Link>
                                        )
                                    })}
                                </div>
                            </nav>
                            <div className="p-3">
                                {tabs.map(tab => {
                                    if (tab.stage === stage) {
                                        const Component = tab.component;
                                        return <Component application={data} applicationId={applicationId} stage={stage}/>
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (<div>No Application Found</div>)
}

export default Wrapper