import React from "react";
import Vendor from "./Vendor";
import Money from "../Helpers/Money";
import {useQuery} from "react-query";
import Axios from "../Mics/axiosInstance";

function useApplication(id) {
    return useQuery(["application", id], async () => {
        const { data } = await Axios.get("/applications/"+id);
        return data
    });
}

export const VendorList = ({document}) => {
    const { data, isLoading } = useApplication(document.application_id);

    const handleVendorTotals = () => {
        var total = 0
        document.equipments.map((equipment) => {
            total += Number(equipment.cost)
        })
        return total
    }

    if(isLoading || data === undefined){
        return <div>Loading Vendors</div>
    }

    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        Vendor Prefunding
                    </div>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>
                                Vendor
                            </th>
                            <th>
                                Invoiced Amount
                            </th>
                            <th>
                                Prefunding Terms
                            </th>
                            <th>
                                Prefunding Amounts
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.vendors.map(vendor => {
                            return <Vendor vendor={vendor} application={data} document={document} />
                        })}
                        <tr>
                            <td className="text-right">
                                <strong>Total Vendor Invoiced:</strong>
                            </td>
                            <td>
                                <strong><Money amount={handleVendorTotals()}/></strong>
                            </td>
                            <td></td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        )
}

export default VendorList
