import React, {useState} from 'react';
import AddOrSelect from "../PersonalGuarantors/AddOrSelect";
import List from "../PersonalGuarantors/List";
import {Link} from "react-router-dom";

const PersonalGuarantors = ({application, applicationId}) => {
    const [modalState, setModalState] = useState(false)

    const openAddModal = () => {
        setModalState(true)
    }

    const closeAddModal = () => {
        setModalState(false)
    }

    const Footer = () => {
        return (
            <div className="row">
                <div className="col">
                    <hr/>
                    <Link className="btn btn-outline-secondary float-right" to={`/applications/${applicationId}/corporate-guarantors`}>
                        Go to Corporate Guarantors <i className="fas fa-chevron-right"/>
                    </Link>
                    <Link className="btn btn-outline-secondary" to={`/applications/${applicationId}/details`}>
                        <i className="fas fa-chevron-left"/> Go to Details
                    </Link>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="row mb-3">
                <div className="col">
                    <button className='btn btn-success float-right' onClick={openAddModal}><i className="fas fa-plus"/> Add Personal Guarantor</button>
                </div>
            </div>

            <List applicationId={applicationId}/>
            <AddOrSelect open={modalState} toggle={closeAddModal} applicationId={applicationId}/>
            <Footer/>
        </div>
    )
}

export default PersonalGuarantors