import React from 'react';
import { Redirect } from "react-router-dom"
import { useAuth } from '../../Auth/AuthProvider';

const Home = () => {
    let auth = useAuth();
    if(auth){
        return (<div className="row">
                <div className="col-md">
                    <div className="card mb-4">
                        <div className="card-header">
                            Dashboard
                        </div>
                        <div className="card-body text-center">
                            Welcome to Peak 2.0 by Dedicated Funding.
                        </div>
                    </div>
                </div>
            </div>
        )
    }else{
        return (<Redirect to="/login"></Redirect>)
    }
}
export default Home