import React, {useState} from 'react';
import {confirmAlert} from "react-confirm-alert";
import Axios from "../Mics/axiosInstance";
import { NotificationManager } from 'react-notifications';
import Date from "../Helpers/Date";

export const Item = ({item, triggerReload}) => {
    const confirmRemoveItem = () => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to remove this item?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: removeItem
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const removeItem = () => {
        Axios.delete("/billing_defaults/"+item.id).then((res) => {
            triggerReload()
            NotificationManager.success('Default Billing type has been removed.', 'Success')
        }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }

    return (
        <>
            <tr>
                <td>
                    {item.billing_method.name}
                </td>
                <td>
                    {item.account_holder_name}
                </td>
                <td>
                    {item.full_address}
                </td>
                <td>
                    {item.bank_name}
                </td>
                <td>
                    {item.routing_number}
                </td>
                <td>
                    {item.account_number}
                </td>
                <td>
                    {item.comment}
                </td>
                <td>
                    <Date date={item.created_at} time/>
                </td>
                <td>
                    {item.created_user.name}
                </td>

                <td>
                    <i className="fas fa-trash pointer" title="Remove this item" onClick={confirmRemoveItem}/>
                </td>
            </tr>
        </>
    )

}
export default Item



