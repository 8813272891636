import React, {useEffect} from 'react';
import { useForm } from "react-hook-form";
import BillingMethodsSelect from '../Inputs/BillingMethodsSelector'
import StatesSelector from '../Inputs/StatesSelector';
import Axios from "../Mics/axiosInstance";
import {NotificationManager} from 'react-notifications';
import {useQuery, useQueryClient} from "react-query";

function useBillings(contractNumber, type, typeId) {
    return useQuery(["billings", contractNumber, type, typeId], async () => {
        const { data } = await Axios.get('/documents/'+contractNumber+'/billings_locate?'+type+'_id='+typeId)
        return data;
    });
}

function useDefaultBillings(type, typeId) {
    return useQuery(["getDefaultBillings", type, typeId], async () => {
        const { data } = await Axios.get("/billing_defaults?type="+type+"&type_id="+typeId);
        return data;
    });
}


export const Billing = ({ type, typeId, contractNumber, triggerReload }) => {
    const billing = useBillings(contractNumber, type, typeId)
    const defaultBillings = useDefaultBillings(type, typeId)
    const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' })
    const [methodSelected, setMethodSelected] = React.useState('')
    const [choiceType, setChoiceType] = React.useState('')
    const queryClient = useQueryClient();

    const update = (formData) => {
        Axios.put('/documents/' + contractNumber + '/billings/' + billing.data.id, formData).then((res) => {
            setMethodSelected(res.data.billing_method.name)
            queryClient.refetchQueries(["billings", contractNumber, type, typeId])
        }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }

    const save = (formData) => {
        formData[type+"_id"] = typeId
        Axios.post('/documents/' + contractNumber + '/billings', formData).then((res) => {
            setMethodSelected(res.data.billing_method.name)
            queryClient.refetchQueries(["billings", contractNumber, type, typeId])
        }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }

    const setChoice = (event) => {
        event.preventDefault()
        let choice = event.target.getAttribute("data-id")
        setChoiceType(choice)

        if(choice !== 'new'){
            defaultBillings.data.billing_defaults.forEach((billing_default) => {
                if(billing_default.id === parseInt(choice)){
                    Axios.post('/documents/' + contractNumber + '/billings', billing_default).then((res) => {
                        return window.location.reload(false);
                    }).catch((error) => {
                        NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
                    })
                }
            })
        }
    }

    const handleBillingMethod = (billingInfo) => {
        setMethodSelected(billingInfo[1])
    }

    if (defaultBillings.isLoading || billing.isLoading) {
        return <div>Loading Default Billing Information</div>
    }


    return (
        <div>
            {billing.data !== undefined && billing.data.id !== undefined ?
                <form onBlur={handleSubmit(update)}>
                    <div className="row mb-3">
                        <div className="col-4">
                            <label htmlFor="billing_method_id" className="form-label">Billing Method <span className="required">*</span></label>
                            <BillingMethodsSelect register={register} whatToDo={handleBillingMethod} defaultValue={billing.data.billing_method_id} />
                        </div>

                        <div className="col">
                            <label htmlFor="account_holder_name" className="form-label">Account Holder's Name <span className="required">*</span></label>
                            <input className={`form-control ${errors.account_holder_name ? "is-invalid" : ""}`} type='text'  name='account_holder_name' ref={register} defaultValue={billing.data.account_holder_name} />
                        </div>
                    </div>
                    {
                        billing.data.billing_method.name === 'Check'
                            ?
                            <div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <label htmlFor="address" className="form-label">Address <span className="required">*</span></label>
                                        <input className="form-control" type="text" name="address" ref={register} defaultValue={billing.data.address} />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="city" className="form-label">City <span className="required">*</span></label>
                                        <input className="form-control" type="text" name="city" ref={register} defaultValue={billing.data.city} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <label htmlFor="state" className="form-label">State <span className="required">*</span></label>
                                        <StatesSelector register={register} value={billing.data.state} name="state" />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="zip" className="form-label">Zip <span className="required">*</span></label>
                                        <input className="form-control" type="text" name="zip" ref={register} defaultValue={billing.data.zip} maxLength={5}/>
                                    </div>
                                </div>

                            </div>
                            :
                            <div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <label htmlFor="bank_name" className="form-label">Bank Name <span className="required">*</span></label>
                                        <input className={'form-control'} type='text' name='bank_name' ref={register} defaultValue={billing.data.bank_name} />
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col">
                                        <label htmlFor="account_number" className="form-label">Account Number <span className="required">*</span></label>
                                        <input className={'form-control'} type='number' name='account_number' ref={register} defaultValue={billing.data.account_number} />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="routing_number" className="form-label">Routing Number <span className="required">*</span></label>
                                        <input className={'form-control'} type='text' name='routing_number' maxLength={9} ref={register} defaultValue={billing.data.routing_number} />
                                    </div>
                                </div>
                            </div>
                    }
                    <div className="row mb-3">
                        <div className="col">
                            Comment
                            <textarea className="form-control" name="comment" rows="3" ref={register} defaultValue={billing.data.comment}/>
                        </div>
                    </div>
                </form>
                :
                <div>
                    {defaultBillings.data.billing_defaults.length && choiceType === '' ?
                        <div>
                            <div className="font-weight-bold">
                                This entity has default billing information available to be selected. <br/>Please select if you would like to use the default billing information below or enter in new information.
                            </div>
                            <hr/>
                            <div>
                                {defaultBillings.data.billing_defaults.map(item => {
                                    return (
                                        <button className="btn btn-primary m-1" data-id={item.id} onClick={setChoice}>
                                            Use default <strong><u>{item.billing_method.name}</u></strong> information
                                        </button>
                                    )
                                })}
                            </div>
                            <hr/>
                            <div>
                                <button className="btn btn-danger m-1" data-id="new" onClick={setChoice}>
                                    I want to use new billing information
                                </button>
                            </div>
                        </div>
                        :
                        <form onBlur={handleSubmit(save)}>
                            <div className="row mb-3">
                                <div className="col-4">
                                    <label htmlFor="billing_method_id" className="form-label">Billing Method <span className="required">*</span></label>
                                    <BillingMethodsSelect register={register} whatToDo={handleBillingMethod} />
                                </div>

                                <div className="col">
                                    <label htmlFor="account_holder_name" className="form-label">Account Holder's Name <span className="required">*</span></label>
                                    <input className={`form-control ${errors.account_holder_name ? "is-invalid" : ""}`} type='text' name='account_holder_name' ref={register} />
                                </div>
                            </div>
                            {
                                methodSelected==="Check"
                                    ?
                                    <div>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <label htmlFor="address" className="form-label">Address <span className="required">*</span></label>
                                                <input className="form-control" type="text" name="address" ref={register} />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="city" className="form-label">City <span className="required">*</span></label>
                                                <input className="form-control" type="text" name="city" ref={register} />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <label htmlFor="state" className="form-label">State <span className="required">*</span></label>
                                                <StatesSelector register={register} name="state" />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="zip" className="form-label">Zip <span className="required">*</span></label>
                                                <input className="form-control" type="text" name="zip" ref={register} maxLength={5}/>
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    <div>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <label htmlFor="bank_name" className="form-label">Bank Name <span className="required">*</span></label>
                                                <input className={'form-control'} type='text' name='bank_name' ref={register}/>
                                            </div>
                                        </div>

                                        <div className="row mb-4">
                                            <div className="col">
                                                <label htmlFor="account_number" className="form-label">Account Number <span className="required">*</span></label>
                                                <input className={'form-control'} type='number' name='account_number' ref={register}/>
                                            </div>
                                            <div className="col">
                                                <label htmlFor="routing_number" className="form-label">Routing Number <span className="required">*</span></label>
                                                <input className={'form-control'} type='text' name='routing_number' maxLength={9} ref={register}/>
                                            </div>
                                        </div>
                                    </div>
                            }
                            <div className="row mb-3">
                                <div className="col">
                                    Comment
                                    <textarea className="form-control" name="comment" rows="3" ref={register}/>
                                </div>
                            </div>
                        </form>
                    }
                </div>
            }
        </div>
    )
}

export default Billing
