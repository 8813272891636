import React from 'react';
import { useQuery } from "react-query";
import Axios from "../Mics/axiosInstance";

function useBillingMethods() {
    return useQuery(["billing-methods"], async () => {
        const { data } = await Axios.get("/billing_methods");
        return data;
    });
}

export const BillingMethodsSelect = ({defaultValue, register, errors, whatToDo}) => {
    const { data, isLoading } = useBillingMethods();

    const handleChange = (id) =>{
        data.forEach((method) => {
            if(parseInt(id)===method.id){
                whatToDo([method.id, method.name])
            }
        })
    }

    if (isLoading || data === undefined) {
        return (<></>)
    }

    if (data.length) {
        return (
            <select ref={register} name="billing_method_id" defaultValue={defaultValue} className={`form-control`} id="billing_method_id" onChange={(e)=>handleChange(e.target.value)} >
                <option value="" disabled selected>Select billing method</option>
                {data.map(option => {
                    return (<option value={option.id}>{option.name}</option>)
                })}
            </select>
        )
    }
}
export default BillingMethodsSelect