import React from 'react';
import {useForm} from "react-hook-form";
import {useQueryClient} from 'react-query'
import Axios from "../../Mics/axiosInstance";
import CurrencyInput from "react-currency-input-field";
import { NotificationManager } from 'react-notifications';

export const ScoringSheet = ({application}) => {
    const { register, handleSubmit } = useForm({mode: 'onBlur'});

    const [additional_collateral_cash, set_additional_collateral_cash] = React.useState(application.credit.additional_collateral_cash);
    const [estimated_payment, set_estimated_payment] = React.useState(application.credit.estimated_payment);

    const save = (data) => {
        data.additional_collateral_cash = additional_collateral_cash
        data.estimated_payment = estimated_payment
        Axios.put('/credits/'+application.credit.id, data).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }

    const handleCurrencyChange = (value, name)=>{
        if(name === 'additional_collateral_cash'){
            set_additional_collateral_cash(value)
        }
        if(name === 'estimated_payment'){
            set_estimated_payment(value)
        }
    }

    return (
        <div>
            <form onBlur={handleSubmit(save)}>

                <div className="row mb-3">
                    <div className="col-2">
                        SBSS:
                        <input ref={register} className="form-control" name='sbss' defaultValue={application.credit.sbss}/>
                    </div>
                    <div className="col-2">
                        Percentile:
                        <div className={`input-group`}>
                            <input ref={register} className="form-control" name='percentile' defaultValue={application.credit.percentile} />
                            <div className="input-group-append">
                                <span className="input-group-text">%</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        Paynet Score Date:
                        <input type="date" ref={register} className="form-control" name='paynet_score_date' defaultValue={application.credit.paynet_score_date}/>
                    </div>
                    <div className="col-3">
                        Paynet Score:
                        <input ref={register} className="form-control" name='paynet_score' defaultValue={application.credit.paynet_score}/>
                    </div>
                    <div className="col">
                        Comp Credit:
                        <div className={`input-group`}>
                            <input ref={register} className="form-control" name='comp_credit' defaultValue={application.credit.comp_credit} />
                            <div className="input-group-append">
                                <span className="input-group-text">%</span>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row mb-3">
                    <div className="col">
                        Judgements:
                        <select ref={register} className="form-control" defaultValue={application.credit.judgements} name="judgements">
                            <option value="" selected disabled/>
                            <option value="None">None</option>
                            <option value="Satisfied">Satisfied</option>
                            <option value="Not Satisfied">Not Satisfied</option>
                        </select>
                    </div>
                    <div className="col">
                        Tax Liens:
                        <select ref={register} className="form-control" defaultValue={application.credit.tax_lean} name="tax_lean">
                            <option value="" selected disabled/>
                            <option value="None">None</option>
                            <option value="Satisfied">Satisfied</option>
                            <option value="Not Satisfied">Not Satisfied</option>
                        </select>
                    </div>
                    <div className="col">
                        Lawsuits:
                        <select ref={register} className="form-control" defaultValue={application.credit.lawsuit} name="lawsuit">
                            <option value="" selected disabled/>
                            <option value="None">None</option>
                            <option value="Satisfied">Satisfied</option>
                            <option value="Not Satisfied">Not Satisfied</option>
                        </select>
                    </div>
                    <div className="col">
                        Slow Pays:
                        <select ref={register} className="form-control" name="slow_pay" defaultValue={application.credit.slow_pay} >
                            <option value={false}>No</option>
                            <option value={true}>Yes</option>
                        </select>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col">
                        Loses on Paynet:
                        <select ref={register} className="form-control" name="loses_on_paynet" defaultValue={application.credit.loses_on_paynet} >
                            <option value={false}>No</option>
                            <option value={true}>Yes</option>
                        </select>
                    </div>
                    <div className="col">
                        OLV:
                        <div className={`input-group`}>
                            <input ref={register} className="form-control" type='number' name='olv' defaultValue={application.credit.olv} />
                            <div className="input-group-append">
                                <span className="input-group-text">%</span>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        Additional Collateral:
                        <div className={`input-group`}>
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <CurrencyInput
                                className="form-control"
                                name="additional_collateral_cash"
                                placeholder="0.00"
                                defaultValue={application.credit.additional_collateral_cash}
                                precision={2}
                                onChange={handleCurrencyChange}
                            />
                        </div>

                    </div>
                    <div className="col">
                        OFAC:
                        <select ref={register} className="form-control" name="ofac" defaultValue={application.credit.ofac}>
                            <option value={true}>Record Found</option>
                            <option value={false}>No Records</option>
                        </select>
                    </div>
                </div>


                <div className="row mb-3">
                    <div className="col">
                        <div className="form-check">
                            <input ref={register} id="bankruptcy" className="form-check-input" type='checkbox' name='bankruptcy' defaultChecked={application.credit.bankruptcy} />
                            <label className="form-check-label" htmlFor="bankruptcy">Bankruptcy</label>
                        </div>
                    </div>
                    <div className="col">
                        Age of Oldest Equipment
                        <input ref={register} className="form-control" type='number' name='age_of_equipment' defaultValue={application.credit.age_of_equipment} />
                    </div>
                    <div className="col">
                        Estimated Payment:
                        <div className={`input-group`}>
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <CurrencyInput
                                className="form-control"
                                name="estimated_payment"
                                placeholder="0.00"
                                defaultValue={application.credit.estimated_payment}
                                precision={2}
                                onChange={handleCurrencyChange}
                            />
                        </div>
                    </div>
                    <div className="col">
                        Discretionary:
                        <div className={`input-group`}>
                            <input ref={register} className="form-control" type='text' name='discretionary' defaultValue={application.credit.discretionary} />
                            <div className="input-group-append">
                                <span className="input-group-text">%</span>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
        </div>

    )
}

export default ScoringSheet
