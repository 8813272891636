import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import SiteWrapper from "../Theme/Components/SiteWrapper";

export const PrivateRoute = ({ children, requiredRoles=[], ...rest }) => {
    let auth = useAuth();
    let userHasRequiredRoles = false
    let roles = localStorage.getItem('peak-roles')

    if(roles){
        roles = JSON.parse(roles)
        if(roles.includes('ADMIN')){
            userHasRequiredRoles = true
        }else{
            userHasRequiredRoles = requiredRoles.some(role=> roles.includes(role))
        }
        
    }else{
        console.log('Localstorage roles could not be located')
    }

    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth && userHasRequiredRoles ? (
                    <SiteWrapper>{children}</SiteWrapper>
                ) : (
                    <Redirect
                        to={{
                            pathname: userHasRequiredRoles ? "/" : "/unauthorized",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}