import React from 'react';
import {useForm} from "react-hook-form";
import Axios from "../../Mics/axiosInstance";
import CurrencyInput from '../../Inputs/CurrencyInput'
import { NotificationManager } from 'react-notifications';

export const Document = ({document, triggerReload}) => {
    const { register, handleSubmit, setValue } = useForm({mode: 'onBlur'});

    const save = (data) => {
        Axios.put('/applications/'+document.application_id+'/documents/'+document.contract_number, data).then(()=>triggerReload()).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }
    return (
        <div>
            <form onBlur={handleSubmit(save)}>
                <div className="row mb-4">
                    <div className="col">
                        Signer Title
                        <input className="form-control" type="text" name="signer_title" ref={register} defaultValue={document.signer_title}/>
                    </div>
                    <div className="col">
                        Signer Name
                        <input className="form-control" type="text" name="signer_name" ref={register} defaultValue={document.signer_name}/>
                    </div>
                    <div className="col">
                        Signer Email
                        <input className="form-control" type="email" name="signer_email" ref={register} defaultValue={document.signer_email}/>
                    </div>
                    <div className="col">
                        Accounting Email
                        <input className="form-control" type="email" name="accounting_email" ref={register} defaultValue={document.accounting_email}/>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-4">
                        Loan Amount
                        <CurrencyInput name="loan_amount" value={document.loan_amount} setValue={setValue} register={register}  />
                    </div>
                    <div className="col-4">
                        Terms
                        <input className="form-control" type="number" name="term" defaultValue={document.term} ref={register} />
                    </div>
                    <div className="col-4">
                        Remaing Terms
                        <input className="form-control" type="number" name="remaining_terms" defaultValue={document.remaining_terms} ref={register} />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Document