import React from 'react';
import {useParams} from "react-router-dom";
import {useQuery, useQueryClient} from "react-query";
import Axios from "../Mics/axiosInstance";
import Disbursement from "./Disbursement";
import SourceBilling from "./Source/SourceBilling";
import VendorBilling from "./Vendors/VendorBilling";
import Prefunding from "../Prefundings/Prefunding";
import MailMerge from "./MailMerge";


function useDocumentApplication(contractNumber) {
    return useQuery(["document", contractNumber, "application"], async () => {
        const { data } = await Axios.get("/documents/"+contractNumber+"/application");
        return data;
    });
}

export const DisbursementManager = () => {
    const { contractNumber } = useParams()
    const { data, isLoading } = useDocumentApplication(contractNumber)
    const queryClient = useQueryClient();

    const triggerReload = () => {
        queryClient.refetchQueries(["document", contractNumber, "application"])
    }


    const document = () => {
        var doc = data.documents.filter(doc => {
            return String(doc.contract_number) === contractNumber
        })

        return doc[0]
    }

    if(isLoading || data === undefined){
        return <div>Loading information</div>
    }

    if(!isLoading && Object.keys(data).length === 0){
        return <div className="alert alert-danger">Contract Number does not exist</div>
    }
    return (
        <div className="row">
            <div className="col">
                <SourceBilling document={document()} application={data} triggerReload={()=>triggerReload()}/>
                <hr/>
                <Disbursement document={document()} application={data} triggerReload={()=>triggerReload()}/>
                <hr/>
                <VendorBilling document={document()} application={data} triggerReload={()=>triggerReload()}/>
                <hr/>
                <Prefunding document={document()} application={data} triggerReload={()=>triggerReload()}/>
                <hr/>
                <MailMerge document={document()} application={data}/>
            </div>
        </div>
    )
}

export default DisbursementManager
