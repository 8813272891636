import React, { useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import Axios from "../Mics/axiosInstance";
import CurrencyInput from "../Inputs/CurrencyInput";
import { NotificationManager } from 'react-notifications';

export const Add = ({ vendor, document, application, modalState, toggleModal, triggerReload }) => {
    const { register, handleSubmit, setValue } = useForm();
    const [isOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
        if (modalState) {
            setIsOpen(true)
        }
    }, [modalState, setIsOpen]);

    const hideModal = () => {
        toggleModal()
        setIsOpen(false);
    };

    const save = (formData) => {
        formData.vendor_id = vendor.id
        Axios.post('/documents/' + document.contract_number + '/prefundings', formData).then((res) => {
            hideModal()
            triggerReload(res.data)
        }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    };

    return (
        <Modal show={isOpen} onHide={hideModal} size="lg">
            <form onSubmit={handleSubmit(save)}>
                <Modal.Header>
                    <Modal.Title>Add Disbursement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-3">
                        <div className="col">
                            <label className="form-label">Disbursement Amount</label>
                            <CurrencyInput name="amount" setValue={setValue} register={register} />
                        </div>
                        <div className="col">
                            <label className="form-label">Release Date</label>
                            <input type="date" className="form-control" name="release_date" ref={register} />
                        </div>
                    </div>
                    {application.source.is_dfny ?
                        <div className="row mb-3">
                            <div className="col">
                                <label className="form-label">Doc Fee</label>
                                <CurrencyInput name="doc_fee" setValue={setValue} register={register} />
                            </div>
                            <div className="col">
                                <label className="form-label">Vendor Commission</label>
                                <CurrencyInput name="vendor_commission" setValue={setValue} register={register} />
                            </div>
                        </div>
                        :
                        ''
                    }

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={(e) => { e.preventDefault(); hideModal() }}>Cancel</button>
                    <button className="btn btn-primary" type="submit">Save</button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default Add
