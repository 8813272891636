import React from 'react';
import { useForm } from "react-hook-form";
import Axios from "../../../Mics/axiosInstance";
import StateOptions from "../../../SelectOptions/StateOptions";
import {NotificationManager} from 'react-notifications';

export const CorporateGuarantor = ({ application, guarantor }) => {
    const { register, handleSubmit } = useForm({ mode: 'onBlur' });

    const save = (data) => {
        Axios.put('/corporate_guarantors/' + guarantor.id, data).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }
    return (
        <form onBlur={handleSubmit(save)}>
            <div className="row mb-3">
                <div className="col">
                    <label className="form-label">Company Name</label>
                    <input ref={register} className="form-control" type='text' name="company_name" defaultValue={guarantor.company_name} />
                </div>
                {/* <div className="col">
                    <label className="form-label">DBA Name</label>
                    <input ref={register} className="form-control" type='text' name="dba_name" defaultValue={guarantor.dba_name} />
                </div> */}
                <div className="col">
                    <label className="form-label">Signors Name</label>
                    <input ref={register} className="form-control" type='text' name="signer_name" defaultValue={guarantor.signer_name} />
                </div>
                <div className="col">
                    <label className="form-label">Signor's Title</label>
                    <input ref={register} className="form-control" type='text' name="signer_title" defaultValue={guarantor.signer_title} />
                </div>

            </div>

            <div className="row mb-3">
                <div className='col'>
                    <label className="form-label">Address</label>
                    <input ref={register} className="form-control" type="text" name='address' defaultValue={guarantor.address} />
                </div>
                <div className="col">
                    <label className="form-label">City</label>
                    <input ref={register} className="form-control" type="text" name='city' defaultValue={guarantor.city} />
                </div>
                <div className="col">
                    <label className="form-label">State</label>
                    <select ref={register} className="form-control" name='state' defaultValue={guarantor.state}>
                        <StateOptions />
                    </select>
                </div>
                <div className="col">
                    <label className="form-label">Zip</label>
                    <input ref={register} className="form-control" type="text" name='zip' defaultValue={guarantor.zip} />
                </div>
            </div>

            <div className="row mb-3">
                <div className="col">
                    <label className="form-label">Phone Number</label>
                    <input ref={register} className="form-control" name="phone_number" type='text' defaultValue={guarantor.phone_number} />
                </div>
                <div className="col">
                    <label className="form-label">Federal ID</label>
                    <input ref={register} className="form-control" type="text" name='federal_id' defaultValue={guarantor.federal_id} />
                </div>
                <div className="col">
                    <label className="form-label">Date Established</label>
                    <input ref={register} type="date" className="form-control" name="date_established" defaultValue={guarantor.date_established} />
                </div>
                <div className="col">
                    <label className="form-label">Jurisdiction</label>
                    <select ref={register} className="form-control" name='jurisdiction' defaultValue={guarantor.jurisdiction}>
                        <StateOptions />
                    </select>
                </div>
            </div>
        </form>
    )
}

export default CorporateGuarantor
