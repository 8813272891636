import React, { useState } from 'react';
import { useForm } from 'react-hook-form'
import CurrencyInput from '../../Inputs/CurrencyInput';
import PercentageInput from '../../Inputs/PercentageInput';

export const TaxTreatment = ({ document, setValue, inputRef }) => {
    const [treatment, setTreatment] = useState(document.upfront)

    const handleExtra = () => {
        if (treatment === "Exempt") {
            return <div className="col-4">
                Exemption Certificate
                <input name='exempt_certificate' defaultValue={document.exempt_certificate} ref={inputRef} className="form-control" />
            </div>
        }
        if (treatment === "To State") {
            return <div className="col-4">
                Sales Tax Amount
                <CurrencyInput name='sales_tax_amount' value={document.sales_tax_amount} register={inputRef} className="form-control" setValue={setValue} />
            </div >
        }
        if (treatment === "Stream") {
            return <div className="col-4">
                Sales Tax Rate
                <PercentageInput name='sales_tax_rate' value={document.sales_tax_rate} register={inputRef} className="form-control" setValue={setValue} />
            </div >
        }

    }
    return (
        <div>
            <div className="row mb-4">
                <div className="col-4">
                    Upfront
                    <select className="form-control" defaultValue={document.upfront} name='upfront' ref={inputRef} onChange={(e) => setTreatment(e.target.value)}  >
                        <option value='None' >None</option>
                        <option value='Exempt'>Exempt</option>
                        <option value="To State">To State</option>
                        <option value="To Vendor">To Vendor</option>
                        <option value="Stream">On Stream</option>
                    </select>
                </div>

                {handleExtra()}

            </div>
        </div>
    )

}
export default TaxTreatment