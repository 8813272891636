import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import List from "../Source/List";
import View from "../Source/View";
import Add from "../Source/Add";

const Source = ({application, applicationId}) => {
    const [view, setView] = useState('select-source');

    useEffect(() => {
        if(application.source_id){
            setView('view-source')
        }
    }, [setView, application]);

    const changeSource = () => {
        setView('select-source')
    }

    const addSource = () => {
        setView('add-source')
    }

    const Footer = () => {
        return (
            <div className="row">
                <div className="col">
                    <hr/>
                    <Link className="btn btn-outline-secondary float-right" to={`/applications/${applicationId}/details`}>
                        Go to Details <i className="fas fa-chevron-right"/>
                    </Link>
                    <Link className="btn btn-outline-secondary" to={`/applications/${applicationId}/applicant`}>
                        <i className="fas fa-chevron-left"/> Go to Applicant
                    </Link>
                </div>
            </div>
        )
    }

    if(view === 'view-source'){
        return (
            <div>
                <div className="row mb-3">
                    <div className="col">
                        <button className='btn btn-success float-right' onClick={changeSource}><i className="fas fa-pencil-alt"/> Change Source</button>

                    </div>
                </div>
                <hr/>
                <View sourceId={application.source_id} />
                <Footer/>
            </div>
        );
    }

    // TODO: THIS BEAST
    // if(view === 'add-source'){
    //     return (
    //         <div>
    //             <div className="row mb-3">
    //                 <div className="col">
    //                     <button className='btn btn-success float-right' onClick={changeSource}><i className="fas fa-pencil-alt"/> Change Source</button>
    //                 </div>
    //             </div>
    //             <Add applicationId={applicationId} />
    //             <Footer/>
    //         </div>
    //     );
    // }

    if(view === 'select-source'){
        return (
            <div>
                {/*<div className="row mb-3">*/}
                {/*    <div className="col">*/}
                {/*        <button className='btn btn-success float-right mr-3' onClick={addSource}><i className="fas fa-plus"/> Add New Source</button>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <List />
                <Footer/>
            </div>
        );
    }

    return (
        <div>
            No View found in the Source.js file
            <Footer/>
        </div>
    );
}

export default Source