import axios from 'axios'
import { NotificationManager } from 'react-notifications';
var os = require("os");

let baseURL = '/api'
if (os.hostname() === 'localhost') {
    baseURL = 'http://localhost:3000'
}

const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
        'X-HTTP-PEAK-TOKEN': localStorage.getItem('peak-token')
    }
});

axiosInstance.interceptors.response.use(
    (response) => {
        return new Promise((resolve, reject) => {
            resolve(response)
        })
    },
    (error) => {
        if (error.response.status === 401) {
            localStorage.removeItem('peak-token');
            localStorage.removeItem('peak-email');
            localStorage.removeItem('peak-name');
            localStorage.removeItem('peak-roles');
            return window.location.replace('/')
        }
        if (error.response.status === 422) {
            var message = {}
            Object.entries(error.response.data).forEach(([key, value]) => {
                message = { key: key, message: value }
            });
            return Promise.reject(message)
            // NotificationManager.error(`${message}`, 'Validation Error')
            // NotificationManager.error(Object.entries(error.response.data)[0][1],Object.entries(error.response.data)[0][0])
        }
        return Promise.reject(error)
    }
)
export default axiosInstance