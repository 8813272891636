import React from 'react';
import { useQuery } from "react-query";
import Axios from "../Mics/axiosInstance";

function useBusinessTypes() {
    return useQuery(["business-types"], async () => {
        const { data } = await Axios.get("/business_types");
        return data;
    });
}

export const BusinessTypeSelect = ({value, register}) => {
    const { data, isLoading } = useBusinessTypes();

    if (isLoading || data === undefined) {
        return (<></>)
    }

    if (data.length) {
        return (
            <select ref={register} name="business_type_id" defaultValue={value} className="form-control" id="business_type_id">
                <option value="" disabled selected>Select business type</option>
                {data.map(option => {
                    return (<option value={option.id}>{option.name}</option>)
                })}
            </select>
        )
    }
}
export default BusinessTypeSelect