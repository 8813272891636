import React from 'react';

export const PercentageInput = ({register, name, id, value, setValue, className, max}) => {
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const formatPercentage = (event) => {
        let percentage = (event.target.value).replace(/[^0-9.]/g, '')
        if(percentage !== ''){
            if(parseFloat(percentage) >= parseFloat(max)){
                setValue(name, formatter.format(max))
            }else{
                setValue(name, formatter.format(percentage))
            }
        }else{
            setValue(name, '')
        }
    }

    if(setValue === undefined || setValue === ''){
        console.log("setValue param must be set when using <PercentageInput ..., You must pass this in from useForm")
    }
    if(register === undefined || register === ''){
        console.log("register param must be set when using <PercentageInput ..., You must pass this in from useForm")
    }
    if(name === undefined || name === ''){
        console.log("name param must be set when using <PercentageInput name=''...")
    }

    if(className === undefined || className === ''){
        className = 'form-control'
    }

    if(max === undefined || max === ''){
        max = 100.00
    }

    if(id === undefined || id === ''){
        id = name
    }

    if(value){
        value = formatter.format(value)
    }

    return (
        <div className="input-group">
            <input ref={register} name={name} id={id} className={className} type="text" onBlur={formatPercentage} defaultValue={value} />
            <div className="input-group-append">
                <span className="input-group-text">%</span>
            </div>
        </div>
    )
}

export default PercentageInput