import React from 'react';
import Axios from "../../Mics/axiosInstance";
import CurrencyInput from "react-currency-input-field";
import { NotificationManager } from 'react-notifications';

export const Application = ({ application, applicationId }) => {

    const handleBlur = (event) => {
        save({ [event.target.name]: event.target.value })
    }

    const handleBlurMoney = (value, name) => {
        save({ [name]: value })
    }

    const save = (data) => {
        Axios.put('/applications/' + applicationId, data).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }
    return (
        <div>
            <div className="row mb-4">
                <div className="col">
                    Equipment Cost
                    <div className={`input-group`}>
                        <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                        </div>
                        <CurrencyInput
                            className="form-control"
                            name="equipment_cost"
                            placeholder="0.00"
                            precision={2}
                            defaultValue={application.equipment_cost}
                            onBlurValue={handleBlurMoney}
                        />
                    </div>
                </div>




                <div className="col">
                    Loan Amount
                    <div className={`input-group`}>
                        <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                        </div>
                        <CurrencyInput
                            className="form-control"
                            name="submitted_amount"
                            placeholder="0.00"
                            precision={2}
                            defaultValue={application.submitted_amount}
                            onBlurValue={handleBlurMoney}
                        />

                    </div>
                </div>

            </div>
            <div className="row mb-4">
                <div className="col">
                    Requested Term
                    <input className="form-control" name="requested_terms" defaultValue={application.requested_terms} onBlur={handleBlur} />
                </div>
                <div className="col">
                    Collateral Description
                    <textarea className="form-control" name="collateral_description" rows="5" defaultValue={application.collateral_description} onBlur={handleBlur} />
                </div>
            </div>
            
        </div>

    )
}

export default Application
