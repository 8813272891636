import React, { useState } from 'react';
import {Redirect} from "react-router-dom";

export const Item = ({item}) => {
    const [redirect, setRedirect] = useState(false)

    if(redirect){
        return <Redirect to={`/vendors/${item.id}`}/>
    }

    return (
        <tr className="applicant-select" onClick={()=>setRedirect(true)}>
            <td>
                {item.company_name}
            </td>
            <td>
                {item.dba_name}
            </td>
            <td>
                {item.full_address}
            </td>
        </tr>
    )
}
export default Item