import React from 'react';
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import Axios from "../Mics/axiosInstance";
import BoardingSheet from './BoardingSheet'
import Document from './Document'
import ApplicantBilling from "../Disbursements/Applicant/ApplicantBilling";
function useDocument(contractNumber) {
    return useQuery(["documents", contractNumber], async () => {
        const { data } = await Axios.get("/documents/" + contractNumber);
        return data;
    });
}

export const BoardingSheetManager = () => {
    const { contractNumber } = useParams()
    const { data, isLoading } = useDocument(contractNumber);
    const queryClient = useQueryClient();

    const triggerReload = () => {
        queryClient.refetchQueries(["documents", contractNumber])
    }
    if (isLoading) {
        return <div>Loading information</div>
    }

    if (!isLoading && Object.keys(data).length === 0) {
        return <div className="alert alert-danger">Contract Number does not exist</div>
    }
   
    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        Boarding Sheet - Application ID: {data.application_id} - Contract Number: {data.contract_number}
                    </div>
                    <div className="card-body">
                        <Document document={data} triggerReload={()=>triggerReload()} />
                        <hr />
                        <BoardingSheet document={data} triggerReload={()=>triggerReload()}/>
                        <hr />
                        <ApplicantBilling document={data} triggerReload={()=>triggerReload()} />
                        {/* <button className="btn btn-sm btn-primary" onClick={save}>Save</button> */}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BoardingSheetManager
