import { useQuery } from "react-query"
import Money from "../Helpers/Money";
import Axios from "../Mics/axiosInstance"


function useApplication(id){
    return useQuery(["application-credit", id], async () =>{
        const {data} = await Axios.get('/applications/'+id);
        return data
    })
}
export const Credit = ({document}) =>{
    const {data, isLoading} = useApplication(document.application_id)
    
    if (isLoading){
        return <div>Loading Credit</div>
    }
    return <div className="row">
        <div className="col">
            <div className="card">
                <div className="card-header">
                    Credit - Application ID# <strong>{data.id}</strong>
                </div>
                <div className="card-body">
                    <div className="row mb-3">
                        <div className="col">
                            <label className="form-label">Approved Amount</label>
                            <br/>
                            <Money amount={data.credit?.approved_amount} />
                        </div>
                        <div className="col">
                            <label className="form-label">Approved Term</label>
                            <br/>
                            {data.credit?.terms}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
}