import React, {useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import Add from "./Add";
import Select from "./Select";

export const AddOrSelect = ({applicationId, open, toggle}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [type, setType] = React.useState('select');

    useEffect(() => {
        if(open){
            setIsOpen(true)
        }
    }, [open, setIsOpen])

    const hideModal = () => {
        toggle()
        setIsOpen(false);
    }

    const setSelectType = () => {
        setType('select');
    }

    const setAddType = () => {
        setType('add');
    }

    if(type === 'select'){
        return (
            <Modal show={isOpen} onHide={hideModal} size="lg">
                <Select applicationId={applicationId} hideModal={hideModal} setType={setAddType}/>
            </Modal>
        )
    }
    if(type === 'add'){
        return (
            <Modal show={isOpen} onHide={hideModal} size="lg">
                <Add applicationId={applicationId} toggle={hideModal} open={hideModal} setType={setSelectType}/>
            </Modal>
        )
    }
    return (
        <div>Error</div>
    )
}
export default AddOrSelect