import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from '../../Auth/PrivateRoute';
import Roles from "../Config/Roles";
import ApplicationList from '../Application/List';
import ApplicationNew from '../Application/New';
import ApplicationWrapper from '../Application/Wrapper';
import ApplicantList from '../Applicants/List';
import ApplicantShow from '../Applicants/Show';
import ApplicantAdd from '../Applicants/Add';
import SourceList from '../Sources/List'
import SourceAdd from '../Sources/Add'
import SourceShow from '../Sources/Show'
import Home from "../Pages/Home";
import Unauthorized from "../Pages/Unauthorized";
import CreditSearch from '../Credit/Search'
import CreditUnderwriting from '../Credit/Underwriting'
import CreditScoreCard from '../Credit/Scorecard'
import CreditAdditional from '../Credit/Additional'
import CreditDownload from '../Credit/Download'
import NewUpdate from '../Sales/Update';
import Login from '../Authentication/Login'
import SiteWrapper from '../../Theme/Components/SiteWrapper'
import ApplicationStatus from "../Sales/ApplicationStatus";
import DocSearch from "../DocRequest/Search";
import DocRequestRouter from "../DocRequest/DocRequestRouter";
import DocumentGenerator from "../DocRequest/DocumentGenerator";
import DocumentList from "../DocRequest/DocumentList";
import DocInput from "../DocInput/DocInput";
import CreditStatus from "../Documentation/Status";
import CreditStatusUpdate from "../Documentation/StatusUpdate";
import DisbursementSearch from "../Disbursements/Search";
import DisbursementManager from "../Disbursements/DisbursementManager";
import BoardingSheetSearch from "../BoardingSheet/Search";
import BoardingSheetManager from "../BoardingSheet/BoardingSheetManager";
import FollowupList from "../Followups/List";
import AdminUsersList from "../Admin/Users/List";
import VendorList from "../Vendor/List";
import VendorAdd from "../Vendor/Add";
import VendorShow from "../Vendor/Show";
import ReportList from "../Reports/List";
import ReportGenerator from "../Reports/ReportGenerator";

export const Routes = () => {
    return (
        <React.Fragment>
            <Switch>
                <PrivateRoute exact path='/credit-applications' requiredRoles={[Roles.CREDIT]}>
                    <CreditSearch/>
                </PrivateRoute>
                <PrivateRoute exact path='/credit-applications/:id/underwriting' requiredRoles={[Roles.CREDIT, Roles.APPROVAL]}>
                    <CreditUnderwriting/>
                </PrivateRoute>
                <PrivateRoute exact path='/credit-applications/:id/scorecard' requiredRoles={[Roles.CREDIT, Roles.APPROVAL]}>
                    <CreditScoreCard/>
                </PrivateRoute>
                <PrivateRoute exact path='/credit-applications/:id/additional-info' requiredRoles={[Roles.CREDIT, Roles.APPROVAL]}>
                    <CreditAdditional/>
                </PrivateRoute>
                <PrivateRoute exact path='/credit-applications/:id/download-materials' requiredRoles={[Roles.CREDIT, Roles.APPROVAL]}>
                    <CreditDownload/>
                </PrivateRoute>

                <PrivateRoute exact path='/credit-status/:contractNumber' requiredRoles={[Roles.SALES, Roles.DOCUMENT, Roles.COLLECTION]}>
                    <CreditStatusUpdate/>
                </PrivateRoute>
                <PrivateRoute path='/credit-status' requiredRoles={[Roles.SALES, Roles.DOCUMENT, Roles.COLLECTION]}>
                    <CreditStatus/>
                </PrivateRoute>


                <PrivateRoute exact path='/applicants' requiredRoles={[Roles.SALES, Roles.CREDIT, Roles.DOCUMENT]}>
                    <ApplicantList/>
                </PrivateRoute>
                <PrivateRoute exact path='/applicants/new' requiredRoles={[Roles.SALES, Roles.CREDIT, Roles.DOCUMENT]}>
                    <ApplicantAdd/>
                </PrivateRoute>
                <PrivateRoute exact path='/applicants/:id' requiredRoles={[Roles.SALES, Roles.CREDIT, Roles.DOCUMENT]}>
                    <ApplicantShow/>
                </PrivateRoute>

                <PrivateRoute exact path='/disbursements' requiredRoles={[Roles.DOCUMENT]}>
                    <DisbursementSearch/>
                </PrivateRoute>
                <PrivateRoute exact path='/disbursements/:contractNumber' requiredRoles={[Roles.DOCUMENT]}>
                    <DisbursementManager/>
                </PrivateRoute>

                <PrivateRoute exact path='/boarding-sheets' requiredRoles={[Roles.DOCUMENT]}>
                    <BoardingSheetSearch/>
                </PrivateRoute>
                <PrivateRoute exact path='/boarding-sheets/:contractNumber' requiredRoles={[Roles.DOCUMENT]}>
                    <BoardingSheetManager/>
                </PrivateRoute>

                <PrivateRoute exact path='/applications' requiredRoles={[Roles.SALES, Roles.DOCUMENT, Roles.CREDIT]}>
                    <ApplicationList/>
                </PrivateRoute>
                <PrivateRoute exact path='/applications/new' requiredRoles={[Roles.SALES]}>
                    <ApplicationNew/>
                </PrivateRoute>
                <PrivateRoute exact path='/applications/:applicationId/:stage' requiredRoles={[Roles.SALES, Roles.CREDIT, Roles.DOCUMENT]}>
                    <ApplicationWrapper/>
                </PrivateRoute>

                <PrivateRoute exact path='/application-status/:applicationId/' requiredRoles={[Roles.SALES]}>
                    <ApplicationStatus/>
                </PrivateRoute>

                <PrivateRoute exact path='/doc-request/search' requiredRoles={[Roles.DOCUMENT, Roles.SALES]}>
                    <DocSearch/>
                </PrivateRoute>

                <PrivateRoute exact path='/generate-document/:applicationId' requiredRoles={[Roles.DOCUMENT, Roles.SALES]}>
                    <DocumentGenerator/>
                </PrivateRoute>
                <PrivateRoute exact path='/doc-request/document/:contractNumber' requiredRoles={[Roles.DOCUMENT, Roles.SALES]}>
                    <DocRequestRouter/>
                </PrivateRoute>
                <PrivateRoute exact path='/doc-request/application/:applicationId' requiredRoles={[Roles.DOCUMENT, Roles.SALES]}>
                    <DocumentList/>
                </PrivateRoute>


                <PrivateRoute exact path='/doc-input/:contractNumber/:applicationId' requiredRoles={[Roles.DOCUMENT]}>
                    <DocInput/>
                </PrivateRoute>

                <PrivateRoute exact path='/followups/:applicationId' requiredRoles={[Roles.DOCUMENT, Roles.COLLECTION]}>
                    <FollowupList/>
                </PrivateRoute>

                <PrivateRoute exact path='/sources/new' requiredRoles={[Roles.SALES, Roles.CREDIT, Roles.DOCUMENT]}>
                    <SourceAdd />
                </PrivateRoute>
                <PrivateRoute exact path='/sources/:id' requiredRoles={[Roles.SALES, Roles.CREDIT, Roles.DOCUMENT]}>
                    <SourceShow/>
                </PrivateRoute>
                <PrivateRoute exact path='/sources' requiredRoles={[Roles.SALES, Roles.CREDIT, Roles.DOCUMENT]}>
                    <SourceList/>
                </PrivateRoute>
                <PrivateRoute exact path='/source' requiredRoles={[Roles.SALES, Roles.CREDIT, Roles.DOCUMENT]}>
                    <SourceList/>
                </PrivateRoute>

                <PrivateRoute exact path='/updates' requiredRoles={[Roles.SALES, Roles.CREDIT, Roles.DOCUMENT]}>
                    <NewUpdate/>
                </PrivateRoute>

                <PrivateRoute exact path='/vendors' requiredRoles={[Roles.SALES, Roles.CREDIT, Roles.DOCUMENT]}>
                    <VendorList/>
                </PrivateRoute>
                <PrivateRoute exact path='/vendors/new' requiredRoles={[Roles.SALES, Roles.CREDIT, Roles.DOCUMENT]}>
                    <VendorAdd/>
                </PrivateRoute>
                <PrivateRoute exact path='/vendors/:id' requiredRoles={[Roles.SALES, Roles.CREDIT, Roles.DOCUMENT]}>
                    <VendorShow/>
                </PrivateRoute>


                <PrivateRoute exact path='/admin/users' requiredRoles={[Roles.ADMIN]}>
                    <AdminUsersList/>
                </PrivateRoute>

                <PrivateRoute exact path='/reports' requiredRoles={[Roles.REPORTS, Roles.ADMIN]}>
                    <ReportList/>
                </PrivateRoute>
                <PrivateRoute exact path='/reports/:report' requiredRoles={[Roles.REPORTS, Roles.ADMIN]}>
                    <ReportGenerator/>
                </PrivateRoute>

                <Route exact path="/unauthorized">
                    <Unauthorized />
                </Route>

                <Route path="/login">
                    <Login/>
                </Route>
                <Route exact path="/">
                    <SiteWrapper><Home /></SiteWrapper>
                </Route>
            </Switch>
        </React.Fragment>
    )

}
