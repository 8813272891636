import React, { useState } from 'react';
import Phone from "../../Helpers/Phone";
import FederalID from "../../Helpers/FederalID";
import Edit from "./Edit";
import Delete from './Delete'
export const CorporateGuarantor = ({ guarantor, applicationID }) => {
    const [modalState, setModalState] = useState(false)

    const openModal = () => {
        if (modalState === false) {
            setModalState(true)
        }
    }

    const closeModal = () => {
        setModalState(false)
    }

    return (
        <tr className="table-row-click">
            <td>
                {guarantor.company_name}
            </td>
            <td>
                <Phone number={guarantor.phone_number} />
            </td>
            <td>
                {guarantor.email}
            </td>
            <td>
                {guarantor.address}
            </td>
            <td>
                {guarantor.city}
            </td>
            <td>
                {guarantor.state}
            </td>
            <td>
                {guarantor.zip}
            </td>
            <td>
                <FederalID id={guarantor.federal_id} />
            </td>
            <td>
                <i className="fas fa-edit mr-2 pointer" onClick={openModal}/><Edit open={modalState} toggle={closeModal} guarantor={guarantor} />
            </td>
            <td>
                <Delete corporate_guarantor={guarantor} applicationID={applicationID} />
            </td>

        </tr>
    )

}
export default CorporateGuarantor