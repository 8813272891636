import React, {useEffect, useState} from 'react'
import { useQuery } from 'react-query'
import {Link, Redirect} from 'react-router-dom'
import Date from '../Helpers/Date'
import Money from '../Helpers/Money'
import Axios from "../Mics/axiosInstance"
import Item from './Item'

function useDocuments(){
    return useQuery(['documents', 'list'], async()=>{
        const {data} = await Axios.get('/documents/')
        return data
    })
}

export const List = () =>{
    const [loading, setLoading] = useState(true)
    const {data, isLoading} = useDocuments()
    const [contractNumber, setContractNumber] = useState(false)
    const [redirect, setRedirect] = useState(false)

    const tableRowClick = (id)=>{
        setContractNumber(id)
        setRedirect(true)
    }

    if(isLoading){
        return <div>Loading Contracts</div>
    }
    if(contractNumber && redirect){
        return (<Redirect to={`/doc-request/document/${contractNumber}`}/>)
    }
    let filteredContracts = data.length>0?data.filter((doc)=>doc.status!=='Prefunding'&&doc.status!=='Funded'):[]
    let sortedContracts = filteredContracts.length>0?filteredContracts.sort((a,b)=>b.contract_number-a.contract_number):[]

    return <div className="row">
        <div className="col-md">
            <div className="card mb-4">
                <div className="row">
                    <div className="col-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <td>
                                        Contract Number
                                    </td>
                                    <td>
                                        Company Name
                                    </td>
                                    <td>
                                        Loan Amount
                                    </td>
                                    <td>
                                        Status
                                    </td>
                                    <td>
                                        Created
                                    </td>
                                    <td>
                                        Doc Person
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sortedContracts.map(document=>{
                                        return <Item document={document} tableRowClick={(contractNumber)=>tableRowClick(contractNumber)}/>
                                    
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default List