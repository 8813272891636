import React, {useState} from "react";
import {useQuery, useQueryClient} from "react-query";
import Axios from "../Mics/axiosInstance";
import Item from "./Item";
import Add from "./Add";
import Money from "../Helpers/Money";

function useVendorPrefundings(contract_number, vendor_id) {
    return useQuery(["document", contract_number, 'vendor', vendor_id, 'prefundings'], async () => {
        const { data } = await Axios.get('/documents/'+contract_number+'/prefundings?vendor_id='+vendor_id)
        return data;
    });
}

export const List = ({vendor, document, application, parentReload}) => {
    const { data, isLoading } = useVendorPrefundings(document.contract_number, vendor.id);
    const [modalState, setModalState] = useState(false)
    const queryClient = useQueryClient();

    const triggerReload = () => {
        queryClient.refetchQueries(["document", document.contract_number, 'vendor', vendor.id, 'prefundings'])
        parentReload()
    }

    const toggleModal = () => {
        setModalState(!modalState)
    }
    const handleVendorInvoice = () => {
        var list = document.equipments.filter(equipment => equipment.vendor_id === vendor.id)
        var total = 0
        list.map(equipment => {
            if (equipment.cost) {
                total += Number(equipment.cost)
            }
        })
        return total
    }
    const calculateTotal = () => {
        let total = 0.0
        data.forEach((prefunding) => {
            if(prefunding.amount) {
                total = Number(prefunding.amount) + total
            }
        })
        return total
    }

    if(isLoading){
        return <div>Loading</div>
    }

    return (
        <div className="well-inverted">
            <button className="btn btn-primary btn-sm float-right" onClick={toggleModal}>
                <i className="fas fa-plus"/> Add Disbursement
            </button>

            <strong>{vendor.company_name}</strong>
            <br/>
            <strong>Invoiced Amount <Money amount={handleVendorInvoice()}/></strong>
            <table className="table mt-3">
                <thead>
                    <tr>
                        <th>Amount</th>
                        <th>Release Date</th>
                        {application.source.is_dfny ?
                            <>
                                <th>Doc Fee</th>
                                <th>Vendor Commission</th>
                            </>
                            :
                            ''
                        }
                        <th>Manage</th>
                    </tr>
                </thead>
                <tbody>
                {data.map(prefunding=>{
                    return <Item prefunding={prefunding} vendor={vendor} document={document} application={application} triggerReload={triggerReload} />
                })}
                {!data.length ?
                    <tr>
                        <td className="text-center" colSpan={99}>No disbursements has been added for this vendor</td>
                    </tr>
                    :
                    ''
                }
                </tbody>
            </table>
            {data.length ?
                <div className="row">
                    <div className="col">
                        <div className="font-weight-bold ml-2">
                            <Money amount={calculateTotal()}/> Grand Total
                        </div>
                    </div>
                </div>
                :
                ''
            }

            <Add modalState={modalState} toggleModal={toggleModal} vendor={vendor} document={document} application={application} triggerReload={triggerReload}/>
        </div>
    )
}

export default List
