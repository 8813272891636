import React from 'react';
import List from "./List";

export const VendorBilling = ({document, application, triggerReload}) => {

    //We only want to show the vendors that have equipment associated to the actual document, not the entire list of vendors off the application  as they can differ
    const equipmentVendors = () => {
        let uniqueVendors = []
        let uniqueVendorIds = []

        if(document.equipments.length){
            document.equipments.forEach((equipment) => {
                if(!uniqueVendorIds.includes(equipment.vendor_id)){
                    uniqueVendorIds.push(equipment.vendor_id)
                    uniqueVendors.push(equipment.vendor)
                }
            })
        }
        return uniqueVendors
    }

    return (
        <div>
            {equipmentVendors().map(vendor=>{
                return <List vendor={vendor} document={document} triggerReload={triggerReload} application={application} />
            })}
        </div>
    )
}

export default VendorBilling