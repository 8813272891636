import React, { useState } from 'react';
import { useQuery } from "react-query";
import Axios from "../../Mics/axiosInstance";
import Select from "./Select";

function useApplicants() {
    return useQuery(["applicants"], async () => {
        const { data } = await Axios.get("/applicants");
        return data;
    });
}

export const List = () => {
    const { data, isLoading } = useApplicants();
    const [search, setSearch] = useState('');

    if (isLoading) {
        return <div>Loading</div>
    }

    let filteredApplicant = data.filter(applicant => String(applicant.company_name).toLowerCase().indexOf(String(search).toLowerCase()) !== -1)
    return (
        <div>
            <div className="row mb-4">
                <div className="col-3">
                    <lable className="form-label">Company Name</lable>
                    <input className="form-control" name="search" defaultValue={search} onChange={(e) => setSearch(e.target.value)} />
                </div>
            </div>
            <table className="table table-hover ">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Company</th>
                        <th>DBA</th>
                        <th>Phone Number</th>
                        <th>Federal ID</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredApplicant.map(company => {
                        return (<Select company={company}></Select>)
                    })}
                </tbody>
            </table>
        </div>
    )

}
export default List