import React, {useState} from 'react';
import {useQuery} from "react-query";
import Axios from "../Mics/axiosInstance";
import {Link, Redirect} from "react-router-dom";
import Money from "../Helpers/Money";
import Date from "../Helpers/Date";

function useApplications() {
    return useQuery(["applications"], async () => {
        const { data } = await Axios.get("/applications");
        return data;
    });
}

export const Search = () => {
    const { data, isLoading } = useApplications();
    const [applicationId, setApplicationId] = useState(null);
    const [search, setSearch] = useState('')
    const [filterType, setFilterType] = useState('id')
    const [redirect, setRedirect] = useState(false);

    const handleChange = (event) => {
        setSearch(event.target.value)
    }
    const tableRowClick = (id) => {
        setApplicationId(id)
        setRedirect(true)
    }
    const goToCreditApplication = () => {
        setRedirect(true)
    }

    if(redirect){
        return (<Redirect to={`/credit-applications/${applicationId}/underwriting`} />)
    }

    if(isLoading){
        return <div>Loading Applications</div>
    }
    var filteredApplications = data.filter((app)=>{
        if(filterType==='id'){
            return String(app[filterType]).indexOf(search)!==-1
        }
        else{
            return String(app.applicant[filterType]).toLocaleLowerCase().indexOf(search.toLocaleLowerCase())!==-1
        }
    })
    console.log(filteredApplications)
    var sortedApplications = filteredApplications.sort((a,b)=>b.id-a.id)

    return (
        <div className="row">
            <div className="col">
                <div className="row">
                    <div className="col">
                        <div className="card mb-4">
                            <div className="card-header">
                                Credit - Application
                            </div>
                            <div className="card-body">
                               
                                <form>
                                    <div className="row mb-3">
                                        <div className="col-4">
                                            <label className="form-label">Search by</label>
                                            <select className="form-control" defaultValue={filterType} onChange={(e)=>setFilterType(e.target.value)} >
                                                <option value="id" selected >Application ID</option>
                                                <option value="company_name">Company Name</option>
                                            </select>
                                        </div>
                                        <div className="col-2">
                                            <label></label>
                                            <input name="search" className="form-control" onChange={handleChange} />
                                        </div>
                                        {/* <div className="col-10">
                                            <button className="btn btn-success" onClick={goToCreditApplication}>
                                                <i className="fas fa-search"/> Search
                                            </button>
                                        </div> */}
                                    </div>
                                </form>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Company</th>
                                            <th>Submitted Amount</th>
                                            <th>Created</th>
                                            <th>BDO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {sortedApplications.map(application=>{
                                        return (
                                            <tr onClick={() => tableRowClick(application.id)} className="table-row-click">
                                                <td>
                                                    {application.id}
                                                </td>
                                                <td>
                                                    {application.applicant.company_name}
                                                </td>
                                                <td>
                                                    <Money amount={application.submitted_amount}/>
                                                </td>
                                                <td>
                                                    <Date date={application.created_at}/>
                                                </td>
                                                <td>
                                                    {application.bdo_user?.name}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Search
