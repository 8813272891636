export const PaymentFrequencySelector = ({ value, register }) => {

        return (
            <select ref={register} id="payment_frequency" className="form-control" name="payment_frequency" defaultValue={value}>
                <option value="Monthly" >Monthly</option>
                <option value="Quarterly" >Quarterly</option>
                <option value="Semi-Annual" >Semi-Annual</option>
                <option value="Annual" >Annual</option>
                <option value="Irregular" >Irregular</option>
                <option value="Bi-Monthly" >Bi-Monthly</option>
                <option value="Bi-Weekly" >Bi-Weekly</option>
            </select>
        )
}
export default PaymentFrequencySelector