import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Item from "./Item";
import API from "../../API";

export const List = () => {
    const { data, isLoading } = API.useSources();
    const [search, setSearch] = useState('')

    if (isLoading) {
        return <div>Loading</div>
    }
    let filteredSources = data?data.filter(source => String(source.company_name).toLowerCase().indexOf(String(search).toLowerCase()) !== -1):[]

    return (
        <div className="row">
            <div className="col">
                <div className="card mb-4">
                    <div className="card-header">
                        <Link to={`/sources/new`} className="btn btn-sm btn-success float-right">
                            <i className="fas fa-plus" /> New Source
                        </Link>
                        Sources
                    </div>
                    <div className="card-body">
                        <div className="row mb-4">
                            <div className="col-3">
                                <lable className="form-label">Source Name</lable>
                                <input className="form-control" name="search" defaultValue={search} onChange={(e) => setSearch(e.target.value)} />
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Company Name</th>
                                    <th>Internal Name</th>
                                    <th>DBA</th>
                                    <th>Federal ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredSources.map(item => {
                                    return (
                                        <Item item={item}/>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default List