import React, { useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import Axios from "../Mics/axiosInstance";
import { NotificationManager } from 'react-notifications';

export const Add = ({ applicantId, modalState, toggleModal, triggerReload }) => {
    const { register, handleSubmit, errors, setValue } = useForm();
    const [isOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
        if (modalState) {
            setIsOpen(true)
        }
    }, [modalState, setIsOpen]);

    const hideModal = () => {
        toggleModal()
        setIsOpen(false);
    };

    const save = (formData) => {
        Axios.post('/applicants/' + applicantId + '/paynet_scores', formData).then(res => {
            hideModal()
            triggerReload()
        }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    };

    return (
        <Modal show={isOpen} onHide={hideModal} size="lg">
            <form onSubmit={handleSubmit(save)}>
                <Modal.Header>
                    <Modal.Title>Add Paynet Score</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row mb-3">
                        <div className="col-5">
                            <label htmlFor="address" className="form-label">Paynet Score <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.paynet ? "is-invalid" : ""}`} id="paynet" name="paynet" />
                        </div>
                        <div className="col">
                            <label htmlFor="city" className="form-label">Paynet Score Date <span className="required">*</span></label>
                            <input type="date" ref={register({ required: true })} className={`form-control ${errors.city ? "is-invalid" : ""}`} id="score_date" name="score_date" />
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={(e) => { e.preventDefault(); hideModal() }}>Cancel</button>
                    <button className="btn btn-primary" type="submit">Save</button>
                </Modal.Footer>
            </form>
        </Modal>
    )

}
export default Add