import React from 'react';
import Axios from "../../Mics/axiosInstance";
import UserSelector from "../../Inputs/UserSelector";
import { useForm } from "react-hook-form";
import { NotificationManager } from 'react-notifications';

export const CreditApproversCard = ({ application, applicationId }) => {
    const { register, handleSubmit } = useForm({ mode: 'onBlur' });

    const save = (data) => {
        Axios.put('/applications/' + applicationId + '/credits/' + application.credit_id, data).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }

    return (
        <form onBlur={handleSubmit(save)}>
            <div className="row mb-4">
                <div className="col-3">
                    Credit Officer
                    <UserSelector type="Credit" value={application.credit.credit_officer_user_id} name="credit_officer_user_id" register={register} />
                </div>
                <div className="col-3">
                    Second Approval
                    <UserSelector type="Approval" value={application.credit.second_approval_user_id} name="second_approval_user_id" register={register} />
                </div>
            </div>
        </form>
    )
}

export default CreditApproversCard
