import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import Axios from "../Mics/axiosInstance";
import StatesSelector from '../Inputs/StatesSelector';
import BusinessTypeSelect from '../Inputs/BusinessTypeSelect';
import FederalTaxIdInput from "../Inputs/FederalTaxIdInput";
import VendorSourceTypeSelector from '../Inputs/VendorSourceTypeSelector';
import { NotificationManager } from 'react-notifications';
import {Redirect} from "react-router-dom";
import PhoneInput from "../Inputs/PhoneInput";

export const Add = () => {
    const { register, handleSubmit, errors } = useForm();
    const [redirect, setRedirect] = useState(false);

    const save = (formData) => {
        Axios.post('/vendors', formData).then(res => {
            NotificationManager.success('Vendor has been added.', 'Success')
            setRedirect(true)
        }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    };

    if(redirect){
        return (<Redirect to={`/vendors`}/>)
    }

    return (
        <div className="row">
            <div className="col-md">
                <div className="card mb-4">
                    <div className="card-header">
                        Add Applicant
                    </div>
                    <div className="card-body">

                        <form onSubmit={handleSubmit(save)}>
                            <div className="row mb-3">
                                <div className="col-4">
                                    <label htmlFor="company_name" className="form-label">Company Name <span className="required">*</span></label>
                                    <input ref={register({ required: true })} className={`form-control ${errors.company_name ? "is-invalid" : ""}`} id="company_name" name="company_name" />
                                </div>
                                <div className="col">
                                    <label htmlFor="dba_name" className="form-label">DBA Name</label>
                                    <input ref={register} className={`form-control`} id="dba_name" name="dba_name" />
                                </div>
                                <div className="col">
                                    <label htmlFor="internal_name" className="form-label">Internal Name</label>
                                    <input ref={register} className={`form-control`} id="internal_name" name="internal_name" />
                                </div>
                                <div className="col">
                                    <label htmlFor="federal_id" className="form-label">Federal ID</label>
                                    <FederalTaxIdInput register={register} name="federal_id" />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col">
                                    <label htmlFor="business-type" className="form-label">Source Type</label>
                                    <VendorSourceTypeSelector register={register} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="business-type" className="form-label">Business Type</label>
                                    <BusinessTypeSelect register={register} value={null} />
                                </div>
                                <div className="col">
                                    <label htmlFor="phone_number" className="form-label">Phone Number</label>
                                    <PhoneInput register={register} name="phone_number" />
                                </div>
                                <div className="col">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input ref={register} className="form-control" id="email" name="email" />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col">
                                    <label htmlFor="address" className="form-label">Address</label>
                                    <input ref={register} className="form-control" id="address" name="address" />
                                </div>
                                <div className="col">
                                    <label htmlFor="city" className="form-label">City</label>
                                    <input ref={register} className="form-control" id="city" name="city" />
                                </div>
                                <div className="col">
                                    <label htmlFor="state" className="form-label">State</label>
                                    <StatesSelector register={register} name="state" />
                                </div>
                                <div className="col">
                                    <label htmlFor="zip" className="form-label">Zip</label>
                                    <input ref={register} className="form-control" id="zip" name="zip" />
                                </div>
                            </div>

                            <hr />

                            <div className="row mb-3">
                                <div className="col">
                                    <lable htmlFor="comment" className="form-label">Comment</lable>
                                    <textarea ref={register} className="form-control" name="comment" />
                                </div>
                            </div>
                            <button className="btn btn-primary float-right" type="submit">Save Vendor</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default Add