import React, { useState, } from 'react';
import { useQuery } from "react-query";
import Axios from "../Mics/axiosInstance";
import Item from "./Item";
import {Link} from "react-router-dom";

function useVendors() {
    return useQuery(["vendors"], async () => {
        const { data } = await Axios.get("/vendors");
        return data;
    });
}

export const List = () => {
    const {data, isLoading} = useVendors();
    const [search, setSearch] = useState('')

    if (isLoading) {
        return <div>Loading Items</div>
    }

    let filteredApplicant = data.filter(applicant => String(applicant.company_name).toLowerCase().indexOf(String(search).toLowerCase()) !== -1)

    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        <Link to={`/vendors/new`} className="btn btn-sm btn-success float-right">
                            <i className="fas fa-plus" /> New Vendor
                        </Link>
                        Vendors
                    </div>
                    <div className="card-body">
                        <div className="row mb-4">
                            <div className="col-3">
                                <lable className="form-label">Vendor Name</lable>
                                <input className="form-control" name="search" defaultValue={search} onChange={(e) => setSearch(e.target.value)} />
                            </div>
                        </div>
                        <table className="table table-hover ">
                            <thead>
                                <tr>
                                    <th>Company Name</th>
                                    <th>DBA</th>
                                    <th>Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!data.length ?
                                    <tr>
                                        <td className="text-center" colSpan={99}>
                                            No vendors have been added
                                    </td>
                                    </tr>
                                    :
                                    ''
                                }
                                {filteredApplicant.map(item => {
                                    return <Item item={item} />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default List