import React, {useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import {useForm} from "react-hook-form";
import Axios from "../../Mics/axiosInstance";
import BusinessTypeSelect from '../../Inputs/BusinessTypeSelect'
import PhoneInput from "../../Inputs/PhoneInput";
import StatesSelector from "../../Inputs/StatesSelector";
import FederalTaxIdInput from "../../Inputs/FederalTaxIdInput";
import {NotificationManager} from 'react-notifications';

export const Edit = ({guarantor, open, toggle}) => {
    const { register, handleSubmit, errors } = useForm();
    const [isOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
        if(open){
            setIsOpen(true)
        }
    }, [open, setIsOpen]);

    const hideModal = () => {
        toggle()
        setIsOpen(false);
    };

    const saveGuarantor = (data) => {
        Axios.put('/corporate_guarantors/'+guarantor.id, data).then(res => {
            return window.location.reload(false);
        }).catch(err=>{
            NotificationManager.error(`${err.key} ${err.message}`, 'Error saving')
        })
    };

    return (
        <Modal show={isOpen} onHide={hideModal} size="lg">
            <form onSubmit={handleSubmit(saveGuarantor)}>
                <Modal.Header>
                    <Modal.Title>Edit Corporate Guarantor</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row mb-4">
                        <div className="col-8">
                            <label className="form-label">Business Name <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.company_name ? "is-invalid" : ""}`} name="company_name"  defaultValue={guarantor.company_name} />
                        </div>
                        <div className="col-4">
                            <label htmlFor="dba_name" className="form-label">DBA Name</label>
                            <input ref={register} className={`form-control`} name="dba_name"  defaultValue={guarantor.dba_name} />
                        </div>
                        <div className="col-4">
                            <label htmlFor="business_type_id" className="form-label">Business Type</label>
                            <BusinessTypeSelect register={register} value={guarantor.business_type_id} />
                        </div>
                    </div>

                    <hr />

                    <div className="row mb-4">
                        <div className="col">
                            <label htmlFor="signer_name" className="form-label">Signer Name</label>
                            <input ref={register} className="form-control" id="signer_name" name="signer_name"  defaultValue={guarantor.signer_name} />
                        </div>
                        <div className="col">
                            <label htmlFor="signer_title" className="form-label">Signer Title</label>
                            <input ref={register} className="form-control" id="signer_title" name="signer_title" defaultValue={guarantor.signer_title} />
                        </div>
                        <div className="col">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input ref={register} className="form-control" id="email" name="email" defaultValue={guarantor.email}/>
                        </div>
                        <div className="col">
                            <label htmlFor="phone_number" className="form-label">Phone Number</label>
                            <PhoneInput register={register} name="phone_number"  value={guarantor.phone_number} />
                        </div>
                    </div>

                    <hr/>

                    <div className="row mb-4">
                        <div className="col-5">
                            <label htmlFor="address" className="form-label">Address <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.address ? "is-invalid" : ""}`} id="address" name="address" defaultValue={guarantor.address} />
                        </div>
                        <div className="col">
                            <label htmlFor="city" className="form-label">City <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.city ? "is-invalid" : ""}`} id="city" name='city' defaultValue={guarantor.city} />
                        </div>
                        <div className="col">
                            <label htmlFor="state" className="form-label">State </label>
                            <StatesSelector register={register({ required: true })} name="state"  value={guarantor.state} />
                        </div>
                        <div className="col">
                            <label htmlFor="zip" className="form-label">Zip <span className="required">*</span></label>
                            <input ref={register({ required: true })} maxLength={5} className={`form-control ${errors.zip ? "is-invalid" : ""}`} id="zip" name='zip' defaultValue={guarantor.zip} />
                        </div>

                    </div>

                    <hr/>

                    <div className="row mb-4">
                        <div className="col">
                            <label className="form-label">Federal ID</label>
                            <FederalTaxIdInput register={register} name='federal_id'  value={guarantor.federal_id} />
                        </div>
                        <div className="col">
                            <label className="form-label">Date Established</label>
                            <input ref={register} type="date" className="form-control" name="date_established"  defaultValue={guarantor.date_established} />
                        </div>
                        <div className="col">
                            <label className="form-label">Jurisdiction</label>
                            <StatesSelector register={register} name="jurisdiction" id="jurisdiction" value={guarantor.jurisdiction} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={(e) => {e.preventDefault(); hideModal()}}>Cancel</button>
                    <button className="btn btn-primary" type="submit">Save Corporate Guarantor</button>
                </Modal.Footer>
            </form>
        </Modal>
    )

}
export default Edit