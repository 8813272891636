import React, {useState} from 'react';
import Date from "../Helpers/Date";

export const Item = ({item}) => {
    return (
        <>
            <tr>
                <td>
                    {item.paynet}
                </td>
                <td>
                    <Date date={item.score_date}/>
                </td>
                <td>
                    <Date date={item.created_at} time/>
                </td>
                <td>
                    {item.created_user.name}
                </td>
            </tr>
        </>
    )

}
export default Item