import React from 'react';
import { useForm } from 'react-hook-form'
import Axios from "../../Mics/axiosInstance";
import CurrencyInput from "react-currency-input-field";
import { NotificationManager } from 'react-notifications';

export const Comment = ({ application, applicationId }) => {
    const { register, handleSubmit } = useForm({ mode: 'onBlur' });

    const save = (formData) => {
        Axios.put('/applications/' + applicationId + '/credits/' + application.credit_id, formData).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }
    return <form onBlur={handleSubmit(save)}>
        <div className="row mb-4">
            <div className="col">
                Comment
                <textarea ref={register} className="form-control" name='comment' defaultValue={application.credit.comment} />
            </div>
        </div>
    </form>

}
export default Comment
