import React from 'react';
import { useQuery } from "react-query";
import {Redirect} from 'react-router-dom'
import Axios from "../Mics/axiosInstance";

function useApplication() {
    return useQuery("generate-application", async () => {
        const { data } = await Axios.post("/applications");
        return data;
    });
}

export const New = () => {
    const { data, isLoading } = useApplication();

    if(isLoading){
        return <div>Generating Application ID...</div>
    }

    if(data){
        return (<Redirect to={`/applications/${data.id}/applicant`}/>)
    }

    return <div>Error generating application id, please try again</div>

}
export default New