import React, {useEffect, useState} from 'react';
import Axios from "../Mics/axiosInstance";
import {Link, useParams} from "react-router-dom";
import moment from "moment";

export const ReportGenerator = () => {
    const {report} = useParams()
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [startDate, setStartDate] = React.useState(moment().format('YYYY-MM-DD'))
    const [endDate, setEndDate] = React.useState(moment().add(1,'days').format('YYYY-MM-DD'))

    useEffect(() => {
        if(isLoading) {
            fetchReport()
        }
    }, [isLoading, report, startDate, endDate]);

    const fetchReport = () => {
        Axios.get(buildUrl()).then(res=>{
            setData(res.data)
            setLoading(false);
        })
    }

    const handleStartChange = (event) => {
        setStartDate(event.target.value)
    }
    const handleEndChange = (event) => {
        setEndDate(event.target.value)
    }

    const buildUrl = () => {
        let url = "/reports/"+report
        if(startDate && endDate){
            url = url+"?start_date="+startDate+"&end_date="+endDate
        }
        return url
    }

    if(isLoading){
        return (
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-header">
                            Loading
                        </div>
                        <div className="card-body text-center">
                            <i className="fas fa-spinner fa-pulse fa-4x mb-3"/>
                            <div>
                                Currently loading report
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="row">
            <div className="col">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <i className="fas fa-chart-bar mr-2 mt-1"/> <Link to={`/reports`}>Reports</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {data.report.title}
                        </li>
                    </ol>
                </nav>

                {data.report.has_filters ?
                    <div className="well-inverted">
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label">Start Date</label>
                                <input type="date" className="form-control" id="start_date" name="start_date" defaultValue={startDate} onChange={handleStartChange} />
                            </div>
                            <div className="col-3">
                                <label className="form-label">End Date</label>
                                <input type="date" className="form-control" id="end_date" name="end_date" defaultValue={endDate}  onChange={handleEndChange} />
                            </div>
                            <div className="col-3">
                                <button className="btn btn-success" onClick={fetchReport} style={{marginTop: '31px'}}>
                                    <i className="fas fa-search"/> Run Report
                                </button>
                            </div>
                        </div>
                    </div>
                    :
                    ''
                }

                <div className="report well-inverted">
                    <table className="table table-sm table-bordered table-hover">
                        <thead>
                            <tr>
                                {data.columns.map(column=>{
                                    return (<th>{column}</th>)
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {!data.rows?.length ?
                                <tr>
                                    <td className="text-center" colSpan={99}>
                                        No data was found for this report
                                    </td>
                                </tr>
                                :
                                ''
                            }
                            {data.rows?.map(row=>{
                                return (
                                    <tr>
                                        {Object.entries(row).map(entry=>{
                                            const [key, value] = entry;
                                            if(key === "App ID"){
                                                return (
                                                    <td>
                                                        <Link to={`/applications/${value}/overview`}>
                                                            {value}
                                                        </Link>
                                                    </td>
                                                )
                                            }else{
                                                return (<td>{value}</td>)
                                            }
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="row">
                        <div className="col">
                            <div className="float-right pr-3 pb-3">
                                Total Rows: {data.total_rows}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportGenerator
