import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import Axios from "../Mics/axiosInstance";
import StatesSelector from '../Inputs/StatesSelector';
import BusinessTypeSelect from '../Inputs/BusinessTypeSelect';
import FederalTaxIdInput from "../Inputs/FederalTaxIdInput";
import BooleanSelector from "../Inputs/BooleanSelector";
import PhoneInput from "../Inputs/PhoneInput";
import Role from "../../Auth/Role";
import { VendorSourceTypeSelector } from '../Inputs/VendorSourceTypeSelector';
import { NotificationManager } from 'react-notifications';
import {Redirect, useParams} from "react-router-dom";
import {useQuery} from "react-query";

function useVendor(id) {
    return useQuery(["vendors", id], async () => {
        const { data } = await Axios.get("/vendors/"+id);
        return data;
    });
}

export const Edit = () => {
    let { id } = useParams();
    const { data, isLoading } = useVendor(id);
    const { register, handleSubmit, errors } = useForm();
    const [redirect, setRedirect] = useState(false);

    const save = (formData) => {
        Axios.put('/vendors/'+id, formData).then(res => {
            NotificationManager.success('Vendor has been updated.', 'Success')
            setRedirect(true)
        }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }

    if(isLoading || data === undefined){
        return <div>Loading Vendor</div>
    }

    if(redirect){
        return (<Redirect to={`/vendors`}/>)
    }

    return (
        <div>
            <div className="row">
                <div className="col-md">
                    <div className="card mb-4">
                        <div className="card-header">
                            Update Vendor
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(save)}>
                                <div className="row mb-3">
                                    <div className="col-4">
                                        <label htmlFor="company_name" className="form-label">Company Name <span className="required">*</span></label>
                                        <input ref={register({ required: true })} className={`form-control ${errors.company_name ? "is-invalid" : ""}`} id="company_name" name="company_name" defaultValue={data.company_name} />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="dba_name" className="form-label">DBA Name</label>
                                        <input ref={register} className={`form-control`} id="dba_name" name="dba_name" defaultValue={data.dba_name} />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="internal_name" className="form-label">Internal Name</label>
                                        <input ref={register} className={`form-control`} id="internal_name" name="internal_name" defaultValue={data.internal_name} />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="federal_id" className="form-label">Federal ID</label>
                                        <FederalTaxIdInput register={register} value={data.federal_id} name="federal_id" />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col">
                                        <label htmlFor="source_reference" className="form-label">Source Type</label>
                                        <VendorSourceTypeSelector register={register} value={data.source_reference} />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="business-type" className="form-label">Business Type</label>
                                        <BusinessTypeSelect register={register} value={data.business_type_id} />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="phone_number" className="form-label">Phone Number</label>
                                        <PhoneInput value={data.phone_number} register={register} name="phone_number" />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input ref={register} className="form-control" id="email" name="email" defaultValue={data.email} />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col">
                                        <label htmlFor="address" className="form-label">Address</label>
                                        <input ref={register} className="form-control" id="address" name="address" defaultValue={data.address} />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="city" className="form-label">City</label>
                                        <input ref={register} className="form-control" id="city" name="city" defaultValue={data.city} />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="state" className="form-label">State</label>
                                        <StatesSelector register={register} value={data.state} name="state" />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="zip" className="form-label">Zip</label>
                                        <input ref={register} className="form-control" id="zip" name="zip" defaultValue={data.zip} />
                                    </div>

                                </div>

                                <hr />

                                <Role roles={['CREDIT']} >

                                    <div className="row mb-3">
                                        <div className="col">
                                            <label htmlFor="approved" className="form-label">Approved?</label>
                                            <BooleanSelector register={register} name="approved" value={data.approved} />
                                        </div>
                                        <div className="col">
                                            <label htmlFor="approved_date" className="form-label">Approved Date</label>
                                            <input ref={register} className="form-control" type="date" id="approved_date" name="approved_date" defaultValue={data.approved_date} />
                                        </div>
                                        <div className="col">
                                            <label htmlFor="ofac" className="form-label">OFAC?</label>
                                            <BooleanSelector register={register} name="ofac" value={data.ofac} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <label htmlFor="approved_for_prefunding" className="form-label">Prefunding Approved?</label>
                                            <BooleanSelector register={register} name="approved_for_prefunding" value={data.approved_for_prefunding} />
                                        </div>
                                        <div className="col">
                                            <label htmlFor="prefunding_terms" className="form-label">Prefunding terms</label>
                                            <input ref={register} className="form-control" id="prefunding_terms" name="prefunding_terms" defaultValue={data.prefunding_terms} />
                                        </div>


                                    </div>

                                    <hr />
                                </Role>

                                <div className="row mb-3">
                                    <div className="col">
                                        <lable htmlFor="comment" className="form-label">Comment</lable>
                                        <textarea ref={register} className="form-control" name="comment" defaultValue={data.comment} />
                                    </div>
                                </div>
                                <button className="btn btn-primary float-right" type="submit">Save Vendor</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default Edit