import React from 'react';
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import Axios from "../Mics/axiosInstance";
import { NotificationManager } from 'react-notifications';

function useApplication(id) {
    return useQuery(["application", id], async () => {
        const { data } = await Axios.get("/applications/" + id);
        return data;
    });
}

export const ApplicationStatus = () => {
    const { applicationId } = useParams()
    const { data, isLoading } = useApplication(applicationId);
    const { register, handleSubmit } = useForm();

    const save = (formData) => {
        Axios.put('/applications/' + applicationId, formData).then(() => {
            NotificationManager.success('Status has been updated.', 'Success')
        })
            .catch((error) => {
                NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
            })
    }

    if (isLoading) {
        return <div>Loading Credit Application</div>
    }

    if (!isLoading && Object.keys(data).length === 0) {
        return <div className="alert alert-danger">Application does not exist</div>
    }

    return (
        <div className="row">
            <div className="col-md">
                <div className="card mb-4">
                    <div className="card-header">
                        Application Status Update
                    </div>
                    <div className="card-body">

                        <form onSubmit={handleSubmit(save)}>
                            <div className="row mb-4">
                                <div className="col">
                                    Status:
                                    <select ref={register} className="form-control" name="status" defaultValue={data.status} >
                                        <option value="" selected disabled />
                                        <option value="Active">Active</option>
                                        <option value="Field Decline">Field Decline</option>
                                        <option value="Lost Sale">Lost Sale</option>
                                        <option value="Dead">Dead</option>
                                        <option value="Withdrawn">Withdrawn</option>
                                    </select>
                                </div>
                                <div className="col">
                                    Date
                                    <input ref={register} type="date" className="form-control" name="death_date" defaultValue={data.death_date} />
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col">
                                    Comment
                                    <textarea ref={register} name='death_comment' className="form-control" defaultValue={data.death_comment} />
                                </div>
                            </div>

                            <hr />
                            <button className="btn btn-primary" type="submit">Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplicationStatus
