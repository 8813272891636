import React, {useEffect, useState} from 'react';
import Add from "../Details/Add";
import View from "../Details/View";
import {Link} from "react-router-dom";

const Details = ({application, applicationId}) => {
    const [view, setView] = useState('add-details');

    useEffect(() => {
        if(application.equipment_cost){
            setView('view-details')
        }
    }, [setView, application]);

    const switchToEditView = () => {
        setView('add-details')
    }


    const Footer = () => {
        return (
            <div className="row">
                <div className="col">
                    <hr/>
                    <Link className="btn btn-outline-secondary float-right" to={`/applications/${applicationId}/personal-guarantors`}>
                        Go to Personal Guarantors <i className="fas fa-chevron-right"/>
                    </Link>
                    <Link className="btn btn-outline-secondary" to={`/applications/${applicationId}/source`}>
                        <i className="fas fa-chevron-left"/> Go to Source
                    </Link>
                </div>
            </div>
        )
    }


    if(view === 'add-details'){
        return (
            <div>
                <Add application={application} applicationId={applicationId}/>
                <Footer/>
            </div>
        );
    }

    if(view === 'view-details'){
        return (
            <div>
                <div className="row mb-3">
                    <div className="col">
                        <button className='btn btn-success float-right' onClick={switchToEditView}><i className="fas fa-pencil-alt"/> Edit Details</button>

                    </div>
                </div>
                <hr/>
                <View application={application} applicationId={applicationId}/>
                <Footer/>
            </div>
        );
    }

    return (
        <div>
            No View found in the Details.js file
        </div>
    );

}

export default Details