import React  from 'react';
import Edit from "./Edit";
import DefaultBillingList from "../DefaultBillings/List";
import SourceContact from "../SourceContact/Edit";
import {useParams} from "react-router-dom";

export const Show = () => {
    let { id } = useParams()

    return (
        <>
            <Edit/>
            <hr/>
            <SourceContact sourceId={id} />
            <hr/>
            <DefaultBillingList typeId={id} type='source' />
        </>
    )
}
export default Show