import React from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import Axios from "../../Mics/axiosInstance";

export const Delete = ({corporate_guarantor, applicationId}) => {

    const confirmRemoveItem = () => {

        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to remove this item?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: removeItem
                },
                {
                    label: 'No'
                }
            ]
        });

    }

    const removeItem = () => {
        Axios.delete('/applications/'+applicationId+'/detach_corporate_guarantor/'+corporate_guarantor.id).then((res) => {
            return window.location.reload(false);
        })
    }

    return (
        <i className="fas fa-trash pointer" title="Remove this item" onClick={confirmRemoveItem}/>
    )

}
export default Delete