import React from 'react';
import { useForm } from "react-hook-form";
import Axios from "../Mics/axiosInstance";
import CurrencyInput from '../Inputs/CurrencyInput'
import Money from "../Helpers/Money";
import { NotificationManager } from 'react-notifications';


export const Disbursement = ({ document, application, triggerReload }) => {
    const { register, handleSubmit, setValue } = useForm({ mode: 'onBlur' });

    const save = (data) => {
        if (document.disbursement?.id) {
            document.commission_due = commissionDue()
            Axios.put('/documents/' + document.contract_number + '/disbursements/' + document.disbursement.id, data).then((res) => {
                document.disbursement = res.data
                triggerReload()
            }).catch((error) => {
                NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
            })
        } else {
            document.commission_due = commissionDue()
            Axios.post('/documents/' + document.contract_number + '/disbursements', data).then((res) => {
                document.disbursement = res.data
            }).catch((error) => {
                NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
            })
        }
    }
    const commissionDue = () =>{
        if(document.disbursement?.shortfall){
            return 0.00
        }
        else if (application.source?.is_dfny){

        }
        else if(application.source?.is_discounter){
            
            let commission_due = Number(document.loan_amount)
            commission_due = commission_due - Number(document.disbursement?.discounted_equipment_cost)
            commission_due = commission_due - Number(document.disbursement?.doc_fee_deduct)
            commission_due = commission_due - Number(document.disbursement?.commission_held)
            commission_due = commission_due + Number(document.disbursement?.shortfall)
            return commission_due
        }
        else{
            let commission_due = Number(document.disbursement?.broker_commission)
            commission_due = commission_due + Number(document.disbursement?.doc_fee_add)
            commission_due = commission_due - Number(document.disbursement?.doc_fee_deduct)
            commission_due = commission_due - Number(document.disbursement?.commission_held)
            commission_due = commission_due - Number(document.disbursement?.shortfall)
            return commission_due
        }
    }
    const totalCommission = () => {
        if (document.loan_amount) {
            if (document.disbursement?.discounted_equipment_cost) {
                return Number(document.loan_amount) - Number(document.disbursement.discounted_equipment_cost)

            }
            return "No Discounted Equipment Cost"
        }
        return "No Loan Amount"
    }
    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        Commission
                    </div>
                    <div className="card-body">
                        <form onBlur={handleSubmit(save)}>
                            {application.source.is_dfny ?
                                <div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            Reps Commission
                                            <CurrencyInput name="broker_commission" setValue={setValue} register={register} value={document.disbursement?.broker_commission} />
                                        </div>
                                        <div className="col">
                                            Doc Fee Add
                                            <CurrencyInput name="doc_fee_add" setValue={setValue} register={register} value={document.disbursement?.doc_fee_add} />
                                        </div>
                                        <div className="col">
                                            Doc Fee Deduct
                                            <CurrencyInput name="doc_fee_deduct" setValue={setValue} register={register} value={document.disbursement?.doc_fee_deduct} />
                                        </div>
                                        <div className="col">
                                            Doc Fee
                                            <CurrencyInput name="doc_fee" setValue={setValue} register={register} value={document.disbursement?.doc_fee} />
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="row mb-3">
                                        <div className="col">
                                            Commission Due
                                            <CurrencyInput name="commission_due" setValue={setValue} register={register} value={document.disbursement?.commission_due} />
                                        </div>
                                        <div className="col">
                                            Release Date
                                            <input type='date' className="form-control" name='commission_due_release_date' defaultValue={document.disbursement?.commission_due_release_date} ref={register} />
                                        </div>
                                        <div className="col">
                                            Dedicated Fee
                                            <CurrencyInput name="dedicated_fee" setValue={setValue} register={register} value={document.disbursement?.dedicated_fee} />
                                        </div>
                                        <div className="col">
                                            Title Fee
                                            <CurrencyInput name="title_fee" setValue={setValue} register={register} value={document.disbursement?.title_fee} />
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="row mb-3">
                                        <div className="col-3">
                                            GPS Fee
                                            <CurrencyInput name="gps_fee" setValue={setValue} register={register} value={document.disbursement?.gps_fee} />
                                        </div>
                                        <div className="col-3">
                                            Inspection Fee
                                            <CurrencyInput name="inspection_fee" setValue={setValue} register={register} value={document.disbursement?.inspection_fee} />
                                        </div>
                                        <div className="col-3">
                                            Doc Fee Balance
                                            <CurrencyInput name="doc_fee_balance" setValue={setValue} register={register} value={document.disbursement?.doc_fee_balance} />
                                        </div>
                                    </div>

                                </div>
                                : application.source.is_discounter ?
                                    <div>
                                        <div className="row mb-3">
                                            <div className="col-3">
                                                Loan Amount
                                                <br/>
                                    <Money name="loan_amount" amount={document.loan_amount} />
                                            </div>
                                            <div className="col-3">
                                                Discounted Equipment Cost
                                    <CurrencyInput name="discounted_equipment_cost" setValue={setValue} register={register} value={document.disbursement?.discounted_equipment_cost} />
                                            </div>
                                            <div className="col-3">
                                                Total Commission
                                                <br />
                                                {typeof totalCommission() === "string" ?
                                                    totalCommission()
                                                    :
                                                    <Money amount={totalCommission()} />
                                                }
                                            </div>

                                        </div>

                                        <hr />
                                        <div className="row mb-3">
                                            <div className="col">
                                                Doc Fee Deducted
                                    <CurrencyInput name="doc_fee_deduct" setValue={setValue} register={register} value={document.disbursement?.doc_fee_deduct} />
                                            </div>
                                            <div className="col">
                                                Commission Due
                                                <br/>
                                                <Money amount={commissionDue()} />
                                            </div>
                                            <div className="col">
                                                Commission Due Release Date
                                                <input type='date' className="form-control" name='commission_due_release_date' defaultValue={document.disbursement?.commission_due_release_date} ref={register} />
                                            </div>



                                        </div>

                                        <hr />
                                        <div className="row mb-3">
                                            <div className="col">
                                                Commission Held
                                    <CurrencyInput name="commission_held" setValue={setValue} register={register} value={document.disbursement?.commission_held} />
                                            </div>
                                            <div className="col">
                                                Commission Held Release Date
                                    <input type='date' className="form-control" name='commission_held_release_date' defaultValue={document.disbursement?.commission_held_release_date} ref={register} />
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row mb-3">
                                            <div className="col-3">
                                                Shortfall
                                    <CurrencyInput name="shortfall" setValue={setValue} register={register} value={document.disbursement?.shortfall} />
                                            </div>
                                            <div className="col-6">
                                                Shortfall Comment
                                    <textarea className="form-control" name="shortfall_comment" rows="3" defaultValue={document.disbursement?.shortfall_comment} ref={register} />
                                            </div>
                                        </div>
                                    </div>
                                    : //else
                                    <div>
                                        <div className="row mb-3">
                                            <div className="col">
                                                Commission
                                            <CurrencyInput name="broker_commission" setValue={setValue} register={register} value={document.disbursement?.broker_commission} />
                                            </div>
                                            <div className="col">
                                                Doc Fee Add
                                            <CurrencyInput name="doc_fee_add" setValue={setValue} register={register} value={document.disbursement?.doc_fee_add} />
                                            </div>
                                            <div className="col">
                                                Doc Fee Deduct
                                            <CurrencyInput name="doc_fee_deduct" setValue={setValue} register={register} value={document.disbursement?.doc_fee_deduct} />
                                            </div>
                                        </div>

                                        <hr />
                                        <div className="row mb-3">
                                            <div className="col">
                                                Commission Held
                                            <CurrencyInput name="commission_held" setValue={setValue} register={register} value={document.disbursement?.commission_held} />
                                            </div>
                                            <div className="col">
                                                Commission Held Release Date
                                            <input type='date' className="form-control" name='commission_held_release_date' defaultValue={document.disbursement?.commission_held_release_date} ref={register} />
                                            </div>
                                            <div className="col">
                                                Commission Due
                                                <br/>
                                                <Money amount={commissionDue()} />
                                            </div>
                                            <div className="col">
                                                Commission Due Release Date
                                            <input type='date' className="form-control" name='commission_due_release_date' defaultValue={document.disbursement?.commission_due_release_date} ref={register} />
                                            </div>
                                        </div>

                                        <hr />

                                        <div className="row mb-3">
                                            <div className="col-3">
                                                Shortfall
                                                <CurrencyInput name="shortfall" setValue={setValue} register={register} value={document.disbursement?.shortfall} />
                                            </div>
                                            <div className="col-9">
                                                Shortfall Comment
                                                <textarea className="form-control" name="shortfall_comment" rows="3" defaultValue={document.disbursement?.shortfall_comment} ref={register} />
                                            </div>
                                        </div>
                                    </div>

                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Disbursement
