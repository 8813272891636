import React, { useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import Axios from "../../../Components/Mics/axiosInstance";
import { useQuery } from "react-query";
import { NotificationManager } from 'react-notifications';
import BooleanSelector from "../../Inputs/BooleanSelector";
import Date from "../../Helpers/Date";

function useRoles() {
    return useQuery(["roles"], async () => {
        const { data } = await Axios.get("/roles");
        return data;
    });
}

export const Edit = ({ modalState, toggleModal, triggerReload, item }) => {
    const { data, isLoading } = useRoles();
    const { register, handleSubmit, errors } = useForm();
    const [isOpen, setIsOpen] = React.useState(false);
    const [active, setActive] = React.useState(item?.active)
    useEffect(() => {
        if (modalState) {
            setIsOpen(true)
        }
    }, [modalState, setIsOpen]);
    const handleActiveChange = () =>{
        setActive(!active)
    }
    const hideModal = () => {
        toggleModal()
        setIsOpen(false);
    };

    const save = (formData) => {
        Axios.put('/users/' + item.id, formData).then(res => {
            hideModal()
            triggerReload()
        }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    };

    if (isLoading) {
        return <div>Loading item</div>
    }

    return (
        <Modal show={isOpen} onHide={hideModal} size="lg">
            <form onSubmit={handleSubmit(save)}>
                <Modal.Header>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {item.is_archived ?
                        <div className="alert alert-danger">
                            This user is archived
                        </div>
                        :
                        <></>
                    }

                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="username" className="form-label">Username <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.username ? "is-invalid" : ""}`} id="username" name="username" defaultValue={item.username} />
                        </div>
                        <div className="col">
                            <label htmlFor="name" className="form-label">Name <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.name ? "is-invalid" : ""}`} id="name" name="name" defaultValue={item.name} />
                        </div>
                        <div className="col">
                            <label htmlFor="email" className="form-label">Email <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.email ? "is-invalid" : ""}`} id="email" name="email" defaultValue={item.email} />
                        </div>
                        <div className="col">
                            <label htmlFor="role_ids" className="form-label">Roles <span className="required">*</span></label>
                            {isLoading ?
                                <div>Loading Roles</div>
                                :
                                <select ref={register({ required: true })} className={`form-control ${errors.role_ids ? "is-invalid" : ""}`} id="role_ids" name="role_ids" defaultValue={item.role_ids} multiple>
                                    {data.map(role => {
                                        return (
                                            <option value={role.id}>
                                                {role.name}
                                            </option>
                                        )
                                    })}
                                </select>
                            }
                        </div>
                    </div>

                    <hr />

                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="password" className="form-label">Change Password (Leave blank if not changing password)</label>
                            <input ref={register} className={`col-4 form-control ${errors.password ? "is-invalid" : ""}`} id="password" name="password" />
                        </div>
                        
                    </div>

                    <hr />

                    <div className="row ">
                        <div className="col-md-3">
                            <label htmlFor="password" className="form-label">Archive this user?</label>
                            <BooleanSelector register={register} name="archived" value={item.is_archived === true ? 'true' : 'false'} />
                        </div>
                        {item.is_archived ?
                            <div className="col-md-6">
                                <label htmlFor="password" className="form-label">Archived by</label>
                                <div>
                                    {item.archived_user.name} on <Date date={item.archived_at} time/>
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={(event) => { event.preventDefault(); hideModal() }}>Cancel</button>
                    <button className="btn btn-primary" type="submit">Save</button>
                </Modal.Footer>
            </form>
        </Modal>
    )

}
export default Edit