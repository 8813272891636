import React from "react";
import Axios from "../../Mics/axiosInstance";

export const SelectRow = ({applicationId, vendor}) => {

    const saveToApplication = () => {
        Axios.post('/applications/'+applicationId+'/attach_vendor/'+vendor.id).then(() => {
            return window.location.reload(false);
        })
    }

    return (
        <tr className="applicant-select" onClick={saveToApplication}>
            <td>{vendor.company_name}</td>
            <td>{vendor.address}</td>
            <td>{vendor.dba_name}</td>
        </tr>
    )

}
export default SelectRow
