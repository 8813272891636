import React from 'react';
import {Link, useParams} from "react-router-dom";
import {useQuery, useQueryClient} from "react-query";
import Axios from "../Mics/axiosInstance";
import Equipment from '../Equipment/List'
import Documentation from './Documentation'
import VendorList from "./VendorList";
import Role from "../../Auth/Role";
import { Credit } from './Credit';

function useDocument(contractNumber) {
    return useQuery(["document", contractNumber], async () => {
        const {data} = await Axios.get("/documents/" + contractNumber);
        return data;
    });
}

export const DocRequestRouter = () => {
    const { contractNumber } = useParams()
    const { data, isLoading } = useDocument(contractNumber);
    const queryClient = useQueryClient();

    const triggerDocReload = () => {
        queryClient.refetchQueries(["document", contractNumber])
    }

    if(isLoading){
        return <div>Loading Document</div>
    }

    if(!isLoading && Object.keys(data).length === 0){
        return <div className="alert alert-danger">Contract Number does not exist</div>
    }

    // if (data && data.credit.decision !== 'Approved') {
    //     return (
    //         <div className="row">
    //             <div className="col">
    //                 <div className="card">
    //                     <div className="card-header">
    //                         Documentation Request
    //                     </div>
    //                     <div className="card-body">
    //                         <div className="alert alert-danger">
    //                             <strong>Credit Not Approved</strong>
    //                             <p>This deal has not been approved through Credit</p>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }
    //
    // if (data && !data.vendors.length) {
    //     return (
    //         <div className="row">
    //             <div className="col">
    //                 <div className="card">
    //                     <div className="card-header">
    //                         Documentation Request
    //                     </div>
    //                     <div className="card-body">
    //                         <div className="alert alert-danger">
    //                             <strong>NO VENDORS</strong>
    //                             <p>Please add a vendor before proceeding.</p>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        Documentation Request
                    </div>
                    <div className="card-body">

                        <Equipment document={data} triggerDocReload={triggerDocReload} />
                        <hr/>
                        <Credit document={data} />
                        <hr/>
                        <Documentation document={data} />
                        <hr/>
                        <VendorList document={data}/>
                        <hr/>

                        <div className="row">
                            <div className="col">
                                <Role roles={['DOCUMENT']} >
                                    <Link className="btn btn-primary float-right" to={`/doc-input/${data.contract_number}/${data.application_id}`}>
                                        Go to Documentation Input <i className="fas fa-chevron-right"/>
                                    </Link>
                                </Role>
                                <Link className="btn btn-primary" to={`/doc-request/search`}>
                                    <i className="fas fa-chevron-left"/> Go back to search
                                </Link>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocRequestRouter
