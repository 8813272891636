import React, {useState} from 'react';
import {Redirect} from "react-router-dom";
import List from './List'
export const Search = () => {
    const [type, setType] = useState('application')
    const [typeId, setTypeId] = useState(null)
    const [go, setGo] = useState(false)

    const handleTypeChange = (event) => {
        setType(event.target.value)
    }

    const handleChange = (event) => {
        setTypeId(event.target.value)
    }

    const goTo = () => {
        setGo(true)
    }

    if(go === true && type !== null && typeId !== null){
        if(type === 'application'){
            return (<Redirect to={`/doc-request/application/${typeId}`}/>)
        }
        if(type === 'contract'){
            return (<Redirect to={`/doc-request/document/${typeId}`}/>)
        }
    }

    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        Doc Request - Search
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row">
                                <div className="col-md-6">
                                    Please Enter your
                                    <select className="form-control" name="type" onChange={handleTypeChange}>
                                        <option value="application">Application ID</option>
                                        <option value="contract">Contract Number</option>
                                    </select>
                                    <br />
                                    <input className="form-control" type="number" name="type_id" onChange={handleChange}/>
                                    <hr />
                                    <button className='btn btn-primary' onClick={goTo}>Search</button>
                                </div>
                            </div>
                        </form>
                        <List />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Search
