import React, {useState,} from 'react';
import {useQuery, useQueryClient} from "react-query";
import Axios from "../Mics/axiosInstance";
import Add from "./Add";
import Item from "./Item";

function usePaynetScores(id) {
    return useQuery(["applicants", id, "paynet_scores"], async () => {
        const { data } = await Axios.get("/applicants/"+id+"/paynet_scores");
        return data;
    });
}

export const List = ({applicantId, triggerDocReload}) => {
    const { data, isLoading } = usePaynetScores(applicantId);
    const [modalState, setModalState] = useState(false)
    const queryClient = useQueryClient();

    const triggerReload = () => {
        queryClient.refetchQueries(["applicants", applicantId, "paynet_scores"])
        if(triggerDocReload !== undefined){
            triggerDocReload()
        }
    }

    const toggleModal = () => {
        setModalState(!modalState)
    }

    if(isLoading){
        return <div>Loading Paynet Scores</div>
    }

    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary float-right" onClick={toggleModal}>
                            <i className="fas fa-plus"/> Add Paynet Score
                        </button>
                        Historical Paynet Scores
                    </div>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Paynet Score</th>
                            <th>Paynet Date</th>
                            <th>Created At</th>
                            <th>Created By</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!data.length ?
                            <tr>
                                <td className="text-center" colSpan={99}>
                                    No paynet scores has been added to this applicant
                                </td>
                            </tr>
                            :
                            <>
                                {data.map(item=>{
                                    return <Item item={item} applicantId={applicantId} triggerReload={triggerReload}/>
                                })}
                            </>
                        }

                        </tbody>
                    </table>
                    <Add modalState={modalState} toggleModal={toggleModal} applicantId={applicantId} triggerReload={triggerReload}/>
                </div>
            </div>
        </div>
    )
}

export default List