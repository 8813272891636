import React, { useState } from 'react';
import {Redirect, useParams} from "react-router-dom";
import FederalID from "../Helpers/FederalID";
import Phone from "../Helpers/Phone";

export const Select = ({company}) => {
    const [redirect, setRedirect] = useState(false)

    if(redirect){
        return <Redirect to={`/applicants/${company.id}`}/>
    }
    return (
        <tr className="applicant-select" onClick={()=>setRedirect(true)}>
            <td>
                {company.id}
            </td>
            <td>
                {company.company_name}
            </td>
            <td>
                {company.dba_name}
            </td>
            <td>
                <Phone number={company.phone_number}/>
            </td>
            <td>
                <FederalID id={company.federal_id}/>
            </td>
        </tr>
    )
}
export default Select