import React from 'react';

export const CurrencyInput = ({register, setValue, name, id, className, value, style}) => {
    const formatConfig = {
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    };

    const formatter = new Intl.NumberFormat('en-US', formatConfig);

    if(register === undefined || register === ''){
        console.log("register param must be set when using <CurrencyInput register={register}..., You must pass this in from useForm")
    }
    if(setValue === undefined || setValue === ''){
        console.log("setValue param must be set when using <CurrencyInput ..., You must pass this in from useForm")
    }

    if(name === undefined || name === ''){
        console.log("name param must be set when using <CurrencyInput name=''...")
    }

    if(className === undefined || className === ''){
        className = 'form-control'
    }

    if(id === undefined || id === ''){
        id = name
    }

    if(value){
        value = formatter.format(value)
    }


    const formatMoney = (event) => {
        let value = (event.target.value).replace(/[^0-9.]/g, '')
        if(value !== ''){
            setValue(name, formatter.format(value))
        }else{
            setValue(name, '')
        }
    }

    return (
        <div className="input-group" style={style}>
            <div className="input-group-prepend">
                <span className="input-group-text">$</span>
            </div>
            <input ref={register} name={name} id={id} className={className} type="text" onBlur={formatMoney} defaultValue={value} />
        </div>
    )
}

export default CurrencyInput