import React, { useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import Axios from "../../Mics/axiosInstance";
import CurrencyInput from '../../Inputs/CurrencyInput';
import { NotificationManager } from 'react-notifications';

export const Add = ({ applicationId, open, toggle }) => {
    const { register, handleSubmit, errors, setValue } = useForm();
    const [isOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
        if (open) {
            setIsOpen(true)
        }
    }, [open, setIsOpen]);

    const hideModal = () => {
        toggle()
        setIsOpen(false);
    };

    const saveBankInfo = (data) => {
        Axios.post('/applications/' + applicationId + '/bank_statements', data).then(res => {
            return window.location.reload(false);
        })
            .catch((err) => {
                NotificationManager.error(`${err.key} ${err.message}`, 'Error saving')
            })
    };

    return (
        <Modal show={isOpen} onHide={hideModal} size="lg">
            <form onSubmit={handleSubmit(saveBankInfo)}>
                <Modal.Header>
                    <Modal.Title>Add Banking Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row mb-4">
                        <div className="col">
                            <label className="form-label">Bank Name <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.bank_name ? "is-invalid" : ""}`} name="bank_name" />
                        </div>
                        <div className="col">
                            <label htmlFor="title" className="form-label">Period<span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.statement_date ? "is-invalid" : ""}`} name="statement_date" type="date" />
                        </div>
                    </div>

                    <hr />

                    <div className="row mb-4">
                        <div className="col">
                            <label className="form-label">Deposits</label>
                            <CurrencyInput name="deposits" setValue={setValue} register={register} />
                        </div>
                        <div className="col">
                            <label className="form-label">Withdrawals</label>
                            <CurrencyInput name="withdrawals" setValue={setValue} register={register} />
                        </div>
                        <div className="col">
                            <label className="form-label">Ending Balance <span className="required">*</span></label>
                            <CurrencyInput name="balance" setValue={setValue} register={register} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={(e) => { e.preventDefault(); hideModal() }}>Cancel</button>
                    <button className="btn btn-primary" type="submit">Save Bank Info</button>
                </Modal.Footer>
            </form>
        </Modal>
    )

}
export default Add