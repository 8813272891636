import React from 'react';

const Unauthorized = () => {
    return (
        <div className="login-page">
            <div className="login-form" style={{padding: '0'}}>
                <div className="alert alert-danger text-center">
                    You are not authorized to view this page, please contact your administrator for elevated permissions
                </div>
            </div>
        </div>
    )
}
export default Unauthorized