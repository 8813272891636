import React, { useState } from 'react'
import { useQuery } from 'react-query'
import axios from '../../../Mics/axiosInstance'

function useResalers() {
    return useQuery(["application-resaler"], async () => {
        const { data } = await axios.get("/sources")
        return data
    })
}

export const Resaler = ({resaler_id, register, setValue}) => {
    const { data, isLoading } = useResalers()

    const updateResaler = (event) =>{
        console.log(event.target.value)
        setValue(event.target.value)
    }
    if(register === undefined || register === ''){
        console.log("register param must be set when using <PhoneInput register={register}..., You must pass this in from useForm")
    }
    
    if (isLoading) {
        return <div>Loading information</div>
    }
    return (
        <React.Fragment>
            <select ref={register} className="form-control" name="resaler_id" id="resaler_id" defaultValue={resaler_id} onBlur={(event)=>updateResaler(event)} >
                <option value={null} selected></option>
                {data.map(source=>{
                    if(source.source_type?.name==='Resaler'){
                        return <option value={source.id}>{source.company_name}</option>
                    }
                })}
            </select>

        </React.Fragment>
    )
}

export default Resaler