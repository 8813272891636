import React, {useState,} from 'react';
import {useQuery, useQueryClient} from "react-query";
import Axios from "../Mics/axiosInstance";
import Add from "./Add";
import Item from "./Item";

function useEquipments(id) {
    return useQuery(["documents", id, "equipments"], async () => {
        const { data } = await Axios.get("/documents/"+id+"/equipments");
        return data;
    });
}

export const List = ({document, add, triggerDocReload}) => {
    const { data, isLoading } = useEquipments(document.contract_number);
    const [modalState, setModalState] = useState(false)
    const queryClient = useQueryClient();

    const triggerReload = () => {
        queryClient.refetchQueries(["documents", document.contract_number, "equipments"])
        if(triggerDocReload !== undefined){
            triggerDocReload()
        }
    }

    const toggleModal = () => {
        setModalState(!modalState)
    }

    if(isLoading){
        return <div>Loading Equipment</div>
    }

    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        {add !== undefined ?
                            ''
                            :
                            <button className="btn btn-primary float-right" onClick={toggleModal}>
                                <i className="fas fa-plus"/> Add Equipment
                            </button>
                        }
                        Equipment
                    </div>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Equipment</th>
                            <th>Vendor</th>
                            <th>Cost</th>
                            <th>Serial Number</th>
                            <th>VIN Number</th>
                            <th>Address</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Zip</th>
                            <th>Manage</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!data.length ?
                            <tr>
                                <td className="text-center" colSpan={99}>
                                    No equipment has been added to this application
                                </td>
                            </tr>
                            :
                            ''
                        }

                        {data.map(equipment=>{
                            return <Item equipment={equipment} document={document} triggerReload={triggerReload}/>
                        })}
                        </tbody>
                    </table>
                    <Add modalState={modalState} toggleModal={toggleModal} document={document} triggerReload={triggerReload}/>
                </div>
            </div>
        </div>
    )
}

export default List