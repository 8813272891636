import React from 'react';
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import BusinessTypeSelect from '../Inputs/BusinessTypeSelect'
import SourceTypeSelector from '../Inputs/SourceTypeSelector'
import FederalTaxIdInput from "../Inputs/FederalTaxIdInput";
import API from "../../API";


export const Edit = () => {
    let { id } = useParams()
    const { data, isLoading } = API.useSource(id)
    const { register, handleSubmit } = useForm()

    const save = (formData) => {
        API.updateSource(id, formData).then(() => {
            NotificationManager.success('Source has been updated', 'Success')
        }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }

    if (isLoading && data === undefined) {
        return <h1>Loading Source</h1>
    }

    return (
        <div className="card mb-4">
            <div className="card-header">
                Source
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit(save)}>
                    <div className="row mb-3">
                        <div className="col-4">
                            <label className="form-label">Company Name</label>
                            <input ref={register} name="company_name" type='text' className="form-control" defaultValue={data.company_name} />
                        </div>
                        <div className="col-4">
                            <label className="form-label">Internal Name</label>
                            <input ref={register} name="internal_name" type='text' className="form-control" defaultValue={data.internal_name} />

                        </div>
                        <div className="col-4">
                            <label className="form-label">DBA Name</label>
                            <input ref={register} name="dba_name" type='text' className="form-control" defaultValue={data.dba_name} />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4">
                            <label className="form-label">Source Type</label>
                            <SourceTypeSelector register={register} value={data.source_type_id} />
                        </div>
                        <div className="col-4">
                            <label className="form-label">CFL</label>
                            <select ref={register} name="cfl" className="form-control" defaultValue={data.cfl}>
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                            </select>
                        </div>
                        <div className="col-4">
                            <label className="form-label">CFL Number</label>
                            <input ref={register} className="form-control" name="cfl_number" defaultValue={data.cfl_number} />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4">
                            <label className="form-label">Federal ID</label>
                            <FederalTaxIdInput register={register} name="federal_id" value={data.federal_id} />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="business-type" className="form-label">Business Type</label>
                            <BusinessTypeSelect register={register} value={data.business_type_id} />
                        </div>
                        <div className="col-4">
                            <label className="form-label">Application Received</label>
                            <input ref={register} className="form-control" name="application_received" type="date" defaultValue={data.application_received} />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4">
                            <label className="form-label">Agreement Date</label>
                            <input ref={register} className="form-control" name="agreement_date" type="date" defaultValue={data.agreement_date} />

                        </div>
                        <div className="col-4">
                            <label className="form-label">Peak Reference</label>
                            <input ref={register} className="form-control" name="peak_reference" type="text" defaultValue={data.peak_reference} />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-4">
                            <label>Approved</label>
                            <select ref={register} name="approved" className="form-control" defaultValue={data.approved} >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                            </select>
                        </div>
                        <div className="col-4">
                            <label>Approval Date</label>
                            <input ref={register} className="form-control" name="approval_date" type='date' defaultValue={data.approval_date} />
                        </div>
                    </div>

                    <hr />

                    <button className="btn btn-primary float-right" type="submit">Submit</button>
                </form>
            </div>
        </div>
    )
}

export default Edit