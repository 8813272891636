import React from 'react';
import { useForm } from "react-hook-form";
import Axios from "../../Mics/axiosInstance";
import CurrencyInput from "../../Inputs/CurrencyInput";
import { NotificationManager } from 'react-notifications';

export const Credit = ({ application }) => {
    const { register, handleSubmit, setValue } = useForm({ mode: 'onBlur' });

    const save = (data) => {
        Axios.put('/applications/' + application.id + '/credits/' + application.credit_id, data).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }
    return (
        <div>
            <form >
                <div className="row mb-4">
                    <div className="col-3">
                        Credit approved amount:
                        <CurrencyInput name="approved_amount" value={application.credit.approved_amount} />
                    </div>
                    <div className="col-3">
                        Credit approved terms
                        <input className="form-control" type="number" name="terms" defaultValue={application.credit.terms} />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Credit