import React, { useEffect, useState } from 'react';
import Axios from "../../Mics/axiosInstance";
import Select from "./Select";

export const List = () => {
    const [loading, setLoading] = useState(true);
    const [sources, setSources] = useState([]);
    const [search, setSearch] = useState('')

    useEffect(() => {
        if (loading) {
            Axios.get("/sources").then(res => {
                setSources(res.data)
                setLoading(false);
            })
        }
    }, [loading, setLoading, sources, setSources]);

    if (loading) {
        return <div>Loading</div>
    }
    let filteredSources = sources.filter(source => String(source.company_name).toLocaleLowerCase().indexOf(String(search).toLocaleLowerCase())!==-1)
    return (
        <div>
            <div className="row mb-4">
                <div className="col">
                    <lable className="form-label">Company Name</lable>
                    <input className="form-control" name="search" defaultValue={search} onChange={(e) => setSearch(e.target.value)} />
                </div>
            </div>
            
            <table className="table table-hover ">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>DBA</th>
                        <th>Federal ID</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredSources.map(source => {
                        return (<Select source={source}></Select>)
                    })}
                </tbody>
            </table>
        </div>
    )

}
export default List