import React from 'react';
import { useQuery } from "react-query";
import Axios from "../../Mics/axiosInstance";
import FederalID from "../../Helpers/FederalID";
import Phone from "../../Helpers/Phone";
import Date from "../../Helpers/Date";

function useApplicant(id) {
    return useQuery(["applicant", id], async () => {
        const { data } = await Axios.get("/applicants/"+id);
        return data
    });
}


export const View = ({applicantID}) => {
    const { data, isLoading } = useApplicant(applicantID);

    if(isLoading || data === undefined){
        return (
            <div>
                <div className='row mt-4'>
                    <div className="col text-center">
                        Application does not have a applicant selected.
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className='row mb-4'>
                <div className="col">
                    <p>Legal Entity:</p>
                    <strong>{data.company_name}</strong>
                </div>
                <div className="col">
                    <p>DBA:</p>
                    <strong>{data.dba_name}</strong>
                </div>
                <div className="col">
                    <p>Type:</p>
                    <strong>{data.business_type?.name}</strong>
                </div>
                <div className="col">
                    <p>Incorporated State:</p>
                    <strong>{data.state_of_incorporation}</strong>
                </div>
                <div className="col">
                    <p>Date Established:</p>
                    <strong>
                        <Date date={data.date_established}/>
                    </strong>
                </div>
            </div>

            <hr/>

            <div className='row mb-3'>
                <div className="col">
                    <p>Physical Address:</p>
                    <strong>{data.address}</strong>
                </div>
                <div className="col">
                    <p>City:</p>
                    <strong>{data.city}</strong>
                </div>
                <div className="col">
                    <p>County:</p>
                    <strong>{data.county}</strong>
                </div>
                <div className="col">
                    <p>State:</p>
                    <strong>{data.state}</strong>
                </div>
                <div className="col">
                    <p>Zip Code:</p>
                    <strong>{data.zip}</strong>
                </div>
            </div>

            <hr/>

            <div className='row mb-4'>
                <div className="col">
                    <p>Phone Number:</p>
                    <strong><Phone number={data.phone_number}/></strong>
                </div>
                <div className="col">
                    <p>Website:</p>
                    <strong>{data.website}</strong>
                </div>
                <div className="col">
                    <p>Description:</p>
                    <strong>{data.description}</strong>
                </div>
                <div className="col">
                    <p>Comment:</p>
                    <strong>{data.comment}</strong>
                </div>
            </div>

            <hr/>

            <div className='row mb-4'>
                <div className="col">
                    {data.is_ssn ?
                        <p>Social Security Number:</p>
                        :
                        <p>Federal ID:</p>
                    }
                    <strong>{data.federal_id}</strong>
                </div>
                <div className="col">
                    <p>NAICS Code:</p>
                    <strong>{data.naics}</strong>
                </div>
            </div>
        </div>
    )

}
export default View