import React from 'react';
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import Axios from "../../Mics/axiosInstance";
import BusinessTypeSelect from "../../Inputs/BusinessTypeSelect";
import PhoneInput from "../../Inputs/PhoneInput";
import StatesSelector from "../../Inputs/StatesSelector";
import VendorSourceTypeSelector from '../../Inputs/VendorSourceTypeSelector'
import {NotificationManager} from 'react-notifications';

export const Add = ({ applicationId, hideModal }) => {
    const { register, handleSubmit } = useForm();

    const saveVendor = async (data) => {
        await Axios.post('/vendors', data).then(res => {
            Axios.post('/applications/' + applicationId + '/attach_vendor/' + res.data.id).then(a2v => {
                return window.location.reload(false);
            }).catch((error) => {
                NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
            })
        }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }

    return (
        <div>
            <form onSubmit={handleSubmit(saveVendor)}>
                <Modal.Header>
                    <Modal.Title>Add Vendor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <label htmlFor="company-name" className="form-label">Company Name<span className="required">*</span></label>
                                <input ref={register({ required: true })} className="form-control" id="company-name" name="company_name" />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="internal-name" className="form-label">Internal Name</label>
                                <input ref={register} className="form-control" id="internal-name" name="internal_name" />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="dba-name" className="form-label">DBA Name</label>
                                <input ref={register} className="form-control" id="dba-name" name="dba_name" />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4">
                                <label htmlFor="business-type" className="form-label">Source Type</label>
                                <VendorSourceTypeSelector register={register} value={null} />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="business-type" className="form-label">Business Type</label>
                                <BusinessTypeSelect register={register} />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="phone" className="form-label">Phone Number</label>
                                <PhoneInput register={register} name="phone_number" />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label htmlFor="address" className="form-label">Address</label>
                                <input ref={register} className="form-control" id="address" name="address" />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="city" className="form-label">City</label>
                                <input ref={register} className="form-control" id="city" name='city' />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4">
                                <label htmlFor="county" className="form-label">County</label>
                                <input ref={register} className="form-control" id="county" name="county" />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="state" className="form-label">State</label>
                                <StatesSelector register={register} name="state" />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="zip" className="form-label">Zip</label>
                                <input ref={register} className="form-control" id="zip" name='zip' maxLength={5} />
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col-md-8">
                                <label htmlFor="Comment" className="form-label">Comment</label>
                                <textarea ref={register} className="form-control" name="comment" />
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={(e) => { e.preventDefault(); hideModal() }}>Cancel</button>
                    <button className="btn btn-primary" type="submit">Save Vendor</button>
                </Modal.Footer>
            </form>

        </div>
    )

}
export default Add