import React, {useEffect, useState} from 'react'
import {Link, Redirect} from 'react-router-dom'
import Axios from "../Mics/axiosInstance"
import Money from "../Helpers/Money"
import Date from "../Helpers/Date"

export const List = () => {
    const [loading, setLoading] = useState(true);
    const [applications, setApplications] = useState([]);
    const [appId, setApplicationId] = useState(false);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        if(loading) {
            Axios.get("/applications").then(res=>{
                setApplications(res.data)
                setLoading(false);
            })
        }
    }, [loading, setLoading, applications, setApplications]);

    const tableRowClick = (id) => {
        setApplicationId(id)
        setRedirect(true)
    }

    const search = () => {
        setRedirect(true)
    }

    if(loading){
        return <div>Loading Applications</div>
    }

    if(redirect && appId){
        return (<Redirect to={`/applications/${appId}/overview`} />)
    }
    let sortedApplications = applications.length>0?applications.sort((a,b)=>b.id-a.id):[]
    return (
        <div className="row">
            <div className="col-md">
                <div className="card mb-4">
                    <div className="card-header">
                        <Link to={`/applications/new`} className="btn btn-sm btn-success float-right">
                            <i className="fas fa-plus"/> New Application
                        </Link>
                        All Applications
                    </div>
                    <div className="card-body">
                        Search for Application by ID:
                        <form>
                            <div className="row mb-3">
                                <div className="col-2">
                                    <input className="form-control" name="appID" onChange={event => setApplicationId(event.target.value)} />
                                </div>
                                <div className="col-10">
                                    <button className="btn btn-success" onClick={search}>
                                        <i className="fas fa-search"/> Search
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div className="row">
                            <div className="col-12">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Company</th>
                                            <th>Submitted Amount</th>
                                            <th>Created</th>
                                            <th>BDO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {sortedApplications.map(application=>{
                                        return (
                                            <tr onClick={() => tableRowClick(application.id)} className="table-row-click">
                                                <td>
                                                    {application.id}
                                                </td>
                                                <td>
                                                    {application.applicant.company_name}
                                                </td>
                                                <td>
                                                    <Money amount={application.submitted_amount}/>
                                                </td>
                                                <td>
                                                    <Date date={application.created_at}/>
                                                </td>
                                                <td>
                                                    {application.bdo_user?.name}  
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default List