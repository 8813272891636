import React from 'react';
import BankStatement from "./BankStatement";

export const List = ({application}) => {
    var sortedList = [...application.bank_statements]
    sortedList.sort((a,b)=>{
        return new Date(a.statement_date) - new Date(b.statement_date)
    }).reverse();
    return (
        <div>
            {sortedList.map(statement=>{
                return (<BankStatement statement={statement}/>)
            })}
        </div>
    )
}
export default List
