import React, { useState } from 'react';
import Phone from "../../Helpers/Phone";
import SSN from "../../Helpers/SSN";
import Edit from "./Edit";
import Delete from './Delete'
export const PersonalGuarantor = ({ guarantor, applicationId }) => {
    const [modalState, setModalState] = useState(false)

    const openModal = () => {
        if (modalState === false) {
            setModalState(true)
        }
    }

    const closeModal = () => {
        setModalState(false)
    }

    return (
        <tr className="table-row-click" >
            <td>
                {guarantor.first_name} {guarantor.middle_name} {guarantor.last_name}
            </td>
            <td>
                <SSN number={guarantor.ssn} />
            </td>
            <td>
                <Phone number={guarantor.phone_number} />
            </td>
            <td>
                {guarantor.email}
            </td>
            <td>
                {guarantor.address}
            </td>
            <td>
                {guarantor.city}
            </td>
            <td>
                {guarantor.state}
            </td>
            <td>
                {guarantor.zip}
            </td>
            <td>
                <i className="fas fa-edit mr-2 pointer" onClick={openModal}/><Edit open={modalState} toggle={closeModal} guarantor={guarantor} />
            </td>
            <td>
                <Delete personal_guarantor={guarantor} applicationId={applicationId} />
            </td>
        </tr>
    )

}
export default PersonalGuarantor