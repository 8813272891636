import React, { useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import Axios from "../../Mics/axiosInstance";
import StatesSelector from '../../Inputs/StatesSelector'
import PhoneInput from '../../Inputs/PhoneInput'
import SSNInput from '../../Inputs/SSNInput'
import PercentageInput from '../../Inputs/PercentageInput'
import {NotificationManager} from 'react-notifications';

export const Add = ({ applicationId, open, toggle }) => {
    const { register, handleSubmit, errors, setValue } = useForm();
    const [isOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
        if (open) {
            setIsOpen(true)
        }
    }, [open, setIsOpen]);

    const hideModal = () => {
        toggle()
        setIsOpen(false);
    };

    const save = (data) => {
        Axios.post('/applications/' + applicationId + '/personal_guarantors', data).then(res => {
            return window.location.reload(false);
        }).catch(err=>{
            NotificationManager.error(`${err.key} ${err.message}`, 'Error saving')
        })
    };

    return (
        <Modal show={isOpen} onHide={hideModal} size="lg">
            <form onSubmit={handleSubmit(save)}>
                <Modal.Header>
                    <Modal.Title>Add Personal Guarantor</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="first-name" className="form-label">First Name <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.first_name ? "is-invalid" : ""}`} id="first-name" name="first_name" />
                        </div>
                        <div className="col">
                            <label htmlFor="middle-name" className="form-label">Middle Name</label>
                            <input ref={register} className="form-control" id="middle-name" name="middle_name" />
                        </div>
                        <div className="col">
                            <label htmlFor="last-name" className="form-label">Last Name <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.last_name ? "is-invalid" : ""}`} id="last-name" name="last_name" />
                        </div>
                    </div>

                    <div className="row mb-5">
                        <div className="col">
                            <label htmlFor="title" className="form-label">Title</label>
                            <input ref={register} className="form-control" name="title" />
                        </div>
                        <div className="col">
                            <label htmlFor="date_of_birth" className="form-label">Date of Birth</label>
                            <input ref={register} className="form-control" id="date_of_birth" type="date" name="date_of_birth" />
                        </div>
                        <div className="col">
                            <label htmlFor="phone_number" className="form-label">Phone Number</label>
                            <PhoneInput register={register} name="phone_number" />
                        </div>
                    </div>

                    <hr />

                    <div className="row mb-5">
                        <div className="col-4">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input ref={register} className={'form-control'} id='email' name='email' />
                        </div>
                        <div className="col-4">
                            <label htmlFor="ssn" className="form-label">SSN</label>
                            <SSNInput register={register} name="ssn" />
                        </div>
                        <div className="col-4">
                            <label htmlFor="ownership" className="form-label">Ownership %</label>
                            <PercentageInput setValue={setValue} register={register} name="ownership" />
                        </div>
                    </div>

                    <hr />

                    <div className="row mb-3">
                        <div className="col-8">
                            <label htmlFor="address" className="form-label">Address <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.address ? "is-invalid" : ""}`} id="address" name="address" />
                        </div>
                        <div className="col">
                            <label htmlFor="city" className="form-label">City <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.city ? "is-invalid" : ""}`} id="city" name='city' />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="county" className="form-label">County </label>
                            <input ref={register} className={`form-control ${errors.county ? "is-invalid" : ""}`} id="county" name="county" />
                        </div>
                        <div className="col">
                            <label htmlFor="state" className="form-label">State<span className="required">*</span> </label>
                            <StatesSelector register={register({ required: true })} name="state" />
                        </div>
                        <div className="col">
                            <label htmlFor="zip" className="form-label">Zip <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.zip ? "is-invalid" : ""}`} id="zip" name='zip' minLength={5} maxLength={5} />
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={(e) => { e.preventDefault(); hideModal() }}>Cancel</button>
                    <button className="btn btn-primary" type="submit">Save Personal Guarantor</button>
                </Modal.Footer>
            </form>
        </Modal>
    )

}
export default Add