import React, { useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import Axios from "../Mics/axiosInstance";
import StatesSelector from "../Inputs/StatesSelector";
import { NotificationManager } from 'react-notifications';


export const Add = ({ allowedBillingMethods, type, typeId, modalState, toggleModal, triggerReload }) => {
    const { register, handleSubmit, errors } = useForm();
    const [isOpen, setIsOpen] = React.useState(false);
    const [selectedBillingMethod, setSelectedBillingMethod] = React.useState('');

    useEffect(() => {
        if (modalState) {
            setIsOpen(true)
            setSelectedBillingMethod('')
        }
    }, [modalState, setIsOpen]);

    const hideModal = () => {
        setSelectedBillingMethod('')
        toggleModal()
        setIsOpen(false);
    };

    const save = (formData) => {
        if(type === 'source'){
            formData.source_id = typeId
        }
        if(type === 'vendor'){
            formData.vendor_id = typeId
        }
        if(type === 'applicant'){
            formData.applicant_id = typeId
        }

        Axios.post('/billing_defaults', formData).then(res => {
            hideModal()
            triggerReload()

        }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    };

    const handleChange = (id) =>{
        allowedBillingMethods.forEach((method) => {
            if(parseInt(id)===method.id){
                setSelectedBillingMethod(method.name)
            }
        })
    }

    return (
        <Modal show={isOpen} onHide={hideModal} size="lg">
            <form onSubmit={handleSubmit(save)}>
                <Modal.Header>
                    <Modal.Title>Add Default Billing</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert alert-info">
                        A {type} can only have 1 of each billing methods set as default, you must delete a already used billing method if you would like to add a new one
                    </div>

                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="description" className="form-label">Billing Method <span className="required">*</span></label>
                            <select ref={register({ required: true })} name="billing_method_id" className={`form-control ${errors.billing_method_id ? "is-invalid" : ""}`} id="billing_method_id" onChange={(e)=>handleChange(e.target.value)}>
                                <option value="" disabled selected>Select billing method</option>
                                {allowedBillingMethods.map(option => {
                                    return (<option value={option.id}>{option.name}</option>)
                                })}
                            </select>
                        </div>

                        <div className="col">
                            <label htmlFor="account_holder_name" className="form-label">Account Holder's Name <span className="required">*</span></label>
                            <input className={`form-control ${errors.account_holder_name ? "is-invalid" : ""}`} type='text' id='account_holder_name' name='account_holder_name' ref={register({ required: true })} />
                        </div>
                    </div>
                    {selectedBillingMethod !== '' ?
                        <>
                            {selectedBillingMethod === 'Check' ?
                                <div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <label htmlFor="address" className="form-label">Address <span className="required">*</span></label>
                                            <input className={`form-control ${errors.address ? "is-invalid" : ""}`} type="text" id="address" name="address" ref={register({ required: true })}/>
                                        </div>
                                        <div className="col">
                                            <label htmlFor="city" className="form-label">City <span className="required">*</span></label>
                                            <input className={`form-control ${errors.city ? "is-invalid" : ""}`} type="text" id="city" name="city" ref={register({ required: true })}/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <label htmlFor="state" className="form-label">State <span className="required">*</span></label>
                                            <StatesSelector register={register} name="state"/>
                                        </div>
                                        <div className="col">
                                            <label htmlFor="zip" className="form-label">Zip <span className="required">*</span></label>
                                            <input className={`form-control ${errors.zip ? "is-invalid" : ""}`} type="text" id="zip" name="zip" ref={register({ required: true })} maxLength={5}/>
                                        </div>
                                    </div>

                                </div>
                                :
                                <div>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <label htmlFor="bank_name" className="form-label">Bank Name <span className="required">*</span></label>
                                            <input className={`form-control ${errors.bank_name ? "is-invalid" : ""}`} type='text' id='bank_name' name='bank_name' ref={register({ required: true })}/>
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col">
                                            <label htmlFor="account_number" className="form-label">Account Number <span className="required">*</span></label>
                                            <input className={`form-control ${errors.account_number ? "is-invalid" : ""}`} type='number' id='account_number' name='account_number' ref={register({ required: true })}/>
                                        </div>
                                        <div className="col">
                                            <label htmlFor="routing_number" className="form-label">Routing Number <span className="required">*</span></label>
                                            <input className={`form-control ${errors.routing_number ? "is-invalid" : ""}`} type='text' id='routing_number' name='routing_number' maxLength={9} ref={register({ required: true })}/>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                        :
                        <>
                        </>
                    }

                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="comment" className="form-label">Comment</label>
                            <textarea className="form-control" id="comment" name="comment" rows="3" ref={register}/>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={(e) => { e.preventDefault(); hideModal() }}>Cancel</button>
                    <button className="btn btn-primary" type="submit">Save</button>
                </Modal.Footer>
            </form>
        </Modal>
    )

}
export default Add