import React, { useState } from "react";
import VendorPrefundingItem from './VendorPrefundingItem'
import Axios from "../Mics/axiosInstance";
import Money from "../Helpers/Money";

export const Vendor = ({ vendor, document }) => {
    const [prefundings, setPrefundings] = useState(document.prefundings?.filter(prefunding => prefunding.vendor_id === vendor.id));

    const handleVendorInvoice = () => {
        var list = document.equipments.filter(equipment => equipment.vendor_id === vendor.id)
        var total = 0
        list.map(equipment => {
            if (equipment.cost) {
                total += Number(equipment.cost)
            }
        })
        return total
    }

    const handleAdd = () => {
        handlePrefundingAdd()
    }

    const handlePrefundingAdd = () => {
        setPrefundings([].concat(prefundings, [{ document_id: document.contract_number, vendor_id: vendor.id, amount: 0.00 }]))
    }

    const triggerChange = () => {
        Axios.get('/documents/' + document.contract_number + '/prefundings?vendor_id=' + vendor.id).then((res) => {
            setPrefundings([]) // Clear it out entirely and then set it seems to work
            setPrefundings(res.data)
        })
    }

    return (
        <tr>
            <td>
                {vendor.company_name}
            </td>
            <td>
                <div className="mb-2">
                    <Money amount={handleVendorInvoice()} />
                </div>
            </td>

            {vendor.approved_for_prefunding
                ?
                <React.Fragment>
                    <td>
                        {vendor.prefunding_terms}
                    </td>
                    <td>
                        {prefundings.map((prefunding) => {
                            return (
                                <VendorPrefundingItem prefunding={prefunding} triggerChange={triggerChange} />
                            )
                        })}
                        <button className="btn btn-sm btn-success btn-block" onClick={handleAdd}>+ Add Prefunding Amount</button>
                    </td>
                </React.Fragment>
                :
                <React.Fragment>
                    <td>
                    </td>
                    <td>
                    <div className="alert alert-danger">Vendor is not approved for Prefunding</div>
                </td>
                </React.Fragment>

            }

        </tr>
    )
}

export default Vendor
