import React from 'react';
import {Link} from "react-router-dom";
import {useQuery} from "react-query";
import Axios from "../Mics/axiosInstance";

function useReports() {
    return useQuery('reports', async () => {
        const { data } = await Axios.get("/reports");
        return data;
    });
}

export const List = () => {
    const { data, isLoading } = useReports()

    if(isLoading || data === undefined){
        return (
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-body text-center">
                            <i className="fas fa-spinner fa-pulse fa-4x mb-3"/>
                            <div>
                                Currently loading
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        <i className="fas fa-chart-bar"/> Reports
                    </div>
                    <div className="list-group  list-group-flush">
                        {data.map(report=>{
                            return (
                                <Link to={`/reports/${report.endpoint}`} className="list-group-item list-group-item-action">
                                    <div>
                                        {report.title}
                                    </div>
                                    <div>
                                        <small>{report.description}</small>
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default List
