import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();
const SetAuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [value, setAuth] = useState(null);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        if(!loaded) {
            const auth = localStorage.getItem('peak-token');
            setAuth(auth ? auth : null);
            setLoaded(true);
        }
    }, [loaded, setLoaded, setAuth]);
    if(!loaded) {
        return null;
    }
    return (
        <AuthContext.Provider value={value}>
            <SetAuthContext.Provider value={setAuth}>
                {children}
            </SetAuthContext.Provider>
        </AuthContext.Provider>
    );
}

export const useUpdateAuth = () => {
    const setAuth = useContext(SetAuthContext);
    if (setAuth === undefined) {
        throw new Error('useUpdateAuth must be used within an AuthProvider');
    }
    const callback = useCallback(auth => {
        localStorage.setItem('peak-token', auth);
        setAuth(auth);
    }, [setAuth]);
    return callback;
}

export const useAuth = () => {
    const auth = useContext(AuthContext);
    if (auth === undefined) {
        throw new Error('useAuth must be used withing an AuthProvider');
    }
    return auth;
}
