import React, {useEffect, useState} from 'react';

export const FederalID = ({id}) => {
    const [federalID, setFederalID] = useState('');

    useEffect(() => {
        format(id)
    });

    const format = () => {
        if(id !== undefined && id !== '' && id !== null){
            id = id.replace('-', '')
            setFederalID(id.substring(0, 2) + '-' + id.substring(2))
        }
    }

    return (<>{federalID}</>)
}
export default FederalID


