import React, {useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import Axios from "../Mics/axiosInstance";
import {useForm} from "react-hook-form";
import {useQuery} from "react-query";
import CurrencyInput from "../Inputs/CurrencyInput";
import StatesSelector from "../Inputs/StatesSelector";
import { NotificationManager } from 'react-notifications';

function useApplicationVendors(id) {
    return useQuery(["vendors", id], async () => {
        const { data } = await Axios.get("/applications/"+id+"/vendors");
        return data;
    });
}

export const Edit = ({document, modalState, toggleModal, triggerReload, equipment}) => {
    const { data, isLoading } = useApplicationVendors(document.application_id);
    const { register, handleSubmit, errors, setValue } = useForm();
    const [isOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
        if(modalState){
            setIsOpen(true)
        }
    }, [modalState, setIsOpen]);

    const hideModal = () => {
        toggleModal()
        setIsOpen(false);
    };

    const save = (formData) => {
        Axios.put('/documents/'+document.contract_number+'/equipments/'+equipment.id, formData).then(res => {
            hideModal()
            triggerReload()
        }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    };

    if(isLoading){
        return <div>Loading Equipment</div>
    }

    return (
        <Modal show={isOpen} onHide={hideModal} size="lg">
            <form onSubmit={handleSubmit(save)}>
                <Modal.Header>
                    <Modal.Title>Add Equipment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="description" className="form-label">Description <span className="required">*</span></label>
                            <textarea ref={register({ required: true })} className={`form-control ${errors.description ? "is-invalid" : ""}`} id="description" name="description" rows="3" defaultValue={equipment.description}  />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-3">
                            <label htmlFor="cost" className="form-label">Cost <span className="required">*</span></label>
                            <CurrencyInput name="cost" value={equipment.cost} setValue={setValue} register={register} />
                        </div>
                    </div>


                    <div className="row mb-3">
                        <div className="col-5">
                            <label htmlFor="address" className="form-label">Address <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.address ? "is-invalid" : ""}`} id="address" name="address"  defaultValue={equipment.address}/>
                        </div>
                        <div className="col">
                            <label htmlFor="city" className="form-label">City <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.city ? "is-invalid" : ""}`} id="city" name="city"  defaultValue={equipment.city}/>
                        </div>
                        <div className="col">
                            <label htmlFor="state" className="form-label">State <span className="required">*</span></label>
                            <StatesSelector register={register({ required: true })} name="state" value={equipment.state} />
                        </div>
                        <div className="col">
                            <label htmlFor="zip" className="form-label">Zip <span className="required">*</span></label>
                            <input ref={register({ required: true })} maxLength="5" className={`form-control ${errors.zip ? "is-invalid" : ""}`} id="zip" name="zip"  defaultValue={equipment.zip}/>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="vin_number" className="form-label">VIN Number</label>
                            <input ref={register} className={`form-control`} id="vin_number" name="vin_number"  defaultValue={equipment.vin_number}/>
                        </div>
                        <div className="col">
                            <label htmlFor="serial_number" className="form-label">Serial Number</label>
                            <input ref={register} className={`form-control`} id="serial_number" name="serial_number"  defaultValue={equipment.serial_number}/>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="vendor_id" className="form-label">Vendor <span className="required">*</span></label>
                            {isLoading ?
                                <div>Loading Vendors</div>
                                :
                                <select ref={register({required: true})} className={`form-control ${errors.state ? "is-invalid" : ""}`} id="vendor_id" name="vendor_id" defaultValue={equipment.vendor_id}>
                                    <option value="" selected disabled>Select vendor</option>
                                    {data.map(vendor => {
                                        return (
                                            <option value={vendor.id}>
                                                {vendor.company_name} - {vendor.address} {vendor.city}, {vendor.state} {vendor.zip}
                                            </option>
                                        )
                                    })}
                                </select>
                            }
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={(event) => { event.preventDefault(); hideModal()}}>Cancel</button>
                    <button className="btn btn-primary" type="submit">Save</button>
                </Modal.Footer>
            </form>
        </Modal>
    )

}
export default Edit