import React, {useState} from 'react';
import Edit from "./Edit";
import {confirmAlert} from "react-confirm-alert";
import Axios from "../../Mics/axiosInstance";
import Date from "../../Helpers/Date";
import { NotificationManager } from 'react-notifications';

export const Item = ({item, triggerReload}) => {
    const [modalState, setModalState] = useState(false)

    const toggleModal = () => {
        setModalState(!modalState)
    }

    const userArchived = () => {
        if(item.is_archived){
            return { backgroundColor: '#fadbd8', border: '#f8ccc8', color: '#78261f' }
        }
    }

    return (
        <>
            <tr style={userArchived()}>
                <td>
                    {item.username}
                </td>
                <td>
                    {item.name}
                </td>
                <td>
                    {item.email}
                </td>
                <td>
                    {item.is_archived ?
                        <span style={{fontStyle: 'bold'}}>
                            User is Archived
                        </span>
                        :
                        <>
                            {item.roles.length ?
                                <span>
                            {item.roles.map((role) => {
                                return (<span>{role.name}, </span>)
                            })}
                        </span>
                                :
                                ''
                            }
                        </>
                    }

                </td>
                <td>
                    <Date date={item.created_at} time/>
                </td>
                <td>
                    {item.created_user?.name}
                </td>

                <td>
                    <i className="fas fa-edit mr-2 pointer" title="Edit this item" onClick={toggleModal}/>
                </td>
            </tr>
            <Edit modalState={modalState} toggleModal={toggleModal} triggerReload={triggerReload} item={item}/>
        </>
    )

}
export default Item