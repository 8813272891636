import React from 'react';
import Axios from "../Mics/axiosInstance";
import {useForm} from "react-hook-form";
import CurrencyInput from "react-currency-input-field";
import {useQuery} from "react-query";
import {NotificationManager} from 'react-notifications';
import Phone from '../Helpers/Phone';
import PhoneInput from '../Inputs/PhoneInput';
function useAssetTypes() {
    return useQuery(["asset-types"], async () => {
        const { data } = await Axios.get("/asset_types");
        return data;
    });
}

export const Document = ({document, triggerReload}) => {
    const { data, isLoading } = useAssetTypes();
    const { register, handleSubmit, errors } = useForm({mode: 'onBlur'});
    const [loan_amount, set_loan_amount] = React.useState(document.loan_amount ? document.loan_amount : 0.00);

    const save = (formData) => {
        formData.loan_amount = loan_amount
        Axios.put('/applications/'+document.application_id+'/documents/'+document.contract_number, formData).then(()=>{
            triggerReload()
        })
        .catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }

    const handleCurrencyChange = (value, name)=>{
        if(name === 'loan_amount'){
            set_loan_amount(value)
        }
    }

    if(isLoading){
        return <div>Loading asset types</div>
    }

    return (
        <form onBlur={handleSubmit(save)}>
            <div className="row mb-4">
                <div className="col">
                    <label className="form-label">Initial Term <span className="required">*</span></label>
                    <input className="form-control" type="number" name="term" ref={register({ required: true })} defaultValue={document.term}/>
                </div>
                <div className="col">
                    <label className="form-label">Loan Amount</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                        </div>
                        <CurrencyInput
                            className="form-control"
                            name="loan_amount"
                            placeholder="0.00"
                            defaultValue={loan_amount}
                            precision={2}
                            onChange={handleCurrencyChange}
                        />
                    </div>
                </div>
                <div className="col">
                    <label className="form-label">Contract Type</label>
                    <select className="form-control" name="contract_type" defaultValue={document.contract_type} ref={register} >
                        <option value="A-Promissory Note">Promissory Note</option>
                        <option value="B-Conditional Sale">Conditional Sale</option>
                        <option value="C-True Lease">True Lease</option>
                        <option value="D-Lease Purchase">Lease Purchase</option>
                        <option value="E-EFA">EFA</option>
                    </select>
                </div>
                <div className="col">
                    <label className="form-label">Billing Group</label>
                    <select className="form-control" name="billing_group" defaultValue={document.billing_group} ref={register} >
                        <option value="A-1-7">A-1-7</option>
                        <option value="B-8-14">B-8-14</option>
                        <option value="C-15-21">C-15-21</option>
                        <option value="D-22-31">D-22-31</option>
                    </select>
                </div>
            </div>

            <hr/>

            <div className="row mb-4">
                <div className="col-3">
                    <label className="form-label">Asset Type</label>
                    <select className="form-control" name='asset_type_id' defaultValue={document.asset_type_id} ref={register} >
                        {data.map((assetType) => {
                            return (<option value={assetType.id}>{assetType.name}</option>)
                        })}
                    </select>
                </div>
                <div className="col-3">
                    <label className="form-label">Status</label>
                    <select className="form-control" name="status" defaultValue={document.status} ref={register} >
                        <option value='Funded'>Funded</option>
                        <option value='Prefunding'>Prefunding</option>
                        <option value='Docs Sent'>Docs Sent</option>
                        <option value='Inspection'>Inspection</option>
                        <option value='Docs Received'>Docs Received</option>
                        <option value='Dead'>Dead</option>
                    </select>
                </div>
                <div className="col-3">
                    <label className="form-label">Status Date</label>
                    <input className="form-control" type="date" name="status_date" ref={register} defaultValue={document.status_date}/>
                </div>

            </div>
            <hr/>
            
            <div className="row mb-4">
                <div className="col">
                    <label>Contact Name</label>
                    <input className="form-control" name="contact_name" ref={register} defaultValue={document.contact_name}/>
                </div>
                <div className="col">
                    <label>Contact Phone Number</label>
                    <PhoneInput className="form-control" name='contact_phone_number' id="contact_phone_number" register={register} value={document.contact_phone_number}/>
                </div>
                <div className="col">
                    <label className="form-label">Remaining Term <span className="required">*</span></label>
                    <input className={`form-control ${errors.remaining_terms ? "is-invalid" : ""}`} type="number" name="remaining_terms" ref={register({ required: true })} defaultValue={document.remaining_terms}/>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-3">
                    <label className="form-label">Bank Rate</label>
                    <div className={`input-group`}>
                        <input className="form-control" name="bank_rate" ref={register} defaultValue={document.bank_rate} />
                        <div className="input-group-append">
                            <span className="input-group-text">%</span>
                        </div>
                    </div>
                </div>
                <div className="col-3">
                    <label className="form-label">Dedicated Rate</label>
                    <div className={`input-group`}>
                        <input className="form-control" name="dedicated_rate" ref={register} defaultValue={document.dedicated_rate} />
                        <div className="input-group-append">
                            <span className="input-group-text">%</span>
                        </div>
                    </div>
                </div>
            </div>
            
        </form>
    )
}

export default Document
