import React, {useState,} from 'react';
import {useQuery, useQueryClient} from "react-query";
import Axios from "../Mics/axiosInstance";
import Add from "./Add";
import Item from "./Item";
import {useParams} from "react-router-dom";

function useFollowups(contractNumber) {
    return useQuery(["documents", contractNumber, "followups"], async () => {
        const { data } = await Axios.get("/documents/"+contractNumber+"/followups");
        return data;
    });
}

export const List = (props) => {
    let { contractNumber } = useParams();
    if(contractNumber === undefined){
        contractNumber = props.contractNumber;
    }
    const { data, isLoading } = useFollowups(contractNumber);
    const [modalState, setModalState] = useState(false)
    const queryClient = useQueryClient();

    const triggerReload = () => {
        queryClient.refetchQueries(["documents", contractNumber, "followups"])
    }

    const toggleModal = () => {
        setModalState(!modalState)
    }

    if(isLoading){
        return <div>Loading Followups</div>
    }

    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary float-right" onClick={toggleModal}>
                            <i className="fas fa-plus"/> Add Followup
                        </button>
                        Followups
                    </div>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Item</th>
                            <th>Date Completed</th>
                            <th>Created At</th>
                            <th>Created By</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!data.length ?
                            <tr>
                                <td className="text-center" colSpan={99}>
                                    No followups have been added to this application
                                </td>
                            </tr>
                            :
                            ''
                        }

                        {data.map(item=>{
                            return <Item item={item} contractNumber={contractNumber} triggerReload={triggerReload}/>
                        })}
                        </tbody>
                    </table>
                    <Add modalState={modalState} toggleModal={toggleModal} contractNumber={contractNumber} triggerReload={triggerReload}/>
                </div>
            </div>
        </div>
    )
}

export default List