import React, { useEffect, useState } from 'react';
import Axios from "../Mics/axiosInstance";
import {useQuery} from 'react-query'
import Money from '../Helpers/Money'
import Date from '../Helpers/Date'
function useApplication(id){
    return useQuery(['document','item', 'application', id], async()=>{
        const {data} = await Axios.get('/applications/'+id)
        return data
    })
}

export const Item = ({document, tableRowClick}) =>{
    console.log(document)

    const {data, isLoading} = useApplication(document.application_id)
    if(isLoading){
        return <div>loading</div>
    }
    return(
        <tr onClick={() => tableRowClick(document.contract_number)} className="table-row-click">
            <td>
                {document.contract_number}
            </td>
            <td>
                {data.applicant?.company_name}
            </td>
            <td>
                <Money amount={document.loan_amount} />
            </td>
            <td>
                {document.status}
            </td>
            <td>
                <Date date={document.created_at} />
            </td>
            <td>
                {document.doc_user?.name}
            </td>
        </tr>
    )
}

export default Item