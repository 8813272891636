import React from 'react';
import Axios from "../../Mics/axiosInstance";
import BankInfo from "./BankInfo";
import {useQuery} from "react-query";

function useBankStatements(applicationId) {
    return useQuery(["bank-statements", applicationId], async () => {
        const { data } = await Axios.get('/applications/'+applicationId+'/bank_statements')
        return data;
    });
}
export const List = ({applicationId}) => {
    const { data, isLoading } = useBankStatements(applicationId);

    if (isLoading) {
        return <div>Loading</div>
    }

    return (
        <table className="table table-hover">
            <thead>
                <tr>
                    <th>Bank Name</th>
                    <th>Period</th>
                    <th>Deposits</th>
                    <th>Withdrawals</th>
                    <th>Ending Balance</th>
                    <th>Remove</th>
                </tr>
            </thead>
            <tbody>
                {!data.length ?
                    (
                        <tr>
                            <td colSpan={99} className="text-center">
                                Application does not have any banking information.
                            </td>
                        </tr>
                    )

                    : ''}
                {data.map(bank=>{
                    return (<BankInfo bank={bank} applicationId={applicationId}/>)
                })}
            </tbody>
        </table>
    )

}
export default List