import React, { Component } from 'react'
import endpoints from '../Mics/endpoints'
import Axios from "../Mics/axiosInstance";
import '../../Theme/css/login.css';

export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            error: ''
        };
        this.makePristine();
    }

    componentDidMount() {
        if(window.location.search.includes('expired')){
            this.setState({ error: 'You have been logged out due to inactivity.' })
        }
    }

    makePristine = () => {
        this.setState({ error: '' })
        localStorage.removeItem('peak-token');
        localStorage.removeItem('peak-email');
        localStorage.removeItem('peak-name');
        localStorage.removeItem('peak-roles');
    }

    login = (event) => {
        event.preventDefault();
        this.makePristine();

        if(this.state.username.length === 0 || this.state.password.length === 0){
            this.setState({ error: 'Username and Password are both required' })
        }else{
            var self = this;

            Axios.post(endpoints.AUTH.LOGIN, {
                username: this.state.username,
                password: this.state.password
            })
                .then(function (response) {
                    localStorage.setItem('peak-token', response.data.token)
                    localStorage.setItem('peak-email', response.data.email)
                    localStorage.setItem('peak-name', response.data.name)
                    localStorage.setItem('peak-roles', JSON.stringify(response.data.roles))
                    return window.location.replace('/')
                })
                .catch(function (response) {
                    self.setState({ error: response.message })
                });
        }
    }

    handleUsernameChange = (event) => {
        this.setState({ username: event.target.value })
    }
    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value })
    }

    render() {
        return (
            <div className="login-page">
                <div className="login-form">
                    <form>
                        <img src="https://www.dedicatedfunding.com/wp-content/uploads/2017/01/logo.png" className="img-fluid" alt="logo"/>
                        <div className="login-peak">Peak<sup>2.0</sup></div>
                        {this.state.error !== '' && <div className="alert alert-danger">{this.state.error}</div>}
                        <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <input type="text" className="form-control" id="username" onChange={this.handleUsernameChange}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input type="password" className="form-control" id="password" onChange={this.handlePasswordChange}/>
                        </div>

                        <button className="btn btn-primary btn-block" onClick={this.login} >Login</button>
                    </form>
                </div>
            </div>
        )
    }
}
