import React, { useState } from 'react';
import Axios from "../../Mics/axiosInstance";
import PersonalGuarantor from "./PersonalGuarantor";
import { useQuery } from "react-query";

function usePersonalGuarantors(applicationId) {
    return useQuery(["applicants", applicationId, "personal_guarantors"], async () => {
        const { data } = await Axios.get('/applications/' + applicationId + '/personal_guarantors');
        return data;
    });
}

export const List = ({ applicationId }) => {
    const { data, isLoading } = usePersonalGuarantors(applicationId);
    const [search, setSearch] = useState('')

    if (isLoading) {
        return <div>Loading</div>
    }

    return (
        <div>
            {/* {data.length ?
                (
                    <div className="row mb-4">
                        <div className="col-3">
                            <lable className="form-label">Search</lable>
                            <input className="form-control" name="search" defaultValue={search} onChange={(e) => setSearch(e.target.value)} />
                        </div>
                    </div>
                )
                : ''
            } */}

            <table className="table table-hover">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>SSN</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Address</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Zip</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {!data.length ?
                        (
                            <tr>
                                <td colSpan={99} className="text-center">
                                    Application does not have any personal guarantors.
                                </td>
                            </tr>
                        )

                        : ''}
                    {data.map(guarantor => {
                        return (<PersonalGuarantor guarantor={guarantor} applicationId={applicationId} />)
                    })}
                </tbody>
            </table>
        </div>
    )
}
export default List