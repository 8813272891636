import React from 'react';
import Moment from "react-moment";

export const Date = ({date, format, time}) => {
    if(format === undefined){
        format = "MM/DD/YYYY"
    }

    if(time !== undefined){
        format = "MM/DD/YYYY LT"
    }

    if(date !== undefined && date !== '' && date !== null){
        return (<Moment format={format}>{date}</Moment>)
    }else{
        return (<>{date}</>)
    }
}
export default Date
