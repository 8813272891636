import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { Routes } from './Routes';
import { AuthProvider } from '../../Auth/AuthProvider';
import IdleTimer from 'react-idle-timer'
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools'
import endpoints from '../../Components/Mics/endpoints'
import Axios from "../Mics/axiosInstance";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 0,
            refetchOnWindowFocus: false,
        },
    },
})
export class Root extends Component {
    constructor(props) {
        super(props)
        this.idleTimer = null
        this.handleOnIdle = this.handleOnIdle.bind(this)
    }

    handleOnIdle () {
        if(window.location.pathname !== '/login'){
            Axios.post(endpoints.AUTH.LOGOUT, {}).then((res) => {
                localStorage.removeItem('peak-token');
                localStorage.removeItem('peak-email');
                localStorage.removeItem('peak-name');
                localStorage.removeItem('peak-roles');
                return window.location.replace('/login?expired')
            })
        }
    }

    render() {
        return (
            <Suspense fallback={<h1>Loading...</h1>}>
                <QueryClientProvider client={queryClient}>
                    <AuthProvider>
                        <IdleTimer
                            ref={ref => { this.idleTimer = ref  }}
                            timeout={3600000}
                            onIdle={this.handleOnIdle}
                            debounce={500}
                        />
                        <Router>
                            <Route path={`/`}>
                                <Routes/>
                            </Route>
                        </Router>
                    </AuthProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </Suspense>
        )
    }
}

export default Root
