import React from 'react';

export const Money = ({amount}) => {
    if(amount !== undefined && amount !== '' && amount !== null){
        const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return (<span>${formatter.format(amount)}</span>)
    }else{
        return (<>{amount}</>)
    }
}
export default Money
