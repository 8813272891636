import React, { useState } from 'react';
import Edit from "./Edit";
import DefaultBillingList from "../DefaultBillings/List";
import {useParams} from "react-router-dom";

export const Show = () => {
    let { id } = useParams()

    return (
        <>
            <Edit/>
            <hr/>
            <DefaultBillingList typeId={id} type='vendor' />
        </>
    )
}
export default Show