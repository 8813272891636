import React, {useEffect, useState} from 'react';
import Axios from "../../Mics/axiosInstance";
import CorporateGuarantor from "./CorporateGuarantor";
import {useQuery} from "react-query";

function useCorporateGuarantors(applicationId) {
    return useQuery(["applicants", applicationId, "corporate_guarantors"], async () => {
        const { data } = await Axios.get( '/applications/'+applicationId+'/corporate_guarantors');
        return data;
    });
}

export const List = ({applicationId}) => {
    const { data, isLoading } = useCorporateGuarantors(applicationId);

    if(isLoading){
        return <div>Loading</div>
    }

    return (
        <table className="table table-hover">
            <thead>
                <tr>
                    <th>Company</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Address</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Zip</th>
                    <th>Federal ID</th>
                    <th>Edit</th>
                    <th>Delete</th>
                </tr>
            </thead>
            <tbody>
                {!data.length ?
                    (
                        <tr>
                            <td colSpan={99} className="text-center">
                                Application does not have any corporate guarantors.
                            </td>
                        </tr>
                    )

                    : ''}
                {data.map(guarantor=>{
                    return (<CorporateGuarantor guarantor={guarantor} applicationId={applicationId}/>)
                })}
            </tbody>
        </table>
    )

}
export default List