import React from 'react';
import {useForm} from "react-hook-form";
import Axios from "../../../Mics/axiosInstance";
import CurrencyInput from "react-currency-input-field";
import {NotificationManager} from 'react-notifications';

export const BankStatement = ({application, statement}) => {
    const { register, handleSubmit } = useForm({mode: 'onBlur'});
    const [deposits, setDeposits] = React.useState(statement.deposits);
    const [withdrawals, setWithdrawals] = React.useState(statement.withdrawals);
    const [balance, setBalance] = React.useState(statement.balance);

    const save = (data) => {
        data.deposits = deposits
        data.withdrawals = withdrawals
        data.balance = balance
        Axios.put('/bank_statements/'+statement.id, data).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }

    const handleCurrencyChange = (value, name)=>{
        if(name === 'deposits'){
            setDeposits(value)
        }
        if(name === 'withdrawals'){
            setWithdrawals(value)
        }
        if(name === 'balance'){
            setBalance(value)
        }
    }

    return (
        <form onBlur={handleSubmit(save)}>
            <div className="row mb-4">
                <div className="col">
                    <label className="form-label">Bank Name</label>
                    <input ref={register} className="form-control" name="bank_name" type="text" defaultValue={statement.bank_name} />
                </div>
                <div className="col">
                    <label className="form-label">Period</label>
                    <input ref={register} className="form-control"  name="statement_date" type="date" defaultValue={statement.statement_date} />
                </div>
                <div className="col">
                    <label className="form-label">Deposits</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                        </div>
                        <CurrencyInput
                            className="form-control"
                            name="deposits"
                            placeholder="0.00"
                            defaultValue={statement.deposits}
                            precision={2}
                            onChange={handleCurrencyChange}
                        />

                    </div>
                </div>
                <div className="col">
                    <label className="form-label">Withdrawals</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                        </div>
                        <CurrencyInput
                            className="form-control"
                            name="withdrawals"
                            placeholder="0.00"
                            defaultValue={statement.withdrawals}
                            precision={2}
                            onChange={handleCurrencyChange}
                        />
                    </div>
                </div>
                <div className="col">
                    <label className="form-label">Ending Balance</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                        </div>
                        <CurrencyInput
                            className="form-control"
                            name="balance"
                            placeholder="0.00"
                            defaultValue={statement.balance}
                            precision={2}
                            onChange={handleCurrencyChange}
                        />
                    </div>
                </div>
            </div>
        </form>
    )
}

export default BankStatement
