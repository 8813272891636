import React, {useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import {useForm} from "react-hook-form";
import Axios from "../../Mics/axiosInstance";
import BusinessTypeSelect from '../../Inputs/BusinessTypeSelect'
import PhoneInput from "../../Inputs/PhoneInput";
import StatesSelector from "../../Inputs/StatesSelector";
import SSNInput from "../../Inputs/SSNInput";
import {NotificationManager} from 'react-notifications';

export const Edit = ({guarantor, open, toggle}) => {
    const { register, handleSubmit, errors } = useForm();
    const [isOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
        if(open){
            setIsOpen(true)
        }
    }, [open, setIsOpen]);

    const hideModal = () => {
        toggle()
        setIsOpen(false);
    };

    const saveGuarantor = (data) => {
        Axios.put('/personal_guarantors/'+guarantor.id, data).then(res => {
            return window.location.reload(false);
        }).catch(err=>{
            NotificationManager.error(`${err.key} ${err.message}`, 'Error saving')
        })
    };

    return (
        <Modal show={isOpen} onHide={hideModal} size="lg">
            <form onSubmit={handleSubmit(saveGuarantor)}>
                <Modal.Header>
                    <Modal.Title>Edit Personal Guarantor</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row mb-4">
                        <div className="col-8">
                            <label className="form-label">First Name <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.first_name ? "is-invalid" : ""}`} name="first_name"  defaultValue={guarantor.first_name} />
                        </div>
                        <div className="col-4">
                            <label htmlFor="middle_name" className="form-label">Middle Name</label>
                            <input ref={register} className={`form-control`} name="middle_name"  defaultValue={guarantor.middle_name} />
                        </div>
                        <div className="col-4">
                            <label htmlFor="last_name" className="form-label">Last Name<span className="required">*</span></label>
                            <input ref={register({required:true})} defaultValue={guarantor.last_name} name="last_name" className={`form-control ${errors.last_name ? "is-invalid" : ""}`} />
                        </div>
                    </div>

                    <hr/>

                    <div className="row mb-4">
                        <div className="col-5">
                            <label htmlFor="address" className="form-label">Address <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.address ? "is-invalid" : ""}`} id="address" name="address" defaultValue={guarantor.address} />
                        </div>
                        <div className="col">
                            <label htmlFor="city" className="form-label">City <span className="required">*</span></label>
                            <input ref={register({ required: true })} className={`form-control ${errors.city ? "is-invalid" : ""}`} id="city" name='city' defaultValue={guarantor.city} />
                        </div>
                        <div className="col">
                            <label htmlFor="state" className="form-label">State </label>
                            <StatesSelector register={register({ required: true })} name="state"  value={guarantor.state} />
                        </div>
                        <div className="col">
                            <label htmlFor="zip" className="form-label">Zip <span className="required">*</span></label>
                            <input ref={register({ required: true })} maxLength={5} className={`form-control ${errors.zip ? "is-invalid" : ""}`} id="zip" name='zip' defaultValue={guarantor.zip} />
                        </div>

                    </div>

                    <hr/>

                    <div className="row mb-4">
                        <div className="col">
                            <label className="form-label">Email</label>
                            <input ref={register} className="form-control" name="email" defaultValue={guarantor.email} />
                        </div>
                        <div className="col">
                            <label className="form-label">SSN</label>
                            <SSNInput register={register} name='ssn'  value={guarantor.ssn} />
                        </div>
                        <div className="col">
                            <label className="form-label">Date of Birth</label>
                            <input ref={register} type="date" className="form-control" name="date_of_birth"  defaultValue={guarantor.date_of_birth} />
                        </div>
                        
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={(e) => {e.preventDefault(); hideModal()}}>Cancel</button>
                    <button className="btn btn-primary" type="submit">Save personal Guarantor</button>
                </Modal.Footer>
            </form>
        </Modal>
    )

}
export default Edit