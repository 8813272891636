import React from 'react';
import Axios from "../../Mics/axiosInstance";
import {useParams} from "react-router-dom";
import {NotificationManager} from 'react-notifications';
import FederalID from "../../Helpers/FederalID";
import Phone from "../../Helpers/Phone";

export const Select = ({company}) => {
    let { applicationId } = useParams();

    const saveToApplication = () => {
        var data = {
            applicant_id: company.id
        }

        Axios.put('/applications/'+applicationId, data).then(() => {
            return window.location.reload(false);
        })
        .catch((err) => {
            NotificationManager.error('Please try again', 'Error saving')
        })
    }

    return (
        <tr className="applicant-select" onClick={saveToApplication}>
            <td>
                {company.id}
            </td>
            <td>
                {company.company_name}
            </td>
            <td>
                {company.dba_name}
            </td>
            <td>
                <Phone number={company.phone_number}/>
            </td>
            <td>
                <FederalID id={company.federal_id}/>
            </td>
        </tr>
    )

}
export default Select

