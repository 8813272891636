import React from 'react';
import axios from '../Mics/axiosInstance';
import { useForm } from "react-hook-form";
import { useQuery } from 'react-query';
import PhoneInput from "../Inputs/PhoneInput";
import StatesSelector from "../Inputs/StatesSelector";
import { NotificationManager } from 'react-notifications';

function useSourceContact(id) {
    return useQuery(['source-contact', id], async () => {
        const { data } = await axios.get(`/sources/${id}/source_contacts`);
        return data;
    })
}

export const Edit = ({ sourceId }) => {
    var { data, isLoading } = useSourceContact(sourceId)
    const { register, handleSubmit } = useForm();

    const save = (formData) => {
        if (data) {
            axios.put(`/sources/${sourceId}/source_contacts/${data.id}`, formData).then(() => {
                NotificationManager.success('Source Contact has been updated', 'Success')
            }).catch((error) => {
                NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
            })
        } else {
            axios.post(`/sources/${sourceId}/source_contacts`, formData).then((response) => {
                data = response.data
                NotificationManager.success('Source Contact has been added', 'Success')
            }).catch((error) => {
                NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
            })
        }
    }

    if (isLoading || data === undefined) {
        return <h1>Loading Contact</h1>
    }

    return (
        <div className="card mb-4">
            <div className="card-header">
                Source Contact
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit(save)} >
                    <div className='row mb-4'>
                        <div className="col-6">
                            <label className="form-label">First Name</label>
                            <input ref={register} className="form-control" name="first_name" type='text' defaultValue={data?.first_name} />
                        </div>
                        <div className="col-6">
                            <label className="form-label">Last Name</label>
                            <input ref={register} className="form-control" name="last_name" type='text' defaultValue={data?.last_name} />
                        </div>
                    </div>

                    <div className='row mb-4'>
                        <div className="col-4">
                            <label className="form-label">Phone Number</label>
                            <PhoneInput register={register} name="phone_number" value={data?.phone_number} />
                        </div>
                        <div className="col-4">
                            <label className="form-label">Extension</label>
                            <input ref={register} className="form-control" name="extension" type="number" defaultValue={data?.extension} />
                        </div>
                        <div className="col-4">
                            <label className="form-label">Email</label>
                            <input ref={register} className="form-control" name="email" type="email" defaultValue={data?.email} />
                        </div>
                    </div>

                    <hr />

                    <div className="row mb-4">
                        <div className="col-6">
                            <label className="form-label">Address</label>
                            <input ref={register} className="form-control" name="address" type="text" defaultValue={data?.address} />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-4">
                            <label className="form-label">City</label>
                            <input ref={register} className="form-control" name="city" type="text" defaultValue={data?.city} />
                        </div>
                        <div className="col-4">
                            <label className="form-label">State</label>
                            <StatesSelector register={register} value={data?.state} name="state" />
                        </div>
                        <div className="col-4">
                            <label className="form-label">Zip</label>
                            <input ref={register} className="form-control" name='zip]' defaultValue={data?.zip} />
                        </div>
                    </div>

                    <hr />

                    <button className="btn btn-primary float-right" type='submit'>Submit</button>
                </form>
            </div >
        </div >
    )

}

export default Edit;