import React from 'react';
import Axios from "../../Mics/axiosInstance";
import Item from "./Item";
import {useQuery} from "react-query";

function useVendors(applicationId) {
    return useQuery("get-vendors-by-application", async () => {
        const { data } = await Axios.get('/applications/'+applicationId+'/vendors');
        return data;
    });
}

export const List = ({applicationId}) => {
    const { data, isLoading } = useVendors(applicationId);

    if(isLoading){
        return <div>Loading</div>
    }

    return (
        <table className="table table-hover">
            <thead>
                <tr>
                    <th>Company Name</th>
                    <th>DBA</th>
                    <th>Address</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Zip</th>
                    <th>Edit</th>
                    <th>Delete</th>
                </tr>
            </thead>
            <tbody>
            {!data.length ?
                <tr>
                    <td className="text-center" colSpan={99}>
                        No vendors have been added to this application
                    </td>
                </tr>
                :
                ''
            }
            {data.map(vendor=>{
                    return (<Item vendor={vendor} applicationId={applicationId} />)
                })}
            </tbody>
        </table>
    )

}
export default List