import React from 'react';
import Roles from "../Components/Config/Roles";

export const Role = ({children, roles}) => {
    const allowedRoles = [Roles.DOCUMENT, Roles.SALES, Roles.CREDIT, Roles.USER, Roles.ADMIN, Roles.REPORTS]
    let canView = false
    let localRoles = localStorage.getItem('peak-roles')

    if(localRoles){
        localRoles = JSON.parse(localRoles)
        if(localRoles.includes(Roles.ADMIN)){
            canView = true
        }else{
            canView = localRoles.some(role=> roles.includes(role.toUpperCase()))
        }
    }

    // Check to make sure the list of roles given is in the allowed list
    roles.forEach((role) => {
        if(!allowedRoles.includes(role.toUpperCase())){
            console.log('You are passing the role ['+role+'] that is not allowed')
        }
    })

    if(canView){
        return <>{children}</>
    }

    return ''
}

export default Role