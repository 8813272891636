import React from 'react';
import Money from '../../Helpers/Money'

export const View = ({application}) => {

    if(!application.equipment_cost){
        return (
            <div>
                <div className='row mt-4'>
                    <div className="col text-center">
                        Application does not have any details.
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className='row mb-4'>
                <div className="col">
                    <p>Equipment Cost:</p>
                    <strong><Money amount={application.equipment_cost} /></strong>
                </div>
                <div className="col">
                    <p>Requested Cost:</p>
                    <strong><Money amount={application.submitted_amount}/></strong>
                </div>
                <div className="col">
                    <p>Requested Terms:</p>
                    <strong>{application.requested_terms}</strong>
                </div>
            </div>

            <hr/>

            <div className='row mb-4'>
                <div className="col">
                    <p>Frequency:</p>
                    <strong>{application.requested_frequency}</strong>
                </div>
                <div className="col">
                    <p>Requested Advanced:</p>
                    <strong>{application.requested_advance}</strong>
                </div>
            </div>

            <hr/>

            <div className='row mb-4'>
                <div className="col">
                    <p>Collateral Description:</p>
                    <strong>{application.collateral_description}</strong>
                </div>
                <div className="col">
                    <p>Purpose of Loan:</p>
                    <strong>{application.purpose_of_loan}</strong>
                </div>
            </div>

            <hr/>

            <div className='row mb-4'>
                <div className="col">
                    <p>Business Development Officer:</p>
                    <strong>{application.bdo_user?.name}</strong>
                </div>
            </div>
        </div>
    )

}
export default View