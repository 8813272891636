import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import Axios from "../Mics/axiosInstance";
import CurrencyInput from "../Inputs/CurrencyInput";
import { useForm } from "react-hook-form";
import { NotificationManager } from 'react-notifications';

export const VendorPrefundingItem = ({ prefunding, triggerChange }) => {
    const { register, handleSubmit, setValue } = useForm({ mode: 'onBlur' });

    const save = (formData) => {
        prefunding.amount = formData.amount
        if (prefunding.id) {
            Axios.put('/documents/' + prefunding.document_id + '/prefundings/' + prefunding.id, prefunding).then((res) => {
                prefunding.id = res.data.id
            }).catch((error) => {
                NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
            })
        } else {
            Axios.post('/documents/' + prefunding.document_id + '/prefundings', prefunding).then((res) => {
                prefunding.id = res.data.id
            }).catch((error) => {
                NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
            })
        }
    }

    const confirmRemoveItem = () => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to remove this item?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: removeItem
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const removeItem = () => {
        if (prefunding.id) {
            Axios.delete('/documents/' + prefunding.document_id + '/prefundings/' + prefunding.id).then((res) => {
                triggerChange(prefunding)
            }).catch((error) => {
                NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
            })
        } else {
            triggerChange(prefunding)
        }
    }

    return (
        <div className="mb-2">
            <form onBlur={handleSubmit(save)}>
                <i className="fas fa-trash pointer float-right ml-0" title="Remove this item" onClick={confirmRemoveItem} style={{ marginTop: '10px', marginLeft: '-0px', color: 'red' }} />
                <CurrencyInput
                    name="amount"
                    value={prefunding.amount}
                    setValue={setValue}
                    register={register}
                    style={{ width: '95%' }}
                />
            </form>
        </div>
    )
}

export default VendorPrefundingItem
