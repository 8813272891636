import React, { useState } from 'react';
import Edit from "./Edit";
import { confirmAlert } from "react-confirm-alert";
import Axios from "../Mics/axiosInstance";
import Date from "../Helpers/Date";
import { NotificationManager } from 'react-notifications';

export const Item = ({ item, triggerReload, contractNumber }) => {
    const [modalState, setModalState] = useState(false)

    const confirmRemoveItem = () => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to remove this item?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: removeItem
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const removeItem = () => {
        Axios.delete('/documents/' + contractNumber + '/followups/' + item.id).then((res) => {
            triggerReload()
        }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }

    const toggleModal = () => {
        setModalState(!modalState)
    }

    return (
        <>
            <tr>
                <td>
                    {item.task}
                </td>
                <td>
                    <Date date={item.completed_date} />
                </td>
                <td>
                    <Date date={item.created_at} time />
                </td>
                <td>
                    {item.created_user.name}
                </td>
                <td>
                    <i className="fas fa-edit mr-2 pointer" title="Edit this item" onClick={toggleModal} />
                    <i className="fas fa-trash pointer" title="Remove this item" onClick={confirmRemoveItem} />
                </td>
            </tr>
            <Edit modalState={modalState} toggleModal={toggleModal} contractNumber={contractNumber} triggerReload={triggerReload} item={item} />
        </>
    )

}
export default Item