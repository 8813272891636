import React, {useState} from 'react';
import {Redirect} from "react-router-dom";

export const Update = () => {
    const [type, setType] = useState(null)
    const [applicationId, setApplicationId] = useState(null)
    const [go, setGo] = useState(false)

    const handleChange = (event) => {
        setApplicationId(event.target.value)
    }

    const goTo = () => {
        setGo(true)
    }

    if(go === true && applicationId !== null){
        if(type === 'status'){
            return (<Redirect to={`/application-status/${applicationId}`}/>)
        }
        if(type === 'company'){
            return (<Redirect to={`/applications/${applicationId}/applicant`}/>)
        }
        if(type === 'personal_guarantors'){
            return (<Redirect to={`/applications/${applicationId}/personal-guarantors`}/>)
        }
        if(type === 'vendor'){
            return (<Redirect to={`/applications/${applicationId}/vendor`}/>)
        }
    }

    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        Sales - Update
                    </div>
                    <div className="card-body">

                        {type !== null ?
                            <form>
                                Application ID:
                                <div className="row mb-3">
                                    <div className="col-2">
                                        <input name="application_id" className="form-control" onChange={handleChange} />
                                    </div>
                                    <div className="col-10">
                                        <button className="btn btn-primary mr-3" onClick={goTo} type="submit">
                                            <i className="fas fa-search"/> Search
                                        </button>
                                        <button name='Status' className="btn btn-danger" onClick={() => setType(null)} >Reset</button>
                                    </div>
                                </div>
                            </form>
                            :
                            <div>
                                <button name='Status' className="btn btn-primary" onClick={() => setType('status')} >Status</button>&nbsp;&nbsp;
                                {/* <button name='Company' className="btn btn-primary" onClick={() => setType('company')} >Company</button>&nbsp;&nbsp; */}
                                <button name='PG' className="btn btn-primary" onClick={() => setType('personal_guarantors')} >Personal Guarantors</button>&nbsp;&nbsp;
                                <button name='Vendor' className="btn btn-primary" onClick={() => setType('vendor')} >Vendor</button>&nbsp;&nbsp;
                            </div>
                        }



                    </div>
                </div>
            </div>
        </div>
    )
}

export default Update
