import React, {useEffect, useState} from 'react';

export const Phone = ({number}) => {
    const [phone, setPhone] = useState('');

    useEffect(() => {
        format(number)
    });

    const format = () => {
        if(number !== undefined && number !== '' && number !== null){
            number = number.replace(/[^\d]/g, '')
            setPhone('('+number.substring(0, 3) + ') ' + number.substring(3, 6) + '-' + number.substring(6,10))
        }
    }
    return (<>{phone}</>)
}
export default Phone
