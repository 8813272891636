import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { NotificationManager } from 'react-notifications';
import BusinessTypeSelect from '../Inputs/BusinessTypeSelect'
import SourceTypeSelector from "../Inputs/SourceTypeSelector";
import FederalTaxIdInput from "../Inputs/FederalTaxIdInput";
import API from "../../API";

export const Add = () => {
    const { register, handleSubmit } = useForm()
    const [redirect, setRedirect] = useState(false)

    const save = (formData) => {
        API.createSource(formData).then(() => {
            NotificationManager.success('Success', 'Source has been added')
            setRedirect(true)
        }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }

    if (redirect) {
        return (<Redirect to={`/sources/`} />)
    }

    return (
        <div className="row">
            <div className="col-md">
                <div className="card mb-4">
                    <div className="card-header">
                        Update Source Profile
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit(save)}>
                            <div className="row mb-3">
                                <div className="col-4">
                                    <label className="form-label">Company Name</label>
                                    <input ref={register} name="company_name" type='text' className="form-control" />
                                </div>
                                <div className="col-4">
                                    <label className="form-label">Internal Name</label>
                                    <input ref={register} name="internal_name" type='text' className="form-control" />

                                </div>
                                <div className="col-4">
                                    <label className="form-label">DBA Name</label>
                                    <input ref={register} name="dba_name" type='text' className="form-control"/>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-4">
                                    <label className="form-label">Type</label>
                                    <SourceTypeSelector register={register}/>
                                </div>
                                <div className="col-4">
                                    <label className="form-label">CFL</label>
                                    <select ref={register} name="cfl" className="form-control">
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                </div>
                                <div className="col-4">
                                    <label className="form-label">CFL Number</label>
                                    <input ref={register} className="form-control" name="cfl_number"/>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-4">
                                    <label className="form-label">Federal ID</label>
                                    <FederalTaxIdInput register={register} name="federal_id" />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="sos-type" className="form-label">Business Type</label>
                                    <BusinessTypeSelect register={register} />
                                </div>
                                <div className="col-4">
                                    <label className="form-label">Application Received</label>
                                    <input ref={register} className="form-control" name="application_received" type="date" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-4">
                                    <label className="form-label">Agreement Date</label>
                                    <input ref={register} className="form-control" name="agreement_date" type="date" />

                                </div>
                                <div className="col-4">
                                    <label className="form-label">Peak Reference</label>
                                    <input ref={register} className="form-control" name="peak_reference" type="text" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-4">
                                    <label>Approved</label>
                                    <select ref={register} name="approved" className="form-control" >
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                </div>

                                <div className="col-4">
                                    <label>Approval Date</label>
                                    <input ref={register} className="form-control" name="approval_date" type='date' />
                                </div>
                            </div>
                            <button className="btn btn-success" type="submit" >Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Add