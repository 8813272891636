import React, {useState} from 'react';
import {Redirect, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import Axios from "../Mics/axiosInstance";
import FollowupList from "../Followups/List";
import {useForm} from "react-hook-form";
import { NotificationManager } from 'react-notifications';

function useDocument(contractNumber) {
    return useQuery(["document", contractNumber], async () => {
        const { data } = await Axios.get("/documents/"+contractNumber);
        return data;
    });
}

export const StatusUpdate = () => {
    const { contractNumber } = useParams()
    const { data, isLoading } = useDocument(contractNumber);
    const { register, handleSubmit } = useForm({mode: 'onBlur'});

    const save = (formData) => {
        Axios.put('/applications/'+data.application_id+'/documents/'+data.contract_number, formData).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }

    if(isLoading){
        return <div>Loading Credit Application</div>
    }

    if(!isLoading && Object.keys(data).length === 0){
        return <div className="alert alert-danger">Contract Number does not exist</div>
    }

    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        Documentation - Status - Application ID: {data.id} - Contract Number: {data.contract_number}
                    </div>
                    <div className="card-body">
                        <form onBlur={handleSubmit(save)}>

                            <div className="row mb-4">
                                <div className="col">
                                    Docs Sent Out
                                    <input className="form-control" type="date" name="docs_sent_out" ref={register} defaultValue={data.docs_sent_out}/>
                                </div>
                                <div className="col">
                                    Redoc
                                    <select className="form-control" name='redoc' ref={register} defaultValue={data.redoc}>
                                        <option value='true'>Yes</option>
                                        <option value='false'>No</option>
                                    </select>
                                </div>
                                <div className="col">
                                    Docs Received
                                    <input className="form-control" type="date" name="docs_received" ref={register} defaultValue={data.docs_received}/>
                                </div>
                            </div>

                            <hr/>

                            <div className="row mb-4">
                                <div className="col">
                                    Inspection Completed
                                    <input className="form-control" type="date" name="inspection_completed" ref={register} defaultValue={data.inspection_completed}/>
                                </div>
                                <div className="col">
                                    UCC Recorded Date
                                    <input className="form-control" type="date" name="ucc_recorded" ref={register} defaultValue={data.ucc_recorded}/>
                                </div>
                                <div className="col">
                                    UCC Fixture Filing
                                    <input className="form-control" type="date" name="ucc_fixture" ref={register} defaultValue={data.ucc_fixture}/>
                                </div>
                            </div>

                            <hr/>

                            <div className="row mb-4">
                                <div className="col-4">
                                    Status
                                    <select className="form-control" name='status' ref={register} defaultValue={data.status}>
                                        <option selected disabled></option>
                                        <option value='Funded'>Funded</option>
                                        <option value='Prefunding'>Prefunding</option>
                                        <option value='Docs Sent'>Docs Sent</option>
                                        <option value='Inspection'>Inspection</option>
                                        <option value='Docs Received'>Docs Received</option>
                                        <option value='Dead'>Dead</option>
                                    </select>
                                </div>
                                <div className="col-4">
                                    Status Date
                                    <input className="form-control" type="date" name="status_date" ref={register} defaultValue={data.status_date}/>
                                </div>
                            </div>
                        </form>


                    </div>
                </div>

                <hr/>
                <FollowupList contractNumber={data.contractNumber}/>

            </div>
        </div>
    )
}

export default StatusUpdate
