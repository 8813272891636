import React from 'react'

export const BooleanSelector = ({register, name, value, id, className}) => {
    if(register === undefined || register === ''){
        console.log("register param must be set when using <BooleanSelector register={register}..., You must pass this in from useForm")
    }

    if(name === undefined || name === ''){
        console.log("name param must be set when using <BooleanSelector name=''...")
    }

    if(id === undefined || id === ''){
        id = name
    }

    if(className === undefined || className === ''){
        className = 'form-control'
    }

    return (
        <select ref={register} className={className} name={name} id={id} defaultValue={value}>
            <option value="" selected disabled/>
            <option value="true">Yes</option>
            <option value="false">No</option>
        </select>
    )
}

export default BooleanSelector