import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Axios from "../../Mics/axiosInstance";
import SelectRow from "./SelectRow";

export const Select = ({applicationId, hideModal, setType}) => {
    const [loading, setLoading] = useState(true);
    const [corporateGuarantors, setCorporateGuarantors] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if(loading) {
            Axios.get("/corporate_guarantors").then(res=>{
                setCorporateGuarantors(res.data)
                setLoading(false);
            })
        }
    }, [loading, setLoading, corporateGuarantors, setCorporateGuarantors]);
    let filteredGuarantor = corporateGuarantors.filter(cg => String(cg.company_name).toLocaleLowerCase().indexOf(String(search).toLocaleLowerCase())!==-1)
    return (
        <div>
            <Modal.Header>
                <Modal.Title>Select a corporate guarantor from the list below</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="row mb-4">
                    <div className="col">
                        <button className="btn btn-success float-right" onClick={setType}>
                            <i className="fas fa-plus"/> Add New corporate Guarantor
                        </button>
                    </div>
                </div><div className="row mb-4">
                    <div className="col-3">
                        <lable className="form-label">Search Company Name</lable>
                        <input className="form-control" name="search" defaultValue={search} onChange={(e) => setSearch(e.target.value)} />
                    </div>
                </div>

                <div className="row" style={{'maxHeight': '500px', 'overflow': 'scroll'}}>
                    <div className="col">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Company Name</th>
                                <th>DBA Name</th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredGuarantor.map(corporate_guarantor=>{
                                if(corporate_guarantor.company_name){
                                    return (<SelectRow applicationId={applicationId} corporate_guarantor={corporate_guarantor}/>)
                                }
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>


            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-danger" onClick={hideModal}>Cancel</button>
            </Modal.Footer>
        </div>
    )

}
export default Select