import React, {useState} from 'react';
import Money from "../Helpers/Money";
import Edit from "./Edit";
import {confirmAlert} from "react-confirm-alert";
import Axios from "../Mics/axiosInstance";
import { NotificationManager } from 'react-notifications';

export const Item = ({equipment, triggerReload, document}) => {
    const [modalState, setModalState] = useState(false)

    const confirmRemoveItem = () => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to remove this item?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: removeItem
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const removeItem = () => {
        Axios.delete('/documents/'+document.contract_number+'/equipments/'+equipment.id).then((res) => {
            triggerReload()
        }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }

    const toggleModal = () => {
        setModalState(!modalState)
    }

    return (
        <>
            <tr>
                <td>
                    {equipment.description}
                </td>
                <td>
                    {equipment.vendor.company_name}
                </td>
                <td>
                    <Money amount={equipment.cost}/>
                </td>
                <td>
                    {equipment.serial_number}
                </td>
                <td>
                    {equipment.vin_number}
                </td>
                <td>
                    {equipment.address}
                </td>
                <td>
                    {equipment.city}
                </td>
                <td>
                    {equipment.state}
                </td>
                <td>
                    {equipment.zip}
                </td>
                <td>
                    <i className="fas fa-edit mr-2 pointer" title="Edit this item" onClick={toggleModal}/>
                    <i className="fas fa-trash pointer" title="Remove this item" onClick={confirmRemoveItem}/>
                </td>
            </tr>
            <Edit modalState={modalState} toggleModal={toggleModal} document={document} triggerReload={triggerReload} equipment={equipment}/>
        </>
    )

}
export default Item