import React from 'react'
import StateOptions from '../../Components/SelectOptions/StateOptions'

export const StatesSelector = ({register, name, value, id, className}) => {
    if(register === undefined || register === ''){
        console.log("register param must be set when using <StatesInput register={register}..., You must pass this in from useForm")
    }

    if(name === undefined || name === ''){
        console.log("name param must be set when using <StatesInput name=''...")
    }

    if(id === undefined || id === ''){
        id = name
    }

    if(className === undefined || className === ''){
        className = 'form-control'
    }

    return (
        <select ref={register} className={className} name={name} id={id} defaultValue={value}>
            <StateOptions/>
        </select>
    )
}

export default StatesSelector