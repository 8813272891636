
import React from 'react';
import ReactDOM from 'react-dom'
import './Theme/css/vendor.css';
import './Theme/css/theme.css';
import './Theme/css/custom.css';

import { Root } from './Components/Navigation/Root';
ReactDOM.render(
    <React.StrictMode>
        <Root />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();