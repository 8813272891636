import React from 'react';
import API from "../../API";

export const SourceTypeSelector = ({value, register}) => {
    const { data, isLoading } = API.useSourceTypes()

    if (isLoading || data === undefined) {
        return (<></>)
    }

    if (data.length) {
        return (
            <select ref={register} name="source_type_id" defaultValue={value} className="form-control" id="source_type_id">
                <option value="" disabled selected>Select source type</option>
                {data.map(option => {
                    return (<option value={option.id}>{option.name}</option>)
                })}
            </select>
        )
    }
}
export default SourceTypeSelector