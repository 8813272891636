import React from 'react';
import Billing from '../Billing'
import { useQuery } from "react-query";
import Axios from "../../Mics/axiosInstance";
import MailMerge from '../MailMerge'

function useApplication(id) {
    return useQuery(["applications", id], async () => {
        const { data } = await Axios.get("/applications/" + id);
        return data;
    });
}

export const ApplicantBilling = ({ document, triggerReload }) => {
    const { data, isLoading } = useApplication(document.application_id)

    if (isLoading || data === undefined) {
        return <div>Loading information</div>
    }

    return (
        <div>
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-header">
                            Applicant Billing for <strong>{data.applicant.company_name}</strong>
                        </div>
                        <div className="card-body">
                            <Billing type="applicant" typeId={data.applicant.id} contractNumber={document.contract_number} triggerReload={triggerReload} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <MailMerge document={document} application={data}/>
                </div>
            </div>
        </div>
    )
}

export default ApplicantBilling
