import React, { useState } from 'react';
import { confirmAlert } from "react-confirm-alert";
import Axios from "../Mics/axiosInstance";
import Edit from "./Edit";
import Money from '../Helpers/Money'
import Date from '../Helpers/Date'
import { NotificationManager } from 'react-notifications';

export const Item = ({ prefunding, vendor, document, application, triggerReload }) => {
    const [modalState, setModalState] = useState(false)

    const confirmRemoveItem = () => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you want to remove this item?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: removeItem
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    const removeItem = () => {
        Axios.delete('/documents/' + document.contract_number + '/prefundings/' + prefunding.id).then((res) => {
            triggerReload()
        }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }

    const toggleModal = () => {
        setModalState(!modalState)
    }

    return (
        <>
            <tr>
                <td><Money amount={prefunding.amount} /></td>
                <td><Date date={prefunding.release_date} /></td>
                {application.source.is_dfny ?
                    <>
                        <td><Money amount={prefunding.doc_fee} /></td>
                        <td><Money amount={prefunding.vendor_commission} /></td>
                    </>
                    :
                    ''
                }

                <td>
                    <i className="fas fa-edit mr-2 pointer" title="Edit this item" onClick={toggleModal} />
                    <i className="fas fa-trash pointer" title="Remove this item" onClick={confirmRemoveItem} />
                </td>
            </tr>
            <Edit modalState={modalState} toggleModal={toggleModal} vendor={vendor} document={document} triggerReload={triggerReload} prefunding={prefunding} application={application} />
        </>
    )
}

export default Item
