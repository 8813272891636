import React, {useState} from 'react';
import {useQuery, useQueryClient} from "react-query";
import Axios from "../Mics/axiosInstance";
import Add from "./Add";
import Item from "./Item";

function useDefaultBillings(type, typeId) {
    return useQuery(["defaultBillings", type, typeId], async () => {
        const { data } = await Axios.get("/billing_defaults?type="+type+"&type_id="+typeId);
        return data;
    });
}

export const List = ({type, typeId}) => {
    const { data, isLoading } = useDefaultBillings(type, typeId);
    const [modalState, setModalState] = useState(false)
    const queryClient = useQueryClient();

    const triggerReload = () => {
        queryClient.refetchQueries(["defaultBillings", type, typeId])
    }

    const toggleModal = () => {
        setModalState(!modalState)
    }

    if (isLoading) {
        return <div>Loading Default Billing Information</div>
    }

    return (
        <div className="card mb-5">
            <div className="card-header">
                {data.allowed_billing_methods.length ?
                    <button className="btn btn-primary float-right" onClick={toggleModal}>
                        <i className="fas fa-plus"/> Add Billing
                    </button>
                    :
                    ''
                }

                Default Billing Information
            </div>

            <div className="card-body">

                {!data.allowed_billing_methods.length ?
                    <div className="alert alert-danger">
                        You have already assigned all types available as you can only have 1 of each billing method assigned to this entity. If you would like to edit or add a new one you must first delete the type you would like to add.
                    </div>
                    :
                    ''
                }

                <table className="table">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Account Holder</th>
                            <th>Address</th>
                            <th>Bank Name</th>
                            <th>Routing #</th>
                            <th>Account #</th>
                            <th>Comment</th>
                            <th>Created At</th>
                            <th>Created By</th>
                        </tr>
                    </thead>
                    <tbody>
                    {!data.billing_defaults.length ?
                        <tr>
                            <td className="text-center" colSpan={99}>
                                No billing defaults have been added
                            </td>
                        </tr>
                        :
                        ''
                    }

                    {data.billing_defaults.map(item => {
                        return (
                            <Item item={item} triggerReload={triggerReload}/>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            <Add modalState={modalState} toggleModal={toggleModal} allowedBillingMethods={data.allowed_billing_methods} type={type} typeId={typeId} triggerReload={triggerReload}/>
        </div>
    )
}
export default List