import React from "react";
import Axios from "../../Mics/axiosInstance";

export const SelectRow = ({applicationId, personal_guarantor}) => {

    const saveToApplication = () => {
        Axios.post('/applications/'+applicationId+'/attach_personal_guarantor/'+personal_guarantor.id).then(() => {
            return window.location.reload(false);
        })
    }

    return (
        <tr className="applicant-select" onClick={saveToApplication}>
            <td>{personal_guarantor.first_name + ' '+ personal_guarantor.last_name}</td>
            <td>{personal_guarantor.ssn}</td>
        </tr>
    )

}
export default SelectRow
