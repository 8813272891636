import React from 'react';
import CorporateGuarantor from "./CorporateGuarantor";
import PersonalGuarantor from "../PersonalGuarantors/PersonalGuarantor";

export const List = ({application}) => {
    return (
        <div>
            {application.corporate_guarantors.map(guarantor=>{
                return (<div className='well-inverted'><CorporateGuarantor guarantor={guarantor}/></div>)
            })}
        </div>
    )
}
export default List
