import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import Axios from "../../Mics/axiosInstance";
import SelectRow from "./SelectRow";

export const Select = ({ applicationId, hideModal, setType }) => {
    const [loading, setLoading] = useState(true);
    const [vendors, setVendors] = useState([]);
    const [vendor, setVendor] = useState(false);
    const [search, setSearch] = useState('')

    useEffect(() => {
        if (loading) {
            Axios.get("/vendors").then(res => {
                setVendors(res.data)
                setLoading(false);
            })
        }
    }, [loading, setLoading, vendors, setVendors]);
    let filtereVendor = vendors.filter(vendor => String(vendor.company_name).toLowerCase().indexOf(String(search).toLowerCase()) !== -1)

    return (
        <div>
            <Modal.Header>
                <Modal.Title>Select a vendor from the list below</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mb-4">
                    <div className="col-3">
                        <lable className="form-label">Company Name</lable>
                        <input className="form-control" name="search" defaultValue={search} onChange={(e) => setSearch(e.target.value)} />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col">
                        <button className="btn btn-success float-right" onClick={setType}>
                            <i className="fas fa-plus" /> Add New Vendor
                        </button>
                    </div>
                </div>

                <div className="row" style={{ 'maxHeight': '500px', 'overflow': 'scroll' }}>
                    <div className="col">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Company</th>
                                    <th>Address</th>
                                    <th>DBA</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filtereVendor.map(vendor => {
                                    if (vendor.company_name) {
                                        return (<SelectRow applicationId={applicationId} vendor={vendor} />)
                                    }
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>


            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-danger" onClick={hideModal}>Cancel</button>
            </Modal.Footer>
        </div>
    )

}
export default Select