import React from "react";
import Axios from "../../Mics/axiosInstance";

export const SelectRow = ({applicationId, corporate_guarantor}) => {

    const saveToApplication = () => {
        Axios.post('/applications/'+applicationId+'/attach_corporate_guarantor/'+corporate_guarantor.id).then(() => {
            return window.location.reload(false);
        })
    }

    return (
        <tr className="applicant-select" onClick={saveToApplication}>
            <td>{corporate_guarantor.company_name}</td>
            <td>{corporate_guarantor.dba_name}</td>
        </tr>
    )

}
export default SelectRow
