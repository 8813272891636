import React, {useState} from 'react';
import {Redirect} from "react-router-dom";

export const Search = () => {
    const [contractNumber, setContractNumber] = useState(null)
    const [go, setGo] = useState(false)

    const handleChange = (event) => {
        setContractNumber(event.target.value)
    }

    const goTo = () => {
        setGo(true)
    }

    if(go === true && contractNumber !== null){
        return (<Redirect to={`/disbursements/${contractNumber}`}/>)
    }

    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        Document Lookup
                    </div>
                    <div className="card-body">
                        <form>
                            Contract Number
                            <div className="row">
                                <div className="col-md-3">
                                    <input className="form-control" type="number" name="contract_number" onChange={handleChange}/>
                                </div>
                                <div className="col-md-6">
                                    <button className='btn btn-primary' onClick={goTo}>Search</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Search
