import React from 'react';
import { useForm } from "react-hook-form";
import Axios from "../../Mics/axiosInstance";
import StatesSelector from "../../Inputs/StatesSelector";
import FederalTaxIdInput from "../../Inputs/FederalTaxIdInput";
import { NotificationManager } from 'react-notifications';

export const Applicant = ({ application, triggerReload }) => {
    const { register, handleSubmit } = useForm({ mode: 'onBlur' });

    const save = (data) => {
        Axios.put('/applicants/' + application.applicant.id, data).then(()=>triggerReload()).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }
    return (
        <form onBlur={handleSubmit(save)}>
            <div className="row mb-4">
                <div className="col">
                    Company Name
                    <input className="form-control" type="text" name="company_name" ref={register} defaultValue={application.applicant.company_name} />
                </div>
                <div className="col">
                    DBA
                    <input className="form-control" type="text" name="dba_name" ref={register} defaultValue={application.applicant.dba_name} />
                </div>
            </div>

            <hr />

            <div className="row mb-4">
                <div className="col-3">
                    Federal ID
                    <FederalTaxIdInput register={register} value={application.applicant.federal_id} name='federal_id' />
                </div>
            </div>

            <hr />

            <div className="row mb-4">
                <div className="col">
                    Address
                    <input className="form-control" type="text" name="address" ref={register} defaultValue={application.applicant.address} />
                </div>
                <div className="col">
                    City
                    <input className="form-control" type="text" name="city" ref={register} defaultValue={application.applicant.city} />
                </div>
                <div className="col">
                    State
                    <StatesSelector value={application.applicant.state} name="state" register={register} />
                </div>
                <div className="col">
                    Zip
                    <input className="form-control" type="text" name="zip" maxLength={5} ref={register} defaultValue={application.applicant.zip} />
                </div>
            </div>
        </form>
    )
}

export default Applicant
