import React from 'react';
import {CSVLink} from "react-csv";
import docExportCSV from "../Mics/docExportCsv";

export const MailMerge = ({document, application}) => {
    return (
        <div className="row mb-5">
            <div className="col">
                <CSVLink headers={docExportCSV.headers(docExportCSV.body(document,application))} data={[docExportCSV.body(document, application)]} className="btn btn-primary">Mail Merge Download</CSVLink>
            </div>
        </div>
    )
}

export default MailMerge
