import React from 'react';
import Axios from "../Mics/axiosInstance";
import { Link, useParams } from "react-router-dom";
import Money from "../Helpers/Money";
import { useForm } from "react-hook-form";
import { NotificationManager } from 'react-notifications';
import API from "../../API";

export const ScoreCard = () => {
    const { id } = useParams()
    const application = API.useApplication(id);
    const scoreCard = API.useScoreCard(id);
    const { register, handleSubmit } = useForm({ mode: 'onBlur' });

    const save = () => {
        Axios.put('/credits/' + application.data.credit_id, application.data).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }
    const averageFico = () => {
        return scoreCard.data.average_fico
    }
    const lowestFico = () =>{
        return scoreCard.data.lowest_fico
    }
    const sbssScore = () =>{
        return scoreCard.data.sbss
    }
    const paynetScore = () =>{
        return scoreCard.data.paynet_score
    }
    const compCredit = () =>{
        return scoreCard.data.comp_credit
    }
    const averageBankStatements = () => {
        return scoreCard.data.average_bank_balance
    }
    const paymentCoverage = () => {
        return scoreCard.data.payment_coverage
    }
    const timeInBusiness = () => {
        return scoreCard.data.time_in_business
    }

    if (application.isLoading || scoreCard.isLoading) {
        return <div>Loading Credit Application and Scorecard</div>
    }

    return (
        <div className="row">
            <div className="col">
                <div className="card mb-4">
                    <div className="card-header">
                        Credit - Scorecard
                    </div>
                    <div className="card-body">


                        <div className="row mb-3">
                            <div className="col">
                                <p>Company Name:</p>
                                <strong>{application.data.applicant.company_name}</strong>
                            </div>
                            <div className="col">
                                <p>Collateral Description:</p>
                                <strong>{application.data.collateral_description}</strong>
                            </div>
                            <div className="col">
                                <p>Submitted Amount:</p>
                                <strong><Money amount={application.data.submitted_amount} /></strong>
                            </div>
                            <div className="col">
                                <p>Term:</p>
                                <strong>{application.data.credit.terms}</strong>
                            </div>
                        </div>

                        <hr />

                        <div className="row">
                            <div className="col">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Lowest FICO
                                        </td>
                                            <td>
                                                {lowestFico()}
                                            </td>
                                            <td>
                                                <span className="badge badge-custom" style={lowestFico() >= 685 ? { backgroundColor: 'green' } : lowestFico() >= 670 ? { backgroundColor: 'grey' } : { backgroundColor: 'yellow', color: 'black' }} >
                                                    {lowestFico() >= 685 ? 'Resale' : lowestFico >= 670 ? 'Either' : 'See Average'}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Average FICO
                                        </td>
                                            <td>
                                                {averageFico()}
                                            </td>
                                            <td>
                                                <span className="badge badge-custom" style={averageFico() >= 670 ? { background: 'grey' } : averageFico() >= 650 ? { background: '#8838ff' } : { background: 'red' }} >
                                                    {averageFico() >= 670 ? 'Either' : averageFico() >= 650 ? 'Retention' : 'Decline'}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                SBSS Score
                                            </td>
                                            <td>
                                                {sbssScore()}
                                            </td>
                                            <td>
                                                <span className="badge badge-custom" style={sbssScore() >= 190 ? { background: 'green' } : sbssScore() >= 175 ? { background: 'grey' } : sbssScore() >= 165 ? { background: '#8838ff' } : { background: 'red' }} >
                                                    {sbssScore() >= 190 ? 'Resale' : sbssScore() >= 175 ? 'Either' : sbssScore() >= 165 ? 'Retention' : 'Decline'}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Paynet
                                            </td>
                                            <td>
                                                {paynetScore()}
                                            </td>
                                            <td>
                                                <span className="badge badge-custom" style={paynetScore() >= 680 ? { background: 'green' } : paynetScore() >= 670 ? { background: 'grey' } : paynetScore() >= 635 ? { background: '#8838ff' } : { background: 'red' }} >
                                                    {paynetScore() >= 680 ? 'Resale' : paynetScore() >= 670 ? 'Either' : paynetScore() >= 635 ? 'Retention' : 'Decline'}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Comp Credit
                                            </td>
                                            <td>
                                                {compCredit()}%
                                            </td>
                                            <td>
                                                <span className="badge badge-custom" style={compCredit() >= 75 ? { background: 'green' } : compCredit() >= 50 ? { background: 'grey' } : compCredit() >= 35 ? { background: '#8838ff' } : { background: 'red' }} >
                                                    {compCredit() >= 75 ? 'Resale' : compCredit() >= 50 ? 'Either' : compCredit() >= 35 ? 'Retention' : 'Decline'}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Average Bank Statement
                                            </td>
                                            <td>
                                                <Money amount={averageBankStatements()} />
                                            </td>
                                            <td>
                                                <span className="badge badge-custom" style={averageBankStatements() >= 1500 ? { background: 'green' } : averageBankStatements() >= 1000 ? { background: 'grey' } : { background: 'red' }} >
                                                    {averageBankStatements() >= 1500 ? 'Resale' : averageBankStatements() >= 1000 ? 'Either' : 'Decline'}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Payment Coverage
                                        </td>
                                            <td>
                                                {paymentCoverage()}
                                            </td>
                                            <td>
                                                <span className="badge badge-custom" style={paymentCoverage() >= 1.75 ? { background: 'green' } : paymentCoverage() >= 1.50 ? { background: 'grey' } : paymentCoverage() >= 1.35 ? { background: '#8838ff' } : { background: 'red' }} >
                                                    {paymentCoverage() >= 1.75 ? 'Resale' : paymentCoverage() >= 1.50 ? 'Either' : paymentCoverage() >= 1.35 ? 'Retention' : 'Decline'}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Time In Business
                                        </td>
                                            <td>
                                                {timeInBusiness()}
                                            </td>
                                            <td>
                                                <span className="badge badge-custom" style={timeInBusiness() >= 4 ? { background: 'green' } : timeInBusiness() >= 3 ? { background: 'grey' } : timeInBusiness() >= 2 ? { background: '#8838ff' } : { background: 'red' }} >
                                                    {timeInBusiness() >= 4 ? 'Resale' : timeInBusiness() >= 3 ? 'Either' : timeInBusiness() >= 2 ? 'Retention' : 'Decline'}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                OLV
                                        </td>
                                            <td>
                                                {scoreCard.data.olv}
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Age of Equipment
                                        </td>
                                            <td>
                                                {scoreCard.data.age_of_equipment}
                                            </td>
                                            <td>
                                                <span className="badge badge-custom" style={scoreCard.data.age_of_equipment <= 5 ? { background: 'green' } : scoreCard.data.age_of_equipment > 10 ? { background: '#8838ff' } : { background: 'grey' }} >
                                                    {scoreCard.data.age_of_equipment <= 5 ? 'Resale' : scoreCard.data.age_of_equipment > 10 ? 'Retention' : 'Either'}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Additional Collateral Cash
                                        </td>
                                            <td>
                                                <Money amount={scoreCard.data.additional_collateral_cash} />
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                OFAC
                                        </td>
                                            <td>
                                                {scoreCard.data.ofac}
                                            </td>
                                            <td>
                                                <span className="badge badge-custom" style={Number(application.data.credit.ofac) === 0 ? { background: 'green' } : { background: 'red' }} >
                                                    {Number(application.data.credit.ofac) === 0 ? 'Accept' : 'Decline'}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Judgements
                                        </td>
                                            <td>
                                                {scoreCard.data.judgements}
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Tax Liens
                                        </td>

                                            <td>
                                                {scoreCard.data.tax_lean}
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Personal Guarantors Lawsuits
                                        </td>
                                            <td>
                                                {scoreCard.data.lawsuit}
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Slow Pay on Paynet
                                        </td>
                                            <td>
                                                {scoreCard.data.slow_pay}
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Loses on Paynet
                                        </td>
                                            <td>
                                                {scoreCard.data.loses_on_paynet}
                                            </td>
                                            <td></td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>
                            <div className="col">

                                <form onBlur={handleSubmit(save)}>
                                    <input ref={register} type="hidden" name="credit_id" value={application.data.credit.id} />
                                    <div className="well">
                                        <div className="row">
                                            <div className="col-4">
                                                <label htmlFor="">Discretionary Yield:</label>
                                                <div className={`input-group`}>
                                                    <input ref={register} className="form-control" type="number" name='discretionary' defaultValue={application.data.credit.discretionary} />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <div className="well">
                                    <p>
                                        Credit Comments:
                                    </p>
                                    <strong>
                                        {application.data.credit.comment || 'N/A'}
                                    </strong>
                                </div>

                                <div className="well">
                                    Personal Guarantors
                                    <table className="table mb-3">
                                        <thead>
                                            <tr>
                                                <td>Name</td>
                                                <td>FICO</td>
                                                <td>Ownership</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {application.data.personal_guarantors.map(pg => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            {pg.first_name} {pg.last_name}
                                                        </td>
                                                        <td>
                                                            {pg.credit_score?.fico || 'N/A'}
                                                        </td>
                                                        <td>
                                                            {pg.ownership || 0}%
                                                    </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="well">
                                    Banking Information
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <td>Bank</td>
                                                <td>Deposits</td>
                                                <td>Withdrawals</td>
                                                <td>Balance</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {application.data.bank_statements.map(bank => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            {bank.bank_name}
                                                        </td>
                                                        <td>
                                                            <Money amount={bank.deposits} />
                                                        </td>
                                                        <td>
                                                            <Money amount={bank.withdrawals} />
                                                        </td>
                                                        <td>
                                                            <Money amount={bank.balance} />
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">

                                {application.data.credit.decision === 'Approved' ?
                                    <Link className="btn btn-primary float-right" to={`/credit-applications/${id}/additional-info`}>
                                        Go back to Additional Info <i className="fas fa-chevron-right" />
                                    </Link>
                                    :
                                    <div className="float-right">Not Approved</div>
                                }


                                <Link className="btn btn-primary" to={`/credit-applications/${id}/underwriting`}>
                                    <i className="fas fa-chevron-left" /> Go back to Underwriting
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScoreCard
