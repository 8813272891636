import React, { useState, } from 'react';
import { useQuery, useQueryClient } from "react-query";
import Axios from "../../Mics/axiosInstance";
import Add from "./Add";
import Item from "./Item";

function useUsers() {
    return useQuery(["users"], async () => {
        const { data } = await Axios.get("/users");
        return data;
    });
}

export const List = () => {
    const { data, isLoading } = useUsers();
    const [modalState, setModalState] = useState(false)
    const queryClient = useQueryClient();

    const triggerReload = () => {
        queryClient.refetchQueries(["users"])
    }

    const toggleModal = () => {
        setModalState(!modalState)
    }

    if (isLoading) {
        return <div>Loading Items</div>
    }

    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        <button className="btn btn-primary float-right" onClick={toggleModal}>
                            <i className="fas fa-plus" /> Add User
                        </button>
                        User List
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Roles</th>
                                <th>Created at</th>
                                <th>Created by</th>
                                <th>Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!data.length ?
                                <tr>
                                    <td className="text-center" colSpan={99}>
                                        No users have been added
                                </td>
                                </tr>
                                :
                                ''
                            }

                            {data.map(item => {

                                return <Item item={item} triggerReload={triggerReload} />

                            })}
                        </tbody>
                    </table>
                    <Add modalState={modalState} toggleModal={toggleModal} triggerReload={triggerReload} />
                </div>
            </div>
        </div>
    )
}

export default List