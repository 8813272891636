import React, { useState } from 'react';
import { Redirect, useParams } from "react-router-dom";
import { useQuery,useQueryClient } from "react-query";
import Axios from "../Mics/axiosInstance";
import Applicant from "./Cards/Applicant"
import BillingAddress from "./Cards/BillingAddress"
import PersonalGuarantors from "../Application/PersonalGuarantors/List"
import CorporateGuarantors from "../Application/CorporateGuarantors/List"
import Credit from "./Cards/Credit"
import Document from "./Cards/Document"
import GenDoc from "./Cards/GenDoc"
import Equipment from "../Equipment/List"
import { CSVLink } from 'react-csv'
import docExportCsv from '../Mics/docExportCsv'

function useApplication(applicationId) {
    return useQuery(["application", applicationId], async () => {
        const { data } = await Axios.get("/applications/" + applicationId);
        return data;
    });
}

export const DocInput = () => {
    const { contractNumber, applicationId } = useParams()
    const { data, isLoading } = useApplication(applicationId);

    const queryClient = useQueryClient();

    const triggerReload = () => {
        queryClient.refetchQueries(["application", applicationId])
    }
    const handleVendorTotals = () =>{

    }
    const document = () => {
        var doc = data.documents.filter(doc => {
            return String(doc.contract_number) === contractNumber
        })

        return doc[0]
    }

    if (isLoading || data === undefined) {
        return <div>Loading Credit Application</div>
    }

    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        Doc Input - Application ID: <strong>{data.id}</strong> - Contract Number: <strong>{document().contract_number}</strong>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">

                                <Applicant application={data} triggerReload={()=>triggerReload()} />
                                <hr />
                                <BillingAddress document={document()} triggerReload={()=>triggerReload()} />
                                <hr />
                                <Document document={document()} triggerReload={()=>triggerReload()} />
                                <hr />
                                <Credit application={data} />
                                <hr />
                                Personal Guarantors
                                <PersonalGuarantors applicationId={data.id} triggerReload={()=>triggerReload()} />
                                <hr />
                                Corporate Guarantors
                                <CorporateGuarantors applicationId={data.id} triggerReload={()=>triggerReload()} />
                                <hr />
                                <GenDoc document={document()} triggerReload={()=>triggerReload()} />
                                <hr />
                                <Equipment document={document()} add={false}  />
                                <hr />

                                <CSVLink data={[docExportCsv.body(document(),data)]} headers={docExportCsv.headers(docExportCsv.body(document(),data))} className="btn btn-primary" >
                                    Download Mail Merge
                                </CSVLink>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocInput
