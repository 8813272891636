import React from 'react';
import Billing from "../Billing";

export const List = ({vendor, document}) => {
    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        Vendor Billing for <strong>{vendor.company_name}</strong>
                    </div>
                    <div className="card-body">
                        <Billing type="vendor" typeId={vendor.id} contractNumber={document.contract_number} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default List