import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import List from "../Applicant/List";
import View from "../Applicant/View";
import Add from "../Applicant/Add";

const Applicant = ({application, applicationId}) => {
    const [view, setView] = useState('select-applicant');

    useEffect(() => {
        if(application.applicant_id){
            setView('view-applicant')
        }
    }, [setView, application]);

    const changeApplicant = () => {
        setView('select-applicant')
    }

    const addApplicant = () => {
        setView('add-applicant')
    }

    const Footer = () => {
        return (
            <div className="row">
                <div className="col">
                    <hr/>
                    <Link className="btn btn-outline-secondary float-right" to={`/applications/${applicationId}/source`}>
                        Go to Source <i className="fas fa-chevron-right"/>
                    </Link>
                    <Link className="btn btn-outline-secondary" to={`/applications/${applicationId}/overview`}>
                        <i className="fas fa-chevron-left"/> Go to Overview
                    </Link>
                </div>
            </div>
        )
    }

    if(view === 'view-applicant'){
        return (
            <div>
                <div className="row mb-3">
                    <div className="col">
                        <button className='btn btn-success float-right' onClick={changeApplicant}><i className="fas fa-pencil-alt"/> Change Applicant</button>
                    </div>
                </div>
                <hr/>
                <View applicantID={application.applicant_id} />
                <Footer/>
            </div>
        );
    }

    if(view === 'add-applicant'){
        return (
            <div>
                <div className="row mb-3">
                    <div className="col">
                        <button className='btn btn-success float-right' onClick={changeApplicant}><i className="fas fa-pencil-alt"/> Change Applicant</button>
                    </div>
                </div>
                <hr/>
                <Add applicationId={applicationId} />
                <Footer/>
            </div>
        );
    }

    if(view === 'select-applicant'){
        return (
            <div>
                <div className="row mb-3">
                    <div className="col">
                        <button className='btn btn-success float-right mr-3' onClick={addApplicant}><i className="fas fa-plus"/> Add New Applicant</button>
                    </div>
                </div>
                <List />
                <Footer/>
            </div>
        );
    }

    return (
        <div>
            No View found in the Applicant.js file
            <Footer/>
        </div>
    );
}

export default Applicant