import React, {useState} from 'react';
import {Redirect, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import Axios from "../Mics/axiosInstance";

function useDocumentGenerator(applicationId) {
    return useQuery(["document-generator", applicationId], async () => {
        const {data} = await Axios.post("/applications/"+applicationId+"/documents");
        return data;
    });
}

export const DocumentGenerator = () => {
    const { applicationId } = useParams()
    const { data, isLoading } = useDocumentGenerator(applicationId);

    if(isLoading || data === undefined){
        return <div>Generating Document for Application: {applicationId}</div>
    }

    return (<Redirect to={`/doc-request/document/${data.contract_number}`} />)
}

export default DocumentGenerator
