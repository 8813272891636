import React, {useState} from 'react';
import {Link, Redirect, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import Axios from "../Mics/axiosInstance";
import Date from "../Helpers/Date";
import Money from "../Helpers/Money";

function useApplicationDocuments(applicationId) {
    return useQuery(["applications", "documents", applicationId], async () => {
        const {data} = await Axios.get("/applications/"+applicationId+"/documents");
        return data;
    });
}

export const DocumentList = () => {
    const { applicationId } = useParams()
    const { data, isLoading } = useApplicationDocuments(applicationId);
    const [contractNumber, setContractNumber] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const tableRowClick = (contractNumber) => {
        setContractNumber(contractNumber)
        setRedirect(true)
    }

    if(isLoading){
        return <div>Loading Credit Application</div>
    }

    if(redirect && contractNumber){
        return (<Redirect to={`/doc-request/document/${contractNumber}`} />)
    }

    // if(!isLoading && data.length === 0){
    //     return <div className="alert alert-danger">Application or Contract Number does not exist</div>
    // }

    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        <Link to={`/generate-document/${applicationId}`} className="btn btn-sm btn-success float-right">
                            <i className="fas fa-plus"/> Add New Doc Request
                        </Link>
                        Application #{applicationId} - Documents
                    </div>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Contract Number</th>
                            <th>Loan Amount</th>
                            <th>Created</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map(document=>{
                            return (
                                <tr onClick={() => tableRowClick(document.contract_number)} className="table-row-click">
                                    <td>
                                        {document.contract_number}
                                    </td>
                                    <td>
                                        <Money amount={document.loan_amount}/>
                                    </td>
                                    <td>
                                        <Date date={document.created_at} time/>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default DocumentList
