import React, { useState } from 'react'
import Axios from '../Mics/axiosInstance';
import { useQuery } from "react-query";

function useUsers() {
    return useQuery(["users"], async () => {
        const { data } = await Axios.get("/users");
        return data;
    });
}

export const UserSelector = ({ value, type, register, name }) => {
    const { data, isLoading } = useUsers();

    const checkRoles = (user) => {
        let roles = []
        if (user.roles) {
            user.roles.forEach((role) => {
                roles.push(role.name.toUpperCase())
            })
        }
        return roles
    }

    if (isLoading || data === undefined) {
        return <div>Loading users</div>
    }

    return (
        <select className="form-control" defaultValue={value} ref={register} name={name}>
            <option value="" disabled selected>Select {type} User</option>
            {data.map((user) => {
                
                    if (checkRoles(user).includes(type.toUpperCase())) {
                        if (type.toUpperCase() === 'CREDIT') {
                            if (user.name === 'Kyle Winther') {
                                return <option value={user.id} selected>{user.name}</option>
                            }
                            else {
                                return <option value={user.id}>{user.name}</option>

                            }
                        }
                        if (type.toUpperCase() === 'APPROVAL') {
                            if (user.name === 'Grant Finch') {
                                return <option value={user.id} selected>{user.name}</option>
                            }
                            else {
                                return <option value={user.id}>{user.name}</option>

                            }
                        }
                        else {
                            return <option value={user.id}>{user.name}</option>

                        }


                    }
                
            })}
        </select>
    )
}
export default UserSelector