import React from 'react';
import Axios from "../Mics/axiosInstance";
import {useForm} from "react-hook-form";
import {NotificationManager} from 'react-notifications';


export const BoardingSheet = ({document, triggerReload}) => {
    if(document.boarding_sheet === null){
        document.boarding_sheet = {}
    }

    const { register, handleSubmit, errors } = useForm({mode: 'onBlur'});
    const [basic_rental_payment, set_basic_rental_payment] = React.useState(document.boarding_sheet.basic_rental_payment ? document.boarding_sheet.basic_rental_payment : 0.00);

    const save = (formData) => {
        formData.basic_rental_payment = basic_rental_payment

        if(document.boarding_sheet_id === null){
            Axios.post('/documents/'+document.contract_number+'/boarding_sheets', formData).then((resp) => {
                document.boarding_sheet = resp.data
                triggerReload()
            })
            .catch((error) => {
                NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
            })
        }else{
            Axios.put('/documents/'+document.contract_number+'/boarding_sheets/'+document.boarding_sheet_id, formData)
            .then(()=>{
                triggerReload()
            })
            .catch((error) => {
                NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
            })
        }

    }

    const handleCurrencyChange = (value, name)=>{
        if(name === 'basic_rental_payment'){
            set_basic_rental_payment(value)
        }
    }

    return (
        <form onBlur={handleSubmit(save)}>
            
            <div className="row mb-4">
                
                <div className="col">
                    <label className="form-label">First Payment Date</label>
                    <input className="form-control" type="date" name="first_payment_date" ref={register} defaultValue={document.boarding_sheet.first_payment_date}/>
                </div>
                <div className="col">
                    <label className="form-label">Accrual Date</label>
                    <input className="form-control" type="date" name="loan_start_date" ref={register} defaultValue={document.boarding_sheet.loan_start_date}/>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-6">
                    <label className="form-label">Equipment Description for Invoice</label>
                    <input className="form-control" type="text" name="equipment_description" ref={register} defaultValue={document.boarding_sheet.equipment_description}/>
                </div>
                <div className="col-3">
                    <label className="form-label">Effective Date:</label>
                    <input className="form-control" type="date" name="effective_date" ref={register} defaultValue={document.boarding_sheet.effective_date}/>
                </div>
                <div className="col-3">
                    <label className="form-label">Expiration Date:</label>
                    <input className="form-control" type="date" name="expire_date" ref={register} defaultValue={document.boarding_sheet.expire_date}/>
                </div>
            </div>
            
            

        </form>
    )
}

export default BoardingSheet
