import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import Axios from "../../Mics/axiosInstance";
import CurrencyInput from '../../Inputs/CurrencyInput';
import {NotificationManager} from 'react-notifications';
import UserSelector from "../../Inputs/UserSelector";


export const Add = ({application, applicationId}) => {
    const { register, handleSubmit, errors, setValue } = useForm();
    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState([])

    useEffect(() => {
        if(loading) {
            Axios.get("/users").then(res=>{
                setUsers(res.data)
                setLoading(false)
            })
        }
    }, [loading, setLoading, users, setUsers]);

    const saveCompany = (data) => {
        Axios.put('/applications/'+applicationId, data).then(() => {
            return window.location.reload(false);
        })
        .catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    };

    return (
        <form onSubmit={handleSubmit(saveCompany)}>
            <div className="row">
                <div className="col">

                    <div className="row mb-3">
                        <div className="col">
                            <label className="form-label">Equipment Cost <span className="required">*</span></label>
                            <CurrencyInput name="equipment_cost" setValue={setValue} register={register} value={application.equipment_cost}/>
                        </div>
                        <div className="col">
                            <label className="form-label">Requested Cost <span className="required">*</span></label>
                            <CurrencyInput name="submitted_amount" setValue={setValue} register={register} value={application.submitted_amount}/>
                        </div>
                        <div className="col">
                            <label htmlFor="requested-terms" className="form-label">Requested Terms</label>
                            <input ref={register} id="requested-terms" className="form-control" name="requested_terms" type="text" defaultValue={application.requested_terms} />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="frequency" className="form-label">Frequency</label>
                            <select ref={register} id="frequency" className="form-control" name="requested_frequency" defaultValue={application.requested_frequency}>
                                <option value="Monthly" >Monthly</option>
                                <option value="Quarterly" >Quarterly</option>
                                <option value="Semi-Annual" >Semi-Annual</option>
                                <option value="Annual" >Annual</option>
                                <option value="Irregular" >Irregular</option>
                                <option value="Bi-Monthly" >Bi-Monthly</option>
                                <option value="Bi-Weekly" >Bi-Weekly</option>
                            </select>
                        </div>
                        <div className="col">
                            <label htmlFor="advance" className="form-label">Advance</label>
                            <select ref={register} id="advance" className="form-control" name="requested_advance" defaultValue={application.requested_advance}>
                                <option value="First Payment" >First Payment</option>
                                <option value="In Areas">In Areas</option>
                                <option value="90 Days Deferred">90 Days Deferred</option>
                                <option value="180 Days Deferred">180 Days Deferred</option>
                                <option value="First and Last Payment">First and Last Payment</option>
                                <option value="45 Days Deferred">45 Days Deferred</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="collateral_description" className="form-label">Collateral Description</label>
                            <textarea ref={register} id="collateral_description" className="form-control" name="collateral_description" rows="5" defaultValue={application.collateral_description}/>
                        </div>
                        <div className="col">
                            <label htmlFor="purpose_of_loan" className="form-label">Purpose of Loan</label>
                            <textarea ref={register} id="purpose_of_loan" className="form-control" name="purpose_of_loan" rows="5" defaultValue={application.purpose_of_loan}/>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-6">
                            <label htmlFor="bdo_user_id" className="form-label">Business Development Officer <span className="required">*</span></label>
                            <UserSelector value={application.bdo_user_id} register={register} type="Sales" name='bdo_user_id'/>
                        </div>
                    </div>

                    <button className="btn btn-primary float-right" type="submit">Save</button>

                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        </form>
    )
}

export default Add
