import React from 'react';
import DedFundLogo from '../images/ded_fund.png'
import endpoints from '../../Components/Mics/endpoints'
import Axios from "../../Components/Mics/axiosInstance";

const Header = () => {
    const logout = () => {
        Axios.post(endpoints.AUTH.LOGOUT, {}).then((res) => {
            localStorage.removeItem('peak-token');
            localStorage.removeItem('peak-email');
            localStorage.removeItem('peak-name');
            localStorage.removeItem('peak-roles');
            return window.location.replace('/')
        })
    }

    return (
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <img src={DedFundLogo} className='header-ded-fund' alt="Logo"/>
            <ul className="navbar-nav ml-auto">
                <div className="topbar-divider d-none d-sm-block"></div>

                <li className="nav-item dropdown no-arrow">
                    <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="mr-2 d-none d-lg-inline text-gray-600">Hello, {localStorage.getItem('peak-name')}</span>
                        <i className="fas fa-user-circle logout-icon"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                        <a className="dropdown-item" onClick={() => logout()} >
                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                            Logout
                        </a>
                    </div>
                </li>
            </ul>
        </nav>
    )
}

export default Header
