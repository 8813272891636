import React from 'react';
import PersonalGuarantor from "./PersonalGuarantor";

export const List = ({application}) => {
    return (
        <div>
            {application.personal_guarantors.map(guarantor=>{
                return (<div className="well"><PersonalGuarantor guarantor={guarantor} applicationID={application.id} /></div>)
            })}
        </div>
    )
}
export default List
