import React, {useEffect, useState} from 'react';
import { useQuery } from "react-query";
import Axios from "../../Mics/axiosInstance";
import FederalID from "../../Helpers/FederalID";

function useSource(id) {
    return useQuery(["sources", id], async () => {
        const { data } = await Axios.get("/sources/"+id);
        return data
    });
}

export const View = ({sourceId}) => {
    const { data, isLoading } = useSource(sourceId);

    if(isLoading || data === undefined){
        return (
            <div>
                <div className='row mt-4'>
                    <div className="col text-center">
                        Application does not have a source selected.
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className='row mb-4'>
                <div className="col">
                    <p>Company:</p>
                    <strong>{data.company_name}</strong>
                </div>
                <div className="col">
                    <p>DBA:</p>
                    <strong>{data.dba_name}</strong>
                </div>
                <div className="col">
                    <p>Internal Name:</p>
                    <strong>{data.internal_name}</strong>
                </div>
            </div>

            <hr/>

            <div className='row mb-4'>
                <div className="col">
                    <p>Type:</p>
                    <strong>{data.business_type?.name}</strong>
                </div>
                <div className="col">
                    <p>Federal ID:</p>
                    <strong><FederalID id={data.federal_id}/></strong>
                </div>
                <div className="col">
                    <p>Comment:</p>
                    <strong>{data.comment}</strong>
                </div>
            </div>
        </div>
    )

}
export default View