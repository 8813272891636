import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import Axios from "../../../Mics/axiosInstance";
import { NotificationManager } from 'react-notifications';

function useCreditScores(applicationID, guarantorID) {
    return useQuery(["credit-scores", applicationID, guarantorID], async () => {
        const { data } = await Axios.get('/applications/' + applicationID + '/personal_guarantors/' + guarantorID + '/credit_scores')


        return data

    })
}
export const CreditScores = ({ guarantorID, applicationID }) => {
    const { data, isLoading } = useCreditScores(applicationID, guarantorID)
    const [id, setId] = useState(data?.id)
    const { register, handleSubmit } = useForm({ mode: 'onBlur' });
    const save = (formData) => {


        if (id) {
            Axios.put('/applications/' + applicationID + `/personal_guarantors/` + guarantorID + `/credit_scores/` + id, formData).catch((error) => {
                NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
            })
        }
        else {
            formData.personal_guarantor_id = guarantorID
            formData.application_id = applicationID
            Axios.post('/applications/' + applicationID + `/personal_guarantors/` + guarantorID + '/credit_scores', formData).then((res) => {
                console.log(res.data)
                setId(res.data.id)
            }).catch((error) => {
                NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
            })
        }
    }

    if (isLoading) {
        return <div>Is Loading</div>
    }

    return (
        <form onBlur={handleSubmit(save)}>
            <div className="row mb-4">
                <div className="col">
                    FICO:
                    <input ref={register} className="form-control" type='number' name="fico" defaultValue={data?.fico} />
                </div>
                <div className="col">
                    Years in Bureau:
                    <input ref={register} className="form-control" type='number' name='years_in_bureau' defaultValue={data?.years_in_bureau} />
                </div>
                <div className="col">
                    Trade Line:
                    <input ref={register} className="form-control" type='number' name='trade_lines' defaultValue={data?.trade_lines} />
                </div>
                <div className="col">
                    Revolving Available Credit:
                    <div className={`input-group`}>
                        <input ref={register} className="form-control" type='number' name='revolving_available_credit' defaultValue={data?.revolving_available_credit} />
                        <div className="input-group-append">
                            <span className="input-group-text">%</span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
export default CreditScores