import React from 'react';
import States from "../../SelectOptions/StatesHook";
import {useForm} from "react-hook-form";
import {NotificationManager} from 'react-notifications';

import Axios from "../../Mics/axiosInstance";

export const Applicant = ({application}) => {
    const { register, handleSubmit, errors } = useForm({mode: 'onBlur'});

    const save = (data) => {
        Axios.put('/applicants/'+application.applicant.id, data)
        .catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }
    return (
        <div>
            <form onBlur={handleSubmit(save)}>
                <div className="row mb-4">
                    <div className="col">
                        Company Name
                        <input className="form-control" type="text" name="company_name" ref={register} defaultValue={application.applicant.company_name}/>
                    </div>
                    <div className="col">
                        DBA
                        <input className="form-control" type="text" name="dba_name" ref={register} defaultValue={application.applicant.dba_name}/>
                    </div>
                    <div className="col">
                        NAICS
                        <input className={`form-control`} type="text" maxLength={6} name="naics" ref={register({required: true})} defaultValue={application.applicant.naics}/>
                    </div>
                </div>

                <hr/>

                <div className="row mb-4">
                    <div className="col">
                        Address
                        <input className="form-control" type="text" name="address" ref={register} defaultValue={application.applicant.address}/>
                    </div>
                    <div className="col">
                        City
                        <input className="form-control" type="text" name="city" ref={register} defaultValue={application.applicant.city}/>
                    </div>
                    <div className="col">
                        State
                        <States value={application.applicant.state} name="state" inputRef={register}/>
                    </div>
                    <div className="col">
                        Zip
                        <input className="form-control" type="text" name="zip" maxLength={5} ref={register} defaultValue={application.applicant.zip}/>
                    </div>
                </div>
            </form>
        </div>

    )
}

export default Applicant
