import React, { useState } from 'react';
import { Link, useParams } from "react-router-dom";
import Axios from "../Mics/axiosInstance";
import fileDownload from "js-file-download";
import { useQuery } from 'react-query';

function useApplication(id) {
    return useQuery(['application-download'], async () => {
        const { data } = await Axios.get('/applications/' + id)
        return data

    })
}

export const Download = () => {
    const { id } = useParams()
    const { data, isLoading } = useApplication(id)
    const [loading, set_loading] = useState(false)

    const downloadAllFiles = () => {
        if (data?.source?.is_discounter) {
            downloadApproval()
            downloadScorecard()
            downloadApprovalTransmittalDiscounter()
            downloadCoversheet()

        }
        else {
            downloadApproval()
            downloadScorecard()
            downloadDocRequest()
            downloadApprovalTransmittalBroker()
        }
    }
    const downloadApproval = () => {
        downloadFile('approval', 'Approval-' + id)
    }
    const downloadApprovalTransmittalBroker = () => {
        downloadFile('approval_transmittal_broker', 'Approval Transmittal-' + id)
    }
    const downloadApprovalTransmittalDiscounter = () => {
        downloadFile('approval_transmittal_discounter', 'Approval Transmittal-' + id)
    }
    const downloadScorecard = () => {
        downloadFile('score_card', 'Scorecard-' + id)
    }
    const downloadDocRequest = () => {
        downloadFile('doc_request', 'Doc Request-' + id)
    }
    const downloadCoversheet = () => {
        downloadFile('funding_cover_sheet', 'Funding Coversheet-' + id)
    }

    const downloadFile = (fileType, fileName) => {
        set_loading(true)
        Axios.get('/applications/' + id + '/downloads/' + fileType, { responseType: 'blob' }).then(file => {
            fileDownload(file.data, fileName + '.pdf', 'application/pdf');
            set_loading(false)
        })
    }
    if (isLoading) {
        return <div>Loading</div>
    }
    return (
        <div className="row">
            <div className="col">
                <div className="row">
                    <div className="col">
                        <div className="card mb-4">
                            <div className="card-header">
                                Credit - Download Materials
                            </div>
                            <div className="card-body">

                                <div className="row mb-5">
                                    <div className="col">
                                        <div className="btn btn-outline-secondary" onClick={downloadAllFiles}>
                                            {loading ? <i className="fas fa-spinner fa-pulse" /> : <i className="far fa-file-pdf" />} Download all documents
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col">
                                        <div className="btn btn-outline-secondary" onClick={downloadApproval} >
                                            {loading ? <i className="fas fa-spinner fa-pulse" /> : <i className="far fa-file-pdf" />} Download Approval
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col">
                                        <div className="btn btn-outline-secondary" onClick={downloadScorecard} >
                                            {loading ? <i className="fas fa-spinner fa-pulse" /> : <i className="far fa-file-pdf" />} Download Score Card
                                        </div>
                                    </div>
                                </div>
                                {data?.source?.is_discounter
                                    ?
                                    <React.Fragment>
                                        <div className="row mb-2">
                                            <div className="col">
                                                <div className="btn btn-outline-secondary" onClick={downloadApprovalTransmittalDiscounter} >
                                                    {loading ? <i className="fas fa-spinner fa-pulse" /> : <i className="far fa-file-pdf" />} Download Approval Transmittal Discounter
                                    </div>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col">
                                                <div className="btn btn-outline-secondary" onClick={downloadCoversheet} >
                                                    {loading ? <i className="fas fa-spinner fa-pulse" /> : <i className="far fa-file-pdf" />} Download Funding Cover Sheet
                                        </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="row mb-2">
                                            <div className="col">
                                                <div className="btn btn-outline-secondary" onClick={downloadApprovalTransmittalBroker} >
                                                    {loading ? <i className="fas fa-spinner fa-pulse" /> : <i className="far fa-file-pdf" />} Download Approval Transmittal Broker
                                        </div>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col">
                                                <div className="btn btn-outline-secondary" onClick={downloadDocRequest} >
                                                    {loading ? <i className="fas fa-spinner fa-pulse" /> : <i className="far fa-file-pdf" />} Download Doc Request Form
                                        </div>
                                            </div>
                                        </div>
                                    </React.Fragment>

                                }












                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Download
