import React, {useEffect, useState} from 'react';

export const SSN = ({number}) => {
    const [ssn, setSSN] = useState('');

    useEffect(() => {
        format(number)
    });

    const format = () => {
        if(number !== undefined && number !== '' && number !== null){
            number = number.replace(/[^\d]/g, '')
            setSSN(number.substring(0, 3) + '-' + number.substring(3, 5) + '-' + number.substring(5,9))
        }
    }
    return (<>{ssn}</>)
}
export default SSN
