import React, {useState} from 'react';
import { Link } from 'react-router-dom'
import { useQuery } from "react-query";
import Axios from "../Mics/axiosInstance";
import Select from './Select'

function useApplicants() {
    return useQuery("applicants", async () => {
        const { data } = await Axios.get("/applicants");
        return data;
    });
}

export const List = () => {
    const { data, isLoading } = useApplicants();
    const  [search, setSearch]  = useState('')

    if (isLoading) {
        return <div>Loading Items</div>
    }

    let filteredApplicant = data.filter(applicant => String(applicant.company_name).toLowerCase().indexOf(String(search).toLowerCase()) !== -1)

    return (
        <div className="row">
            <div className="col-md">
                <div className="card mb-4">
                    <div className="card-header">
                        <Link to={`/applicants/new`} className="btn btn-sm btn-success float-right">
                            <i className="fas fa-plus" /> New Applicant
                        </Link>
                        Applicants
                    </div>
                    <div  className="card-body">
                        <div className="row mb-4">
                            <div className="col-3">
                                <lable className="form-label">Applicant Name</lable>
                                <input className="form-control" name="search" defaultValue={search} onChange={(e) => setSearch(e.target.value)} />
                            </div>
                        </div>
                        <table className="table table-hover ">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Company</th>
                                    <th>DBA</th>
                                    <th>Phone Number</th>
                                    <th>Federal ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredApplicant.map(company => {
                                    return (<Select company={company}></Select>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default List