import React, { useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import Axios from "../../../Mics/axiosInstance";
import { useQuery } from "react-query";
import { NotificationManager } from 'react-notifications';
import BooleanSelector from '../../../Inputs/BooleanSelector'
export const Vendor = ({ vendor, applicationID }) => {
    const { register, handleSubmit } = useForm({ mode: 'onBlur' })

    const save = (data) => {
        Axios.put('/vendors/' + vendor.id, data).then(() => {

        }).catch((error) => NotificationManager.error(`${error.key} ${error.message}`, 'Error saving'))
    }

    return (
        <React.Fragment>
            <form onBlur={handleSubmit(save)}>
                <div className="row mb-4">
                    <div className="col">
                        Company Name:
                    <input ref={register} className="form-control" name="company_name" defaultValue={vendor.company_name} />
                    </div>
                    <div className="col">
                        DBA Name:
                    <input ref={register} className="form-control" name="dba_name" defaultValue={vendor.dba_name} />

                    </div>
                    <div className="col">
                        Approved?
                        <BooleanSelector register={register} name="approved" value={vendor.approved} />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col">
                        Approved for Prefunding?
                        <BooleanSelector register={register} name="approved_for_prefunding" value={vendor.approved_for_prefunding} />
                    </div>
                    <div className="col">
                        Prefunding terms
                        <input ref={register} className="form-control" id="prefunding_terms" name="prefunding_terms" defaultValue={vendor.prefunding_terms} />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col">
                        Comment:
                    <textarea ref={register} className="form-control" name="comment" defaultValue={vendor.comment} />
                    </div>
                </div>

            </form>
        </React.Fragment>
    )
}
export default Vendor