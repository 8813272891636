import React from 'react';
import Axios from "../../Mics/axiosInstance";
import {useParams} from "react-router-dom";
import {NotificationManager} from 'react-notifications';
import FederalID from "../../Helpers/FederalID";

export const Select = ({source}) => {
    let { applicationId } = useParams();

    const saveToApplication = () => {
        var data = {
            source_id: source.id
        }

        Axios.put('/applications/'+applicationId, data).then(() => {
            return window.location.reload(false);
        })
        .catch((err) => {
            NotificationManager.error('Please try again', 'Error saving')
        })
    }

    const formatFederalID = (id) => {
        if(id !== undefined && id !== '' && id !== null){
            id = id.replace('-', '')
            return id.substring(0, 2) + '-' + id.substring(2);
        }
    }

    return (
        <tr className="applicant-select" onClick={saveToApplication}>
            <td>
                {source.id}
            </td>
            <td>
                {source.company_name}
            </td>
            <td>
                {source.dba_name}
            </td>
            <td>
                <FederalID id={source.federal_id}/>
            </td>
        </tr>
    )

}
export default Select

