import React, {useState} from 'react';
import { Redirect } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Axios from "../Mics/axiosInstance";
import {NotificationManager} from 'react-notifications';
import FederalTaxIdInput from '../Inputs/FederalTaxIdInput'
import PhoneInput from '../Inputs/PhoneInput'
import StatesSelector from '../Inputs/StatesSelector'
import BusinessTypeSelect from '../Inputs/BusinessTypeSelect'


export const Add = () => {
    const { register, handleSubmit, errors } = useForm();
    const [redirect, setRedirect] = useState(false);

    const save = (data) => {
        Axios.post('/applicants', data).then(() => {
            NotificationManager.success('Applicant has been added.', 'Success')
            setRedirect(true)
        })
        .catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    };

    if(redirect){
        return (<Redirect to={`/applicants/`}/>)
    }

    return (
        <div className="row">
            <div className="col-md">
                <div className="card mb-4">
                    <div className="card-header">
                        Add Applicant
                    </div>
                    <div className="card-body">

                        <form onSubmit={handleSubmit(save)}>
                            <div className='row mb-3'>
                                <div className="col-4">
                                    <label className="form-label">Legal Entity <span className="required">*</span></label>
                                    <input ref={register({ required: true })} type="text" className={`form-control ${errors.company_name ? "is-invalid" : ""}`} name="company_name" />
                                </div>
                                <div className="col-4">
                                    <label className="form-label">DBA</label>
                                    <input ref={register} name="dba_name" className="form-control" type="text" />
                                </div>
                                <div className="col-4">
                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="business_type_id" className="form-label">Business Type</label>
                                            <BusinessTypeSelect register={register} name="business_type_id"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-4'>
                                <div className="col-6">
                                    <label className="form-label">Incorporated State</label>
                                    <StatesSelector register={register} name="state_of_incorporation" />
                                </div>
                                <div className="col-6">
                                    <label className="form-label">Date Established <span className="required">*</span></label>
                                    <input ref={register} name="date_established" className="form-control" type="date" />
                                </div>
                            </div>

                            <hr/>

                            <div className='row mb-3'>
                                <div className="col-6">
                                    <label className="form-label">Physical Address <span className="required">*</span></label>
                                    <input ref={register({ required: true })} type="text" className={`form-control ${errors.address ? "is-invalid" : ""}`} name="address" />
                                </div>
                                <div className="col-6">
                                    <label className="form-label">City <span className="required">*</span></label>
                                    <input ref={register({ required: true })} name="city" className={`form-control ${errors.city ? "is-invalid" : ""}`} type="text" />
                                </div>
                            </div>

                            <div className='row mb-4'>
                                <div className="col-4">
                                    <label className="form-label">County </label>
                                    <input ref={register} name="county" type="text" className={`form-control ${errors.county ? "is-invalid" : ""}`} />
                                </div>
                                <div className="col-4">
                                    <label className="form-label">State <span className="required">*</span></label>
                                    <StatesSelector register={register} name="state" />
                                </div>
                                <div className="col-4">
                                    <label className="form-label">Zip Code <span className="required">*</span></label>
                                    <input ref={register({ required: true })} name="zip" className={`form-control ${errors.zip ? "is-invalid" : ""}`} maxLength={5} type="text" />
                                </div>
                            </div>

                            <hr/>

                            <div className='row mb-4'>
                                <div className="col-6">
                                    <label className="form-label">Phone Number</label>
                                    <PhoneInput register={register} name="phone_number" />
                                </div>
                                <div className="col-6">
                                    <label className="form-label">Website</label>
                                    <input ref={register} name="website" className="form-control" type="text" />
                                </div>
                            </div>

                            <hr/>

                            <div className='row mb-4'>
                                <div className="col-12">
                                    <label className="form-label">Description of Company</label>
                                    <textarea ref={register} name="description" className="form-control" rows="5" style={{flex: '30'}} />
                                </div>
                            </div>

                            <hr/>

                            <div className='row mb-4'>
                                <div className="col-4">
                                    <label className="form-label">Federal ID</label>
                                    <FederalTaxIdInput register={register} name="federal_id" />
                                </div>
                                <div className="col-4">
                                    <label className="form-label">NAICS Code</label>
                                    <input ref={register} name="naics" className="form-control" type="text" />
                                </div>
                            </div>

                            <hr/>

                            <div className='row mb-3'>
                                <div className="col-12">
                                    <label className="form-label">Comment</label>
                                    <textarea ref={register} name="comment" className="form-control" rows="5" />
                                </div>
                            </div>
                            <button className="btn btn-primary float-right" type="submit">Save Applicant</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Add
