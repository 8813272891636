import React from 'react';
import {Link} from "react-router-dom";
import ApplicantView from "../Applicant/View";
import SourceView from "../Source/View";
import DetailView from "../Details/View";
import PersonalGuarantorsList from "../PersonalGuarantors/List";
import CorporateGuarantorsList from "../CorporateGuarantors/List";
import BankingInfoList from "../BankingInfo/List";
import VendorsList from "../Vendor/List";

const Overview = ({application, applicationId}) => {
    return (
        <div>
            <div className="well mb-4">
                <div className="row">
                    <div className="col">
                        <Link className="btn btn-outline-secondary float-right" to={`/applications/${applicationId}/applicant`}>
                            Manage <i className="fas fa-chevron-right"/>
                        </Link>
                        <h4>Applicant Information</h4>
                    </div>
                </div>
                <hr/>
                <ApplicantView applicantID={application.applicant_id}/>
            </div>

            <hr/>

            <div className="well mb-4">
                <div className="row">
                    <div className="col">
                        <Link className="btn btn-outline-secondary float-right" to={`/applications/${applicationId}/source`}>
                            Manage <i className="fas fa-chevron-right"/>
                        </Link>
                        <h4>Source Information</h4>
                    </div>
                </div>
                <hr/>
                <SourceView sourceId={application.source_id} />
            </div>

            <hr/>

            <div className="well mb-4">
                <div className="row">
                    <div className="col">
                        <Link className="btn btn-outline-secondary float-right" to={`/applications/${applicationId}/details`}>
                            Manage <i className="fas fa-chevron-right"/>
                        </Link>

                        <h4>Details</h4>
                    </div>
                </div>
                <hr/>
                <DetailView application={application} applicationId={applicationId} />
            </div>

            <hr/>

            <div className="well mb-4">
                <div className="row mb-3">
                    <div className="col">
                        <Link className="btn btn-outline-secondary float-right" to={`/applications/${applicationId}/personal-guarantors`}>
                            Manage <i className="fas fa-chevron-right"/>
                        </Link>

                        <h4>Personal Guarantors</h4>
                    </div>
                </div>
                <PersonalGuarantorsList applicationId={applicationId}/>
            </div>

            <hr/>

            <div className="well mb-4">
                <div className="row mb-3">
                    <div className="col">
                        <Link className="btn btn-outline-secondary float-right" to={`/applications/${applicationId}/corporate-guarantors`}>
                            Manage <i className="fas fa-chevron-right"/>
                        </Link>

                        <h4>Corporate Guarantors</h4>
                    </div>
                </div>
                <CorporateGuarantorsList applicationId={applicationId}/>
            </div>

            <hr/>

            <div className="well mb-4">
                <div className="row mb-3">
                    <div className="col">
                        <Link className="btn btn-outline-secondary float-right" to={`/applications/${applicationId}/banking-info`}>
                            Manage <i className="fas fa-chevron-right"/>
                        </Link>

                        <h4>Banking Information</h4>
                    </div>
                </div>
                <BankingInfoList applicationId={applicationId}/>
            </div>

            <hr/>

            <div className="well mb-4">
                <div className="row mb-3">
                    <div className="col">
                        <Link className="btn btn-outline-secondary float-right" to={`/applications/${applicationId}/vendor`}>
                            Manage <i className="fas fa-chevron-right"/>
                        </Link>

                        <h4>Vendors</h4>
                    </div>
                </div>
                <VendorsList applicationId={applicationId}/>
            </div>

            <hr/>
        </div>
    );
}

export default Overview