
// const axios = require('../Mics/axiosInstance')
// const document = await axios.get('/documents')
let handleVendorTotals = function (data) {
    var total = 0
    data.prefundings.forEach(disbursement => {
        total += Number(disbursement.amount)
    })
    return total
}
let totalCommission = function(document){
    return document.loan_amount - document.disbursement?.discounted_equipment_cost
}
let headers = (data) => {
    var headers = []
    Object.keys(data).map(key => {
        headers.push({ 'label': key, 'key': key })
    })
    return headers
}

let body = (document, application) => {
    let body = {}
    var applicant_billings = document.billings?.filter(bill => bill.applicant_id === application.applicant.id)
    var source_billings = document.billings?.filter(bill => bill.source_id === application.source.id)
    var vendor_billings = document.billings?.filter(bill => bill.vendor_id !== null)
    // console.log(document)
    body.contract_number = document.contract_number
    //Applicant
    body.company_name = application.applicant.company_name
    body.dba = application.applicant.dba_name
    body.address = application.applicant.address
    body.city = application.applicant.city
    body.state = application.applicant.state
    body.zip = "'"+application.applicant.zip
    body.phone_number = application.applicant.phone_number
    body.federal_id = application.applicant?.federal_id
    body.contact_name = document.contact_name
    body.contact_phone_number = document.contact_phone_number
    body.account_number = "'"+applicant_billings[0]?.account_number
    body.routing_number = "'"+applicant_billings[0]?.routing_number
    body.bank_name = applicant_billings[0]?.bank_name
    body.account_holder_name = applicant_billings[0]?.account_holder_name
    body.naics = application.applicant?.naics
    body.jurisdiction = application.applicant.state_of_incorporation
    //Documents
    body.accounting_email = document.accounting_email
    body.advanced_payment = document.advanced_payment
    body.asset_type = document?.asset_type?.code + '-' + document?.asset_type?.name
    body.bank_rate = document?.bank_rate
    body.billing_address = document.billing_address
    body.billing_city = document.billing_city
    body.billing_state = document.billing_state
    body.billing_zip = document.billing_zip
    body.broker_commission = document.broker_commission
    body.broker_points = document.broker_points
    body.co_lessee = document.co_lessee
    body.contract_type = document.contract_type
    body.contract_number = document.contract_number
    body.co_signer_name = document.co_signer_name
    body.co_signer_title = document.co_signer_title
    body.courtesy_invoice_email = document.courtesy_invoice_email
    body.dedicated_rate = document?.dedicated_rate
    body.doc_date = document.doc_date
    body.doc_fee_financed = document.doc_fee_financed
    body.doc_fee_invoice = document.doc_fee_invoice
    body.doc_user = document?.doc_user?.name
    body.document_email = document.document_email
    body.down_payment = document.down_payment
    body.exempt = document.exempt
    body.exempt_certificate = document.exempt_certificate
    body.first_payment = document.first_payment
    body.last_payment = document.last_payment
    body.loan_amount = document.loan_amount
    body.on_stream = document.on_stream
    body.payment_amount = document.payment_amount
    body.signer_name = document.signer_name
    body.signer_email = document.signer_email
    body.signer_title = document.signer_title
    body.sales_tax_amount = document.sales_tax_amount
    body.sales_tax_rate = document.sales_tax_rate
    body.tax = body.sales_tax_amount ? body.sales_tax_amount : body.sales_tax_rate
    body.term = document.term
    body.title_worker = document.title_worker
    body.total_invoiced_amount = document.total_invoiced_amount
    body.upfront = document.upfront
    body.docs_sent_out = document.docs_sent_out
    body.redoc = document.redoc
    body.docs_recieved = document.docs_recieved
    body.inspection_completed = document.inspection_completed
    body.ucc_recorded = document.ucc_recorded
    body.status = document.status
    body.status_date = document.status_date

    //Vendors
    body.vendor_total = handleVendorTotals(document)

    for (let index = 0; index < 6; index++) {
        var billing = vendor_billings.filter(bill => bill.vendor_id === application.vendors[index]?.id)
        var list = document.equipments.filter(y => y.vendor_id === application.vendors[index]?.id)
        var total = 0
        list.map(y => {
            total += y.cost
        })
        body[`vendor_${index+1}_amt`] = total
        body[`payees_${index+1}_company`] = application.vendors[index]?.company_name
        body[`payees_${index+1}_address`] = application.vendors[index]?.address
        body[`payees_${index+1}_city`] = application.vendors[index]?.city
        body[`payees_${index+1}_state`] = application.vendors[index]?.state
        body[`payees_${index+1}_zip`] = application.vendors[index]?.zip
        body[`payees_${index+1}_routing_number`] = "'"+billing[0]?.routing_number
        body[`payees_${index+1}_account_number`] = "'"+billing[0]?.account_number
        body[`payees_${index+1}_bank_name`] = billing[0]?.bank_name
        body[`payees_${index+1}_reference`] = billing[0]?.comment
        var prefunding = document.prefundings.filter(prefunding => prefunding.vendor_id === application.vendors[index]?.id)
        var i = 0
        //prefundings
        for (let i = 0; i < 4; i++) {
            if (i === 0) {
                body[`vendor_${index+1}_a_pre_amt`] = prefunding[i]?.amount
                body[`vendor_${index+1}_a_pre_release_date`] = prefunding[i]?.release_date
                body[`vendor_${index+1}_a_pre_doc_fee`] = prefunding[i]?.doc_fee
                body[`vendor_${index+1}_a_pre_vendor_commission`] = prefunding[i]?.vendor_commission
            }
            else if (i === 1) {
                body[`vendor_${index+1}_b_pre_amt`] = prefunding[i]?.amount
                body[`vendor_${index+1}_b_pre_release_date`] = prefunding[i]?.release_date
                body[`vendor_${index+1}_b_pre_doc_fee`] = prefunding[i]?.doc_fee
                body[`vendor_${index+1}_b_pre_vendor_commission`] = prefunding[i]?.vendor_commission
            } else if (i === 2) {
                body[`vendor_${index+1}_c_pre_amt`] = prefunding[i]?.amount
                body[`vendor_${index+1}_c_pre_release_date`] = prefunding[i]?.release_date
                body[`vendor_${index+1}_c_pre_doc_fee`] = prefunding[i]?.doc_fee
                body[`vendor_${index+1}_c_pre_vendor_commission`] = prefunding[i]?.vendor_commission
            } else if (i === 3) {
                body[`vendor_${index+1}_d_pre_amt`] = prefunding[i]?.amount
                body[`vendor_${index+1}_d_pre_release_date`] = prefunding[i]?.release_date
                body[`vendor_${index+1}_d_pre_doc_fee`] = prefunding[i]?.doc_fee
                body[`vendor_${index+1}_d_pre_vendor_commission`] = prefunding[i]?.vendor_commission
            }
        }        
    }

    //pg
    var pgCount = 1
    for (let index = 0; index < 4; index++) {
        body[`personal_guarantor_${index + 1}_first_name`] = application.personal_guarantors[index]?.first_name
        body[`personal_guarantor_${index + 1}_middle_name`] = application.personal_guarantors[index]?.middle_name
        body[`personal_guarantor_${index + 1}_last_name`] = application.personal_guarantors[index]?.last_name
        body[`personal_guarantor_${index + 1}_phone_number`] = application.personal_guarantors[index]?.phone_number
        body[`personal_guarantor_${index + 1}_ssn`] = application.personal_guarantors[index]?.ssn
        body[`personal_guarantor_${index + 1}_address`] = application.personal_guarantors[index]?.address
        body[`personal_guarantor_${index + 1}_city`] = application.personal_guarantors[index]?.city
        body[`personal_guarantor_${index + 1}_state`] = application.personal_guarantors[index]?.state
        body[`personal_guarantor_${index + 1}_zip`] = application.personal_guarantors[index]?.zip
        body[`personal_guarantor_${index + 1}_county`] = application.personal_guarantors[index]?.county
        body[`personal_guarantor_${index + 1}_title`] = application.personal_guarantors[index]?.title
        body[`personal_guarantor_${index + 1}_ownership`] = application.personal_guarantors[index]?.ownership
        body[`personal_guarantor_${index + 1}_fico`] = application.personal_guarantors[index]?.fico
        body[`personal_guarantor_${index + 1}_revolving_available_credit`] = application.personal_guarantors[index]?.revolving_available_credit
        body[`personal_guarantor_${index + 1}_trade_line`] = application.personal_guarantors[index]?.trade_line
        body[`personal_guarantor_${index + 1}_years_in_bureau`] = application.personal_guarantors[index]?.years_in_bureau

    }
    
    //cg
    // var cgCount = 1
    // application.corporate_guarantors.map(cg => {
    //     body[`corporate_guarantor_${cgCount}_company_name`] = cg.company_name
    //     body[`corporate_guarantor_${cgCount}_date_established`] = cg.date_established
    //     body[`corporate_guarantor_${cgCount}_jurisdiction`] = cg.jurisdiction
    //     body[`corporate_guarantor_${cgCount}_address`] = cg.address
    //     body[`corporate_guarantor_${cgCount}_city`] = cg.city
    //     body[`corporate_guarantor_${cgCount}_state`] = cg.state
    //     body[`corporate_guarantor_${cgCount}_zip`] = cg.zip
    //     body[`corporate_guarantor_${cgCount}_phone_number`] = cg.phone_number
    //     body[`corporate_guarantor_${cgCount}_singer_name`] = cg.singer_name
    //     body[`corporate_guarantor_${cgCount}_signer_title`] = cg.signer_title
    //     cgCount += 1
    // })

    //equipments 
    for (let index = 0; index < 6; index++) {
        body[`asset_${index+1}_address`] = document.equipments[index]?.address
        body[`asset_${index+1}_city`] = document.equipments[index]?.city
        body[`asset_${index+1}_state`] = document.equipments[index]?.state
        body[`asset_${index+1}_zip code`] = "'"+document.equipments[index]?.zip
        body[`asset_description_${index+1}`] = document.equipments[index]?.description
        if (index === 1) {
            body[`equipment_${index+1}_vin_or_serial_number`] = document.equipments[index]?.serial_number ? document.equipments[index]?.serial_number : document.equipments[index]?.vin_number
        }
        else {
            body[`equipment_${index+1}_vin_or_serial_number`] = document.equipments[index]?.serial_number ? document.equipments[index]?.serial_number : document.equipments[index]?.vin_number
        }
        
    }
    // var equipCount = 1
    // document.equipments.map(x => {
    //     body[`asset_${equipCount}_address`] = x.address
    //     body[`asset_${equipCount}_city`] = x.city
    //     body[`asset_${equipCount}_state`] = x.state
    //     body[`asset_${equipCount}_zip code`] = String(x.zip)
    //     body[`asset_description_${equipCount}`] = x.description
    //     if (equipCount === 1) {
    //         body[`equipment_${equipCount}_vin_or_serial_number`] = x.serial_number ? x.serial_number : x.vin_number
    //     }
    //     else {
    //         body[`equipment_${equipCount}_vin_or_serial_number`] = x.serial_number ? x.serial_number : x.vin_number
    //     }
    //     equipCount += 1
    // })

    //Source
    body.source_name = application.source?.company_name
    body.source_routing_number = "'"+source_billings[0]?.routing_number
    body.source_account_number = "'"+source_billings[0]?.account_number
    body.source_bank_name = source_billings[0]?.bank_name
    body.source_ach_or_wire = source_billings[0]?.billing_method.name
    body.source_reference = application.applicant?.company_name
    body.source_address = application.source?.source_contact?.address
    body.source_city = application.source?.source_contact?.city
    body.source_state = "'"+application.source?.source_contact?.state
    body.source_zip = application.source?.source_contact?.zip
    body.source_type = application.source?.source_type?.name
    body.fee_to_source = application.credit?.fee_to_source
    body.total_commission = totalCommission(document)
    //disbursement
    body.broker_commission = document.broker_commission
    body.doc_fee_add = document.disbursement?.doc_fee_add
    body.doc_fee_deduct = document.disbursement?.doc_fee_deduct
    body.commission_held = document.disbursement?.commission_held
    body.commission_held_release_date = document.disbursement?.commission_held_release_date
    body.shortfall = document.disbursement?.shortfall
    body.shortfall_comment = document.disbursement?.shortfall_comment
    body.commission_due_release_date = document.disbursement?.commission_due_release_date
    body.commission_due = document.disbursement?.commission_due
    body.discounted_equiment_cost = document.disbursement?.discounted_equipment_cost
    body.doc_fee = document.disbursement?.doc_fee
    body.gps_fee = document.disbursement?.gps_fee
    body.inspection_fee = document.disbursement?.inspection_fee
    body.title_fee = document.disbursement?.title_fee

    //boarding sheet
    body.contract_type = document.contract_type
    body.first_payment_date = document.boarding_sheet?.first_payment_date
    body.effective_date = document.boarding_sheet?.effective_date
    body.expire_date = document.boarding_sheet?.expire_date
    body.accural_date = document.boarding_sheet?.loan_start_date
    body.remaining_term = document.remaining_terms

    body.payment_frequency = document.payment_frequency ? document.payment_frequency : "Monthly"
    body.billing_group = document.billing_group ? document.billing_group : 'A-1-7'
    body.equipment_description = document.boarding_sheet?.equipment_description
    body.asset_type = document.asset_type?.name
    body.upfront = document.upfront
    body.tax = document.sales_tax_amount ? document.sales_tax_amount : document.sales_tax_rate ? document.sales_tax_rate : '0.00'

    //Totals and Validation
    body.total_invoiced_amount = document.total_invoiced_amount
    body.loan_amount = document.loan_amount
    body.vendor_total = handleVendorTotals(document)
    body.doc_fee_invoiced = document.doc_fee_invoiced


    return body
}

exports.body = body
exports.headers = headers