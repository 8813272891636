import React, { useState } from 'react';
import Axios from "../Mics/axiosInstance";
import { useForm } from "react-hook-form";
import CurrencyInput from "../Inputs/CurrencyInput";
import Money from "../Helpers/Money";
import { useQuery } from "react-query";
import PaymentFrequencySelector from '../Inputs/PaymentFrequencySelector';
import { NotificationManager } from 'react-notifications';

function useDocument(id) {
    return useQuery(["documents", id], async () => {
        const { data } = await Axios.get("/documents/" + id);
        return data
    });
}

export const Documentation = ({ document }) => {
    const { data, isLoading } = useDocument(document.contract_number);
    const { register, handleSubmit, setValue } = useForm({ mode: 'onChange' });
    const [brokerPoints, setBrokerPoints] = useState(document.broker_points);

    const save = (data) => {
        Axios.put('/applications/' + document.application_id + '/documents/' + document.contract_number, data).then((res) => {
            data = res.data
            setBrokerPoints(res.data.broker_points)
        }).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }
    
    const termIsValid = async () => {
        var application = await (await Axios.get('/applications/'+data.application_id)).data
        if (data.term > application.credit?.terms) {
            return false
        }
        return true
    }

    if (isLoading) {
        return <div>Loading Vendors</div>
    }

    if (!isLoading && data.length === 0) {
        return <div className="alert alert-danger">Application or Contract Number does not exist</div>
    }

    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        Documentation - Application ID# <strong>{data.application_id}</strong> - Contract Number: <strong>{data.contract_number}</strong>
                    </div>
                    <div className="card-body">

                        <form onChange={handleSubmit(save)}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            Loan Amount
                                    </td>
                                        <td>
                                            <div className="mb-2">
                                                <CurrencyInput name="loan_amount" value={data?.loan_amount} setValue={setValue} register={register} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Term
                                    </td>
                                        <td>
                                            <input ref={register} type="number" className={`form-control`} name='term' defaultValue={data?.term} style={termIsValid()?{"background":'white', 'color':'black' }:{"background":'#ffbfbf', 'color':'black' }}/>
                                            
                                        </td>
                                        <td>
                                            Remaining Term
                                    </td>
                                        <td>
                                            <input ref={register} type="number" className="form-control" name='remaining_terms' defaultValue={data?.remaining_terms} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Base Payment
                                    </td>
                                        <td>
                                            <div className="mb-2">
                                                <CurrencyInput name="payment_amount" value={data?.payment_amount} setValue={setValue} register={register} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Advance or Arrears
                                    </td>
                                        <td>
                                            <select ref={register} className="form-control" name="advanced_payment" defaultValue={data?.advanced_payment} >
                                                <option value='Advance'>Advance</option>
                                                <option value="Arrears">Arrears</option>
                                            </select>
                                        </td>
                                        <td> Payment Frequency</td>
                                        <td>
                                            <PaymentFrequencySelector value={data?.payment_frequency} register={register} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            First Payment
                                    </td>
                                        <td>
                                            <div className="mb-2">
                                                <CurrencyInput name="first_payment" value={data?.first_payment} setValue={setValue} register={register} />
                                            </div>
                                        </td>
                                        <td>
                                            Broker Commission
                                    </td>
                                        <td>
                                            <div className="mb-2">
                                                <CurrencyInput name="broker_commission" value={data?.broker_commission} setValue={setValue} register={register} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Last Payment
                                    </td>
                                        <td>
                                            <div className="mb-2">
                                                <CurrencyInput name="last_payment" value={data?.last_payment} setValue={setValue} register={register} />
                                            </div>
                                        </td>
                                        <td>
                                            Broker Points
                                    </td>
                                        <td>
                                            {brokerPoints}%
                                    </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Down Payment
                                    </td>
                                        <td>
                                            <div className="mb-2">
                                                <CurrencyInput name="down_payment" value={data?.down_payment} setValue={setValue} register={register} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Doc Fee Invoiced
                                    </td>
                                        <td>
                                            <div className="mb-2">
                                                <CurrencyInput name="doc_fee_invoice" value={data?.doc_fee_invoice} setValue={setValue} register={register} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Doc Fee Financed
                                    </td>
                                        <td>
                                            <div className="mb-2">
                                                <CurrencyInput name="doc_fee_financed" value={data?.doc_fee_financed} setValue={setValue} register={register} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Total Invoiced Amount
                                    </td>
                                        <td>
                                            <div className="mb-2">
                                                <Money amount={data?.total_invoiced_amount} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Signer Name
                                    </td>
                                        <td>
                                            <input ref={register} type="text" className="form-control" name='signer_name' defaultValue={data?.signer_name} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Signer Title
                                    </td>
                                        <td>
                                            <input ref={register} type="text" className="form-control" name='signer_title' defaultValue={data?.signer_title} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Signer Email
                                    </td>
                                        <td>
                                            <input ref={register} type="text" className="form-control" name='signer_email' defaultValue={data?.signer_email} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Documentation