import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import Axios from "../../Mics/axiosInstance";
import {NotificationManager} from 'react-notifications';
import InputMask from "react-input-mask";
import BusinessTypeSelect from '../../Inputs/BusinessTypeSelect';
import StatesSelector from "../../Inputs/StatesSelector";
import PhoneInput from "../../Inputs/PhoneInput";
import FederalTaxIdInput from "../../Inputs/FederalTaxIdInput";
import SSNInput from "../../Inputs/SSNInput";

export const Add = ({applicationId}) => {
    const { register, handleSubmit, errors } = useForm();
    const [ssn_or_ein, set_ssn_or_ein] = useState(false)

    const saveCompany = (data) => {
        Axios.post('/applicants', data).then((res) => {

            var applicationData = {
                applicant_id: res.data.id
            }

            Axios.put('/applications/'+applicationId, applicationData).then(() => {
                return window.location.reload(false);
            })
            .catch((err) => {
                NotificationManager.error(`${err.key} ${err.message}`, 'Error saving')
            })
        })
        .catch((err) => {
            NotificationManager.error(`${err.key} ${err.message}`, 'Error saving')
        })
    }

    const toggleType = (event) => {
        let value = event.target.value
        set_ssn_or_ein(value)
    }

    return (
        <form onSubmit={handleSubmit(saveCompany)}>
            <div className='row mb-3'>
                <div className="col-4">
                    <label className="form-label">Legal Entity <span className="required">*</span></label>
                    <input ref={register({ required: true })} type="text" className={`form-control ${errors.company_name ? "is-invalid" : ""}`} name="company_name" />
                </div>
                <div className="col-4">
                    <label className="form-label">DBA</label>
                    <input ref={register} name="dba_name" className="form-control" type="text" />
                </div>
                <div className="col-4">
                    <label htmlFor="sos-type" className="form-label">Business Type</label>
                    <BusinessTypeSelect register={register} />
                </div>
            </div>

            <div className='row mb-4'>
                <div className="col-4">
                    <label className="form-label">Incorporated State</label>
                    <StatesSelector register={register} name="state_of_incorporation" />
                </div>
                <div className="col-4">
                    <label className="form-label">Date Established <span className="required">*</span></label>
                    <input ref={register} name="date_established" className="form-control" type="date" />
                </div>
            </div>

            <hr/>

            <div className='row mb-3'>
                <div className="col-8">
                    <label className="form-label">Address <span className="required">*</span></label>
                    <input ref={register({ required: true })} type="text" className={`form-control ${errors.address ? "is-invalid" : ""}`} name="address" />
                </div>
                <div className="col">
                    <label className="form-label">City <span className="required">*</span></label>
                    <input ref={register({ required: true })} name="city" className={`form-control ${errors.city ? "is-invalid" : ""}`} type="text" />
                </div>
            </div>

            <div className='row mb-4'>
                <div className="col-4">
                    <label className="form-label">County</label>
                    <input ref={register} name="county" type="text" className={`form-control ${errors.county ? "is-invalid" : ""}`} />
                </div>
                <div className="col-4">
                    <label className="form-label">State <span className="required">*</span></label>
                    <StatesSelector register={register} name='state' />
                </div>
                <div className="col-4">
                    <label className="form-label">Zip Code <span className="required">*</span></label>
                    <input ref={register({ required: true })} name="zip" maxLength={5} className={`form-control ${errors.zip ? "is-invalid" : ""}`} type="text" />
                </div>
            </div>

            <hr/>

            <div className='row mb-4'>
                <div className="col-6">
                    <label className="form-label">Phone Number</label>
                    <PhoneInput register={register} name="phone_number"/>
                </div>
                <div className="col-6">
                    <label className="form-label">Website</label>
                    <input ref={register} name="website" className="form-control" type="text" />
                </div>
            </div>

            <hr/>

            <div className='row mb-4'>
                <div className="col-12">
                    <label className="form-label">Description of Company</label>
                    <textarea ref={register} name="description" className="form-control" rows="5" />
                </div>
            </div>

            <hr/>

            <div className='row mb-4'>
                <div className="col-4">
                    <label className="form-label">Type</label>
                    <select ref={register} name="is_ssn" className="form-control" onChange={toggleType} >
                        <option value="false">EIN</option>
                        <option value="true">SSN</option>
                    </select>
                </div>
                {ssn_or_ein === 'true' ?
                    <div className="col-4">
                        <label className="form-label">Social Security Number</label>
                        <SSNInput register={register} className="form-control" name="federal_id"/>
                    </div>
                    :
                    <div className="col-4">
                        <label className="form-label">Federal ID</label>
                        <FederalTaxIdInput register={register} name="federal_id" />
                    </div>

                }
                <div className="col-4">
                    <label className="form-label">NAICS Code</label>
                    <input ref={register} name="naics" className="form-control" type="text" />
                </div>
            </div>

            <hr/>

            <div className='row mb-3'>
                <div className="col-12">
                    <label className="form-label">Comment</label>
                    <textarea ref={register} name="comment" className="form-control" rows="5" />
                </div>
            </div>
            <button className="btn btn-primary float-right" type="submit">Save Company</button>
            <br/>
            <br/>
            <br/>
        </form>
    )
}

export default Add
