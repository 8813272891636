import React from 'react';
import Billing from "../Billing";

export const SourceBilling = ({document, application, triggerReload}) => {
    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        Source Billing for <strong>{application.source.company_name}</strong>
                    </div>
                    <div className="card-body">
                        <Billing type="source" typeId={application.source.id} contractNumber={document.contract_number} triggerReload={triggerReload} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SourceBilling