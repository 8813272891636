import React from 'react';
import Vendor from "./Vendor";

export const List = ({application}) => {
    return (
        <div>
            {application.vendors.map(vendor=>{
                return (<div className="well"><Vendor vendor={vendor} applicationID={application.id} /></div>)
            })}
        </div>
    )
}
export default List