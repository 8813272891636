import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import Axios from "../../Mics/axiosInstance";
import UserSelector from "../../Inputs/UserSelector";
import CurrencyInput from "../../Inputs/CurrencyInput";
import PercentageInput from "../../Inputs/PercentageInput";
import TaxTreatment from './TaxTreatment';
import { NotificationManager } from 'react-notifications';

export const GenDoc = ({ document, triggerReload }) => {
    const { register, handleSubmit, setValue } = useForm({ mode: 'onBlur' });
    const [exempt, setExempt] = useState(document.exempt);
    const [on_stream, setOnStream] = useState(document.on_stream);
    const [upfront, setUpfront] = useState(document.exempt);

    const save = (data) => {
        Axios.put('/applications/' + document.application_id + '/documents/' + document.contract_number, data).then(()=>triggerReload()).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }

    const toggleExempt = () => {
        setExempt(!exempt)
    }

    return (
        <div>
            <form onBlur={handleSubmit(save)}>
                <div className="row mb-4">
                    <div className="col-4">
                        Document Date<span className="required">*</span>
                        <input className="form-control" type="date" name="doc_date" ref={register({ required: true })} defaultValue={document.doc_date} />
                    </div>
                    <div className="col-4">
                        Document Person<span className="required">*</span>
                        <UserSelector type="Document" value={document.doc_user_id} name="doc_user_id" register={register({ required: true })} />
                    </div>
                </div>

                <hr />
                <fieldset>
                    <legend>Tax Treatment</legend>
                    <TaxTreatment document={document} inputRef={register} />

                </fieldset>

            </form>
        </div>
    )
}

export default GenDoc