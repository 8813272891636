import React, { useState } from "react";
import List from "./List";

export const Prefunding = ({document, application, triggerReload}) => {

    //We only want to show the vendors that have equipment associated to the actual document, not the entire list of vendors off the application  as they can differ
    const equipmentVendors = () => {
        let uniqueVendors = []
        let uniqueVendorIds = []

        if(document.equipments.length){
            document.equipments.forEach((equipment) => {
                if(!uniqueVendorIds.includes(equipment.vendor_id)){
                    uniqueVendorIds.push(equipment.vendor_id)
                    uniqueVendors.push(equipment.vendor)
                }
            })
        }
        return uniqueVendors
    }


    return (
        <div className="row">
            <div className="col">
                <div className="card mb-4">
                    <div className="card-header">
                        Disbursements
                    </div>
                    <div className="card-body">
                        {equipmentVendors().map(vendor=>{
                            return <List vendor={vendor} document={document} parentReload={triggerReload} application={application} />
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Prefunding
