import React from 'react';
import {useQuery, useQueryClient} from "react-query";
import Axios from "../Mics/axiosInstance";
import {Link, useParams} from "react-router-dom";
import ApplicantCard from "./UnderwritingCards/Applicant"
import ApplicationCard from "./UnderwritingCards/Application"
import CreditApproversCard from "./UnderwritingCards/CreditApproversCard"
import BankStatementList from "./UnderwritingCards/BankStatements/List"
import PersonalGuarantorsList from "./UnderwritingCards/PersonalGuarantors/List"
import CorporateGuarantorsList from "./UnderwritingCards/CorporateGuarantors/List"
import ScoringSheet from "./UnderwritingCards/ScoringSheet"
import Decision from "./UnderwritingCards/Decision"
import Comment from './UnderwritingCards/Comment'
import VendorsList from './UnderwritingCards/Vendors/List'
function useApplication(id) {
    return useQuery(["application", id], async () => {
        const { data } = await Axios.get("/applications/"+id);
        return data;
    });
}

export const Underwriting = () => {
    const { id } = useParams()
    const { data, isLoading } = useApplication(id);
    
    if(isLoading){
        return <div>Loading Credit Application</div>
    }

    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-header">
                        Credit Application Overview: #{id}
                    </div>
                    <div className="card-body">

                        <div className="well">
                            <div className="row">
                                <div className="col">
                                    <Link className="btn btn-outline-secondary float-right" to={`/applications/${id}/applicant`}>
                                        Manage <i className="fas fa-chevron-right"/>
                                    </Link>
                                    <h5>Applicant Info</h5>
                                </div>
                            </div>
                            <hr/>
                            <ApplicantCard application={data} applicationId={id}/>
                        </div>

                        

                        <div className="well">
                            <div className="row">
                                <div className="col">
                                    <h5>Credit Approvers</h5>
                                </div>
                            </div>
                            <hr/>
                            <CreditApproversCard application={data} applicationId={id}/>
                        </div>
                        
                        <div className="well">
                            <div className="row">
                                <div className="col">
                                    <Link className="btn btn-outline-secondary float-right" to={`/applications/${id}/personal-guarantors`}>
                                        Add New <i className="fas fa-chevron-right"/>
                                    </Link>
                                    <h5>Personal Guarantors</h5>
                                </div>
                            </div>
                            <hr/>
                            <PersonalGuarantorsList application={data} applicationId={id}/>
                        </div>

                        <div className="well">
                            <div className="row">
                                <div className="col">
                                    <Link className="btn btn-outline-secondary float-right" to={`/applications/${id}/corporate-guarantors`}>
                                        Add New <i className="fas fa-chevron-right"/>
                                    </Link>
                                    <h5>Corporate Guarantors</h5>
                                </div>
                            </div>
                            <hr/>
                            <CorporateGuarantorsList application={data} applicationId={id}/>
                        </div>

                        <div className="well">
                            <div className="row">
                                <div className="col">
                                    <Link className="btn btn-outline-secondary float-right" to={`/applications/${id}/banking-info`}>
                                        Add New <i className="fas fa-chevron-right"/>
                                    </Link>
                                    <h5>Bank Statements</h5>
                                </div>
                            </div>
                            <hr/>
                            <BankStatementList application={data} applicationId={id}/>
                        </div>

                        <div className="well">
                            <h5>Scoring Sheet</h5>
                            <hr/>
                            <ScoringSheet application={data} applicationId={id}/>
                        </div>

                        <div className="well">
                            <div className="row">
                                <div className="col">
                                    <Link className="btn btn-outline-secondary float-right" to={`/applications/${id}/details`}>
                                        Manage <i className="fas fa-chevron-right"/>
                                    </Link>
                                    <h5>Application Info</h5>
                                </div>
                            </div>
                            <hr/>
                            <ApplicationCard application={data} applicationId={id}/>
                        </div>

                        <div className="well">
                            <h5>Credit Comment</h5>
                            <hr/>
                            <Comment application={data} applicationId={id} />
                        </div>
                        
                        <div className="well">
                            <h5>Decision</h5>
                            <hr/>
                            <Decision application={data} applicationId={id}/>
                        </div>

                        <div className="well">
                            <div className="row">
                                <div className="col">
                                    <h5>Vendors</h5>
                                </div>
                            </div>
                            <hr/>
                            <VendorsList application={data} applicationId={id}/>
                        </div>

                        <div className="row">
                            <div className="col">
                                <Link className="btn btn-primary float-right" to={`/credit-applications/${id}/scorecard`}>
                                    Go to ScoreCard <i className="fas fa-chevron-right"/>
                                </Link>
                                <Link className="btn btn-primary" to={`/credit-applications`}>
                                    <i className="fas fa-chevron-left"/> Go to Applications
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Underwriting
