const endpoints={
    AUTH: {
        LOGIN: '/login',
        LOGOUT: '/logout',
        PROFILE: '/auth/profile'
    },
    GET:{
        APP: 'API/application',
        COMPANY: '/applicants',
        APPBILLING: '/applicantBilling',
        A2C: '/applicationToCg',
        A2E: "/applicationToEquipment",
        A2P: "/applicationToPg",
        A2V: '/applicationToVendor',
        BANK: "/bank_statements",
        BILL: '/applicantBill',
        BOARDINGSHEET: '/boardingsheet',
        COMMENT: '/comments',
        COMMENTAPP: "/commentApp",
        COMMENTCOMPANY: '/commentCompany',
        COMMENTCREDIT: '/commentCredit',
        COMMISSION: "/commissions",
        CREDIT: "/credits",
        CREDITADD: "/creditAdditional",
        CG: "/corporate_guarantors",
        BROKERCOM: "/brokerCommision",
        DISCOUNTER: "/discounter",
        DISBURSEMENT: '/disbursement',
        DISBURSEMENTADD: '/disbursementAdditional',
        DISBURSEMENTALL: '/disbursement/all/',
        DEALERKICKBACK: "/dealerKickback",
        DOCUMENTATION: '/documentation',
        DOCBILLING: '/docBilling',
        DOCUMENTATIONCON: '/documenationCon',
        DOCUMENTATIONBYCONTRACTNUMBER: '/documentationByConract',
        EOT: "/eot",
        EQUIPMENT: '/equipments',
        EQUIPMENTFUNDING: '/equipment_fundings',
        FEE: '/fees',
        FOLLOWUP: '/followups',
        PAYMNET: '/paymnet',
        PERSONALCREDIT: '/personalCredit',
        PC: '/pgCredit',
        PG: '/personal_guarantors',
        PREFUNDING: '/prefundings',
        SOURCEBILLING: '/sourceBilling',
        SOURCECONTACT: '/sourceContacts',
        SOURCECOM: '/sourceCommission',
        SOURCEFUNDING: '/sourceFundings',
        SOURCETOTALS: '/sourceTotal',
        SOURCES: '/sources',
        STAFF: '/staff',
        STATUS: '/statuses',
        VENDOR: '/vendors',
        VENDORCOM: '/vendorsCommission',
        VENDORTOTAL: '/vendorsTotals',
        VENDORFUDNING: '/vendorFunding',
        APPROVAL: '/return-CreditApproval',
        CREDITSCORECARD: '/return-CreditScoreCard',
        TRANSMITALBROKER: '/return-CreditApprovalTransmittalBroker',
        TRANSMITALDISCOUNTER: '/return-CreditApprovalTransmittalDiscounter',
        DOCREQUESTFORM: '/return-DocRequestForm',
        FUNDINGCOVERSHEET: '/return-FundingCoverSheet'
    },
    POST:{
        CREDITAPPROVAL: '/genCreditApproval',
        SCORECARD: '/genCreditScoreCard',
        TRANSMITALBROKER: '/genCreditApprovalTransmittalBroker',
        TRANSMITALDISCOUNTER: '/genCreditApprovalTransmittalDiscounter',
        FUNDINGCOVERSHEET: '/genCreditFundingCoverSheet',
        APP: '/application/add',
        COMPANY: '/applicants/add',
        APPBILLING: '/applicantBilling/add',
        A2C: '/applicationToCg/add',
        A2E: "/applicationToEquipment/add",
        A2P: "/applicationToPg/add",
        A2v: '/applicationToVendor/add',
        BANK: "/bank_statements/add",
        BILL: '/applicantBill/add',
        BOARDINGSHEET: '/boardingsheet/add',
        COMMENT: '/comments/add',
        COMMISSION: "/commission/add",
        CREDIT: "/credit/add",
        CREDITADD: "/creditAdditional/add",
        CG: "/cg/add",
        BROKERCOM: "/brokerCommision/add",
        DISCOUNTER: "/discounter/add",
        DISBURSEMENT: '/disbursement/add',
        DISBURSEMENTADD: '/disbursementAdditional/add',
        DEALERKICKBACK: "/dealerKickback/add",
        DOCUMENTATION: '/documentation/add',
        DOCBILLING: '/docBilling/add',
        EOT: "/eot/add",
        EQUIPMENT: '/equipment/add',
        EQUIPMENTFUNDING: '/equipmentFunding/add',
        FEE: '/fee/add',
        FOLLOWUP: '/followup/add',
        LOGIN: '/auth/login',
        LOGOUT: '/auth/logout',
        PAYMNET: '/paymnet/add',
        PERSONALCREDIT: '/personalCredit/add',
        PG: '/pg/add',
        PREFUNDING: '/prefunding/add',
        SOURCEBILLING: '/sourceBilling/add',
        SOURCECONTACT: '/sourceContacts/add',
        SOURCECOM: '/sourceCommission/add',
        SOURCEFUNDING: '/sourceFundings/add',
        SOURCETOTALS: '/sourceTotal/add',
        SOURCES: '/sources/add',
        STATUS: '/status/add',
        STAFF: '/staff/add',
        VENDOR: '/vendors/add',
        VENDORCOM: '/vendorsCommission/add',
        VENDORTOTAL: '/vendorsTotals/add',
        VENDORFUDNING: '/vendorFunding/add',
    },
    PUT:{
        APP: '/application/update',
        COMPANY: '/applicants/update',
        APPBILLING: '/applicantBilling/update',
        A2E: "/applicationToEquipment/update",
        A2P: "/applicationToPg/update",
        A2V: "/applicationToVendor/add",
        BANK: "/bank_statements/update",
        BILL: '/applicantBill/update',
        BOARDINGSHEET: '/boardingsheet/update',
        COMMENT: '/comments/update',
        COMMISSION: "/commission/update",
        CREDIT: "/credit/update",
        CREDITADD: "/creditAdditional/update",
        CG: "/cg/update",
        BROKERCOM: "/brokerCommision/update",
        DISCOUNTER: "/discounter/update",
        DISBURSEMENT: '/disbursement/update',
        DISBURSEMENTADD: '/disbursementAdditional/update',
        DEALERKICKBACK: "/dealerKickback/update",
        DOCUMENTATION: '/documentation/update',
        DOCBILLING: '/docBilling/update',
        EOT: "/eot/update",
        EQUIPMENT: '/equipment/update',
        EQUIPMENTFUNDING: '/equipmentFunding/update',
        FEE: '/fee/update',
        FOLLOWUP: '/followup/update',
        PAYMNET: '/paymnet/update',
        PG: '/pg/update',
        PREFUNDING: '/prefunding/update',
        SOURCECONTACT: '/sourceContacts/update',
        SOURCECOM: '/sourceCommission/update',
        SOURCEFUNDING: '/sourceFundings/update',
        SOURCEBILLING:'/sourceBill/update',
        SOURCETOTALS: '/sourceTotal/update',
        SOURCES: '/sources/update',
        STAFF: '/staff/update',
        STATUS: '/status/update',
        VENDOR: '/vendors/update',
        VENDORCOM: '/vendorsCommission/update',
        VENDORTOTAL: '/vendorsTotals/update',
        VENDORFUDNING: '/vendorFunding/update',
    },
    DELETE:{
      A2C: '/applicationToCg/delete',
      A2E: "/applicationToEquipment/delete",
      A2P: '/applicationToPg/delete',
      A2V: '/applicationToVendor/delete'

    }
}

export default endpoints