import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import Axios from "../../Mics/axiosInstance";
import StatesSelector from "../../Inputs/StatesSelector";
import { NotificationManager } from 'react-notifications';

export const BillingAddress = ({ document, triggerReload }) => {
    const { register, handleSubmit } = useForm({ mode: 'onBlur' });
    const [showBillingAddress, setShowBillingAddress] = useState(!!document.billing_address);

    const save = (data) => {
        Axios.put('/applications/' + document.application_id + '/documents/' + document.contract_number, data).then(()=>triggerReload()).catch((error) => {
            NotificationManager.error(`${error.key} ${error.message}`, 'Error saving')
        })
    }

    const toggleBillingAddress = (event) => {
        event.preventDefault()
        if (showBillingAddress === true) {
            var clearedAddress = {
                billing_address: null,
                billing_city: null,
                billing_state: null,
                billing_zip: null
            }
            save(clearedAddress)
            document = clearedAddress
        }
        setShowBillingAddress(!showBillingAddress)
    }
    return (
        <div>
            <form onBlur={handleSubmit(save)}>
                {showBillingAddress === true ?
                    <div>
                        <div className="row mb-4">
                            <div className="col">
                                Billing Address
                                <input className="form-control" type="text" name="billing_address" ref={register} defaultValue={document.billing_address} />
                            </div>
                            <div className="col">
                                Billing City
                                <input className="form-control" type="text" name="billing_city" ref={register} defaultValue={document.billing_city} />
                            </div>
                            <div className="col">
                                Billing State
                                <StatesSelector value={document.billing_state} name="billing_state" register={register} />
                            </div>
                            <div className="col">
                                Billing Zip
                                <input className="form-control" type="text" name="billing_zip" maxLength={5} ref={register} defaultValue={document.billing_zip} />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col">
                                <button className="btn btn-sm btn-info" onClick={toggleBillingAddress}>Remove billing address</button>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row mb-4">
                        <div className="col">
                            <button className="btn btn-sm btn-info" onClick={toggleBillingAddress}>Billing Address is different</button>
                        </div>
                    </div>
                }
            </form>
        </div>
    )
}

export default BillingAddress