import React from 'react';
import LeftSideBar from "./LeftSideBar";
import Header from "./Header";
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const SiteWrapper = ({children}) => {
    return (
        <div id="wrapper">
            <LeftSideBar/>
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Header/>
                    <div className="container-fluid">
                        <NotificationContainer/>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SiteWrapper